import React from 'react';
import DisconnectSvgIcon from './DisconnectSvgIcon';


 
const GpsDisconnectionDisabledAction = (props) => {
    const handleEventClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onShowGPSWireDisconnection(props);
    };

    return (
        <div>
            <button onClick={handleEventClick} className="custom-btn f22 label label-success" 
            title="No GPS Wire Disconnection" >
                <DisconnectSvgIcon height="20" width="20" color="#cccccc" strokeWidth="3"/> &nbsp;
            </button>
        </div>
    );
};

export default GpsDisconnectionDisabledAction;
