import React, { Component } from "react";
import DashboardGrid from "./DashboardGrid";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import Select from "react-select";
import CountUp from "react-countup";
import redirectURL from "../redirectURL";
// import PiechartDrilldownComponent from "../layouts/PiechartDrilldownComponent";
// import ColumnChartLoadingDashboard from "../layouts/columnchartloadingdashboard";
// import ScatterChartLoadingDashboard from "../layouts/scatterchartloadingdashboard";
import DrilldownBarGraph from "../layouts/DrilldownBarGraph";
// import DriverFatigueBottomCard from "./DriverFatigueBottomCard";
import {
	getHyphenDDMMMYYYYHHMM,
	getHyphenDDMMYYYYHHMMSS,
	} from '../common/utils';

export default class DriverFatigueDashboardChanged extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // 5 overSpeed
      overSpeedGraph: "show-m",
      overspeedDataFound: "show-m",
      overSpeedGrid: "show-n",
      overSpeedButtonText: "Show Data",

      overSpeedGridData: [],
      overSpeedHeading: "No. of Instances of overspeeding",
      overSpeedSubHeading:
        "Inside plant >20km/hr for 10 sec and outside plant > 60km/hr for 10 sec",

      // GPS Wire Disconnection

      gpsWireDisconnectionGraph: "show-m",
      gpsWireDisconnectionGrid: "show-n",
      gpsWireDisconnectionButtonText: "Show Data",

      gpsWireDisconnectionGridData: [],
      gpsWireDisconnectionHeading: "Transporter Wise - GPS Wire Disconnection",
      gpsWireDisconnectionSubHeading: "No of wire disconnections in the corresponding duration",


      // Suspicious Stoppage
      suspiciousStoppageGraph: "show-m",
      suspiciousStoppageGrid: "show-n",
      suspiciousStoppageButtonText: "Show Data",

      enrouteStoppageGraph: "show-m",
      enrouteStoppageGrid: "show-n",
      enrouteStoppageButtonText: "Show Data",

      
      enrouteStoppageHeading: "Enroute Stoppage Analysis",
      enrouteStoppageSubHeading:
        "Avg. stoppage time of truck from Port-Plant per trip in corresponding time",
      suspiciousStoppageHeading: "Suspicious Stoppage Analysis",
      suspiciousStoppageSubHeading:
        "Suspicious Sub heading",

      fromDate: moment
        .parseZone()
        .subtract(7, "days")
        .format("YYYY-MM-DD"),
      toDate: moment.parseZone().format("YYYY-MM-DD"),
      show: false,
      basicType: "default",
      basicTitle: "",
      loadshow: "show-n",
      overly: "show-n",
      portNames: [],
      plantNames: [],

      transporternames: [],
      allVehicles: {},
      allDrivers: {},

      vehicleNumbers: [],
      
      driverNames: [],
      portname: [],
      plantname: [],
      vehiclenumber: [],
      transportername: [],
      drivername: "",
      shortRestChildData: [],
      
      overSpeedSeriesData: [],
      overSpeedSeriesCategories: [],
      
      enrouteStoppageData: { data: [] },
      
      
      overspeedingTicketsCount: 0,
      overspeedingTicketsCountInPlant:0,
      overspeedingTicketsData: [],
      overspeedingTicketsDataInPlant:[],
      enrouteStoppageTicketsCount: 0,
      deviationTicketsCount: 0,
      deviationTicketsData: [],
      enrouteStoppageTicketsData: [],
      suspiciousStoppageChartData : { data :[] },
      suspiciousStoppageRecords:[],
      suspiciousStoppageCount: 0,
      // GPS Wire Disconnection states
      gpsWireDisconnectionCount: 0,
      gpsWireDisconnectionParentData: [],
      gpsWireDisconnectionChildData: [],
      columnDefs: [
        {
          headerName: "Ticket Number",
          field: "ticket_number",
          width: 100,
        },
        {
          headerName: "Truck No",
          field: "truck_no",
          width: 100,
        },
        {
          headerName: "Transporter Name",
          field: "transporter_name",
          width: 150,
          valueGetter: function(params) {
            if (params.data.transporter_name) {
              const words = params.data.transporter_name.split("_");
              const capitalizedWords = words.map(
                (word) => word.charAt(0).toUpperCase() + word.slice(1)
              );
              const result = capitalizedWords.join(" ");
              return result;
            } else {
              return "";
            }
          },
        },
        {
          headerName: "Plant Name",
          field: "consignee_name",
          width: 150,
          valueGetter: function(params) {
            if (params.data.consignee_name) {
              const words = params.data.consignee_name.split("_");
              const capitalizedWords = words.map(
                (word) => word.charAt(0).toUpperCase() + word.slice(1)
              );
              const result = capitalizedWords.join(" ");
              return result;
            } else {
              return "";
            }
          },
        },
        {
          headerName: "Consignment Number",
          field: "consignment_code",
          width: 200,
        },
        {
          headerName: "Port In",
          field: "port_in_timestamp",
          width: 200,
          valueGetter: function(params) {
            if (params.data.port_in_timestamp) {
              return moment(params.data.port_in_timestamp).format(
                "YYYY-MM-DD HH:mm"
              );
            } else {
              return "";
            }
          },
        },
        {
          headerName: "Exception Start Time",
          field: "exception_start_time",
          width: 200,
          valueGetter: function(params) {
            if (params.data.exception_start_time) {
              return getHyphenDDMMYYYYHHMMSS(params.data.exception_start_time);
            } else {
              return "";
            }
          },
        },
        {
          headerName: "Exception End Time",
          field: "exception_end_time",
          width: 200,
          valueGetter: function(params) {
            if (params.data.exception_end_time) {
              return getHyphenDDMMYYYYHHMMSS(params.data.exception_end_time);
            } else {
              return "";
            }
          },
        },
        {
          headerName: "Driver Name",
          field: "driver_name",
          width: 200,
        },
        {
          headerName: "Driver Mobile Number",
          field: "driver_mobile_no",
          width: 200,
        },
        {
          headerName: "Ticket Type",
          field: "ticket_category",
          width: 200,
        },
        {
          headerName: "Ticket Text",
          field: "ticket_text",
          width: 200,
        },
        {
          headerName: "Ticket Creation Date",
          field: "ticket_creation_date",
          width: 200,
          valueGetter: function(params) {
            if (params.data.ticket_creation_date) {
              return moment(params.data.ticket_creation_date).format(
                "YYYY-MM-DD HH:mm"
              );
            } else {
              return "";
            }
          },
        },
        {
          headerName: "Ticket Status",
          field: "ticket_status",
          width: 200,
          valueGetter: function(params) {
            if (params.data.ticket_status) {
              if (params.data.ticket_status === 1) {
                return "Open";
              } else {
                return "";
              }
            } else {
              return "";
            }
          },
        },
      ],
      overspeedChildData: [],
      overspeedParentData: [],
    };
  }
  /**
   * Developer Name: Siva G
   * Bug NO: 2989
   * Description: Splits the input string (-) into words and returns a string by transforming first letter in a word to capital letter. 
   ***/
  transformString(input) {
    const words = input.split("_");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    const result = capitalizedWords.join(" ");

    return result;
  }
  /**
   * Developer Name: Siva G
   * Bug NO: 2989
   * Description:  Fetches GPS Wire Disonnection data from API and sets the states.
   ***/
  getGPSWireDisconnectionDashboardData = (payload) => {
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    });
    redirectURL
      .post("/dashboard/getGPSWireDisconnectionDashboardData", payload)
      .then(async (resp) => {
        console.log("getGPSWireDisconnectionDashboardData data", resp.data);

        // this.setState({
        //   overspeedingTicketsCount: 0,
        //   overspeedingTicketsCountInPlant:0,
        //   overspeedingTicketsData: [],
        //   overspeedingTicketsDataInPlant:[],
        //   enrouteStoppageTicketsCount: 0,
        //   enrouteStoppageTicketsData: [],
        //   deviationTicketsCount: 0,
        //   deviationTicketsData: [],
          
        //   enrouteStoppageData: { data: [] },
        //   suspiciousStoppageChartData : { data :[] },
        // });

        // if(resp.data.suspiciousStoppageRecords.documents.length > 0){
        //   this.setState({
        //     suspiciousStoppageRecords: resp.data.suspiciousStoppageRecords.documents,
        //     suspiciousStoppageCount: resp.data.suspiciousStoppageRecords.documents.length
        //   })
        // }
        // else{
        //   this.setState({
        //     suspiciousStoppageRecords: [],
        //     suspiciousStoppageCount: 0,
        //   })
        // }

        // GPS wire disconnection
        if(resp.data.gpsWireDisconnectionRecordsData.length > 0){
          this.setState({
            gpsWireDisconnectionGridData: resp.data.gpsWireDisconnectionRecordsData,
            gpsWireDisconnectionCount: resp.data.gpsWireDisconnectionRecordsData.length
          })
        }
        else{
          this.setState({
            gpsWireDisconnectionGridData: [],
            gpsWireDisconnectionCount: 0,
          })
        }

        // let ticketsData = resp.data.ticketsData;
        // if (ticketsData.length > 0) {
        //   ticketsData.forEach((each) => {

        //     if (each.ticket === "over_speeding") {

        //       let out_plant_trucks = []
        //       let in_plant_trucks = []
        //       if(each.documents.length > 0){
        //         each.documents.forEach((each)=>{
        //           if(!each.hasOwnProperty("in_plant_vehicle")){
        //             out_plant_trucks.push(each)
        //           } else{
        //             in_plant_trucks.push(each)
        //           }
        //         })
        //       }

        //       this.setState({
        //         overspeedingTicketsCount: out_plant_trucks.length,
        //         overspeedingTicketsCountInPlant: in_plant_trucks.length,
        //         overspeedingTicketsDataInPlant: in_plant_trucks,
        //         overspeedingTicketsData: out_plant_trucks,
        //       });
        //     }

        //     if (each.ticket === "enroute_stoppage") {
        //       this.setState({
        //         enrouteStoppageTicketsCount: each.count,
        //         enrouteStoppageTicketsData: each.documents,
        //       });
        //     }
        //     if (each.ticket === "detour") {
        //       this.setState({
        //         deviationTicketsCount: each.count,
        //         deviationTicketsData: each.documents,
        //       });
        //     }

        //   });
        // } else {
        //   this.setState({
        //     overspeedingTicketsCount: 0,
        //     overspeedingTicketsCountInPlant:0,
        //     overspeedingTicketsData: [],
        //     overspeedingTicketsDataInPlant:[],
        //     enrouteStoppageTicketsCount: 0,
        //     enrouteStoppageTicketsData: [],
        //     deviationTicketsCount: 0,
        //     deviationTicketsData: [],
        //   });
        // }

        // GPS wire disconnection

        let gpsChartParentData = []
        let gpsChartChildData = []

        if(resp.data.gpsWireDisconnectionChartData && 
            resp.data.gpsWireDisconnectionChartData[0].parentData.length > 0 &&
            resp.data.gpsWireDisconnectionChartData[0].childData.length > 0
          ){
            gpsChartParentData = resp.data.gpsWireDisconnectionChartData[0].parentData;
            gpsChartChildData = resp.data.gpsWireDisconnectionChartData[0].childData;
        }

        // let overspeedData =
        //   resp.data.overspeedData.length > 0 ? resp.data.overspeedData : [];

        // let overSpeedSeriesData = {
        //   name: "Truck No.",
        //   colorByPoint: true,
        //   data: overspeedData,
        // };

        // let overSpeedSeriesCategories = [];

        // overspeedData.forEach((each) => {
        //   overSpeedSeriesCategories.push(each.name);
        // });

        // console.log("overSpeedSeriesDataCategories", overSpeedSeriesCategories);

        
        // let overspeedParentData = [];
        // let overspeedChildData = [];
        // if (resp.data.overspeedData.length > 0) {
        //   overspeedParentData = resp.data.overspeedData[0].parentData;
        //   overspeedChildData = resp.data.overspeedData[0].childData;
        // }

        // let overspeedSeriesCategories = [];

        // await overspeedParentData.forEach(async (each) => {
        //   await overspeedSeriesCategories.push(each.name);
        // });

        this.setState(
          {
            // overspeedParentData: overspeedParentData,
            // overspeedChildData: overspeedChildData,
            // enrouteStoppageData: {
            //   data: resp.data.enrouteStoppageData,
            // },
            // suspiciousStoppageChartData : { data : resp.data.suspiciousStoppageChartData },
            gpsWireDisconnectionParentData: gpsChartParentData,
            gpsWireDisconnectionChildData: gpsChartChildData
          },
          () => {
            // if (
            //   this.state.overspeedParentData.length === 0 &&
            //   this.state.overspeedChildData.length === 0
            // ) {
            //   this.setState({
            //     overspeedDataFound: "show-n",
            //   });
            // } else {
            //   this.setState({
            //     overspeedDataFound: "show-m",
            //   });
            // }
          }
        );

        this.setState({
          loadshow: "show-n",
          overly: "show-n",
        });
      })
      .catch((err) => {
        console.log("getGPSWireDisconnectionDashboardData err", err);
      });
  };
/**
 * Developer Name: Siva G
 * Bug NO: 2989
 * Description: Fetches transporter trucks data and GPS Wire Disconnection Dashboard data from API's
 ***/
  componentDidMount = async () => {
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    });
    await redirectURL
      .get("/dashboard/getalltransportertrucks")
      .then((resp) => {
        console.log("getalltransportertrucks resp", resp.data);
        let { allTransporters, allVehicles, allDrivers } = resp.data;
    
        let transporterOptions = [];
        allTransporters.forEach((each) => {
          let changedName = this.transformString(each);
          let option = { label: changedName, value: each };
          transporterOptions.push(option);
        });

        this.setState(
          {
            transporternames: transporterOptions,
            allVehicles: allVehicles,
            allDrivers: allDrivers,
            loadshow: "show-n",
            overly: "show-n",
          },
          () => {
            let payload = {
              fromDate: this.state.fromDate,
              toDate: this.state.toDate,
              transporters: [],
              vehicles: [],
            };
            this.getGPSWireDisconnectionDashboardData(payload);
          }
        );
      })
      .catch((err) => {
        console.log("getalltransportertrucks err", err);
      });
  };
  /**
   * Developer Name: Siva G
   * Bug NO: 2989
   * Description: Handles the selection of Transportername and vehiclenumber.
   ***/
  newFilter = (filterType, selOption) => {
    console.log("filterType", filterType);
    console.log("filterType", selOption);
    this.setState({ [filterType]: selOption }, () => {
      if (selOption && filterType === "transportername") {
        let allVehicles = [];
        selOption.forEach((each) => {
          let vehicles = this.state.allVehicles[each.value];
          vehicles.forEach((veh) => {
            allVehicles.push({ label: veh, value: veh });
          });
        });
        this.setState({
          vehicleNumbers: allVehicles,
          vehiclenumber: [],
          // drivername: "",
        });
      }
      if (filterType === "transportername" && selOption === null) {
        this.setState({
          vehiclenumber: [],
          transportername: [],
        });
      }

      if (filterType === "vehiclenumber" && selOption === null) {
        this.setState({
          vehiclenumber: [],
        });
      }

      // if (selOption && filterType === "vehiclenumber") {
      //   let allDrivers = [];
      //   // console.log("resp.data.allDrivers",this.state.allDrivers);
      //   selOption.forEach((each) => {
      //     let drivers = this.state.allDrivers[each.value];
      //     drivers.forEach((driver) => {
      //       if (driver) {
      //         allDrivers.push({
      //           label: this.transformString(driver),
      //           value: driver,
      //         });
      //       }
      //     });
      //   });
      //   this.setState({
      //     driverNames: allDrivers,
      //     drivername: "",
      //   });
      // }
    });
  };

  closeAlert = () => {
    this.setState({
      show: false,
    });
  };

  onClickHideAll = () => {
    this.setState({
      loadshow: "show-n",
      overly: "show-n",
    });
  };

  /**
   * Developer Name: Siva G
   * Bug NO: 2989
   * Description: Called when submit button is clicked and Fetches GPS Wire Disconnection Dashboard data from API.
   ***/
  getFilterData = async (e) => {
    this.setState({
      overly: "show-m",
      loadshow: "show-m",
    });
    e.preventDefault();
    if (this.state.fromDate && this.state.toDate) {
      let transporterNames = [];
      this.state.transportername.forEach((each) => {
        transporterNames.push(each.value);
      });

      let vehicleNumbers = [];
      this.state.vehiclenumber.forEach((each) => {
        vehicleNumbers.push(each.value);
      });

      let payload = {
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
        transporters: transporterNames,
        vehicles: vehicleNumbers,
      };

      this.getGPSWireDisconnectionDashboardData(payload);
    } else {
      this.setState({
        show: true,
        basicType: "danger",
        basicTitle: "Dates shouldn't be empty",
      });
    }
  };

  /**
   * Developer Name: Siva G
   * Bug NO: 2989
   * Description: Handles when From date is changed. 
   ***/
  handleFromDate = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);
    var mm = d.getMonth() + 1;
    if (mm < 10) {
      var month = "0" + mm;
    } else {
      var month = mm;
    }

    var startdate =
      d.getFullYear() +
      "-" +
      month +
      "-" +
      (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

    let fromDate = new Date(startdate);
    let toDate = new Date(this.state.toDate);

    let previousStartDate = this.state.fromDate;

    if (fromDate > toDate) {
      // fromDate.setDate(toDate.getDate()); // Subtract 1 day from fromDate
      // const formattedFromDate = fromDate.toISOString().split('T')[0];
      this.setState({
        fromDate: previousStartDate,
        show: true,
        basicType: "danger",
        basicTitle: "From Date shouldn't be greater than To Date",
      });
    } else {
      this.setState({
        fromDate: startdate,
      });
    }
  };

  /**
   * Developer Name: Siva G
   * Bug NO: 2989
   * Description: Handles when To date is changed. 
   ***/
  handleToDate = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);
    var mm = d.getMonth() + 1;
    if (mm < 10) {
      var month = "0" + mm;
    } else {
      var month = mm;
    }

    var edate =
      d.getFullYear() +
      "-" +
      month +
      "-" +
      (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

    let fromDate = new Date(this.state.fromDate);
    let toDate = new Date(edate);

    let previousEndDate = this.state.toDate;

    if (toDate < fromDate) {
      // toDate.setDate(fromDate.getDate()); // Subtract 1 day from fromDate
      // const formattedToDate = toDate.toISOString().split('T')[0];
      this.setState({
        toDate: previousEndDate,
        show: true,
        basicType: "danger",
        basicTitle: "To Date shouldn't be lesser than From Date",
      });
    } else {
      this.setState({
        toDate: edate,
      });
    }

  };

  // overSpeedBoxVisibility = () => {
  //   if (this.state.overSpeedButtonText === "Show Data") {
  //     this.setState({
  //       overSpeedGraph: "show-n",
  //       overSpeedGrid: "show-m",
  //       overSpeedButtonText: "Show Chart",
  //     });
  //   } else {
  //     this.setState({
  //       overSpeedGraph: "show-m",
  //       overSpeedGrid: "show-n",
  //       overSpeedButtonText: "Show Data",
  //     });
  //   }
  // };

  // enrouteStoppageBoxVisibility = () => {
  //   if (this.state.enrouteStoppageButtonText === "Show Data") {
  //     this.setState({
  //       enrouteStoppageGraph: "show-n",
  //       enrouteStoppageGrid: "show-m",
  //       enrouteStoppageButtonText: "Show Chart",
  //     });
  //   } else {
  //     this.setState({
  //       enrouteStoppageGraph: "show-m",
  //       enrouteStoppageGrid: "show-n",
  //       enrouteStoppageButtonText: "Show Data",
  //     });
  //   }
  // };
  // suspiciousStoppageBoxVisibility = () => {
  //   if (this.state.suspiciousStoppageButtonText === "Show Data") {
  //     this.setState({
  //       suspiciousStoppageGraph: "show-n",
  //       suspiciousStoppageGrid: "show-m",
  //       suspiciousStoppageButtonText: "Show Chart",
  //     });
  //   } else {
  //     this.setState({
  //       suspiciousStoppageGraph: "show-m",
  //       suspiciousStoppageGrid: "show-n",
  //       suspiciousStoppageButtonText: "Show Data",
  //     });
  //   }
  // };

  /**
   * Developer Name: Siva G
   * Bug NO: 2989
   * Description: Called when Show Data / Show Chart button is clicked.
   ***/
  gpsWireDisconnectionBoxVisibility = () => {
    if (this.state.gpsWireDisconnectionButtonText === "Show Data") {
      this.setState({
        gpsWireDisconnectionGraph: "show-n",
        gpsWireDisconnectionGrid: "show-m",
        gpsWireDisconnectionButtonText: "Show Chart",
      });
    } else {
      this.setState({
        gpsWireDisconnectionGraph: "show-m",
        gpsWireDisconnectionGrid: "show-n",
        gpsWireDisconnectionButtonText: "Show Data",
      });
    }
  };
  render() {
    // let durationColDef = [
    //   {
    //     headerName: "Exception Duration (Hrs)",
    //     field: "total_exception_duration",
    //     width: 150,
    //     valueGetter: function(params) {
    //       console.log(
    //         "params.data.total_exception_duration",
    //         params.data.total_exception_duration
    //       );
    //       if (params.data.total_exception_duration) {
    //         return (
    //           Number(params.data.total_exception_duration) /
    //           (60 * 60)
    //         ).toFixed(2);
    //       } else {
    //         return "";
    //       }
    //     },
    //   },
    // ];

    // let enrouteColumnDefs = [
    //   ...this.state.columnDefs,
    //   {
    //     headerName: "Enroute Stoppage Count",
    //     field: "enroute_stoppage_exceptions_count",
    //     width: 150,
    //   },
    //   ...durationColDef,
    // ];


    // let overspeedColDefs = [
    //   ...this.state.columnDefs,
    //   {
    //     headerName: "Overspeed Score",
    //     field: "overspeed_exceptions_count",
    //     width: 150,
    //   },
    // ];

    // let suspiciousStoppageColDef = [
    //   {
    //     headerName: "Truck No",
    //     field: "truck_no",
    //     width: 100,
    //   },
    //   {
    //     headerName: "Consignment Number",
    //     field: "shipment_id",
    //     width: 200,
    //   },
    //   {
    //     headerName: "Transporter Name",
    //     field: "transporter_name",
    //     width: 150,
    //     valueGetter: function(params) {
    //       if (params.data.transporter_name) {
    //         const words = params.data.transporter_name.split("_");
    //         const capitalizedWords = words.map(
    //           (word) => word.charAt(0).toUpperCase() + word.slice(1)
    //         );
    //         const result = capitalizedWords.join(" ");
    //         return result;
    //       } else {
    //         return "";
    //       }
    //     },
    //   },
    //   {
    //     headerName: "Break Start Time",
    //     field: "break_start",
    //     width: 200,
    //     valueGetter: function(params) {
    //       if (params.data.break_start) {
    //         return getHyphenDDMMYYYYHHMMSS(params.data.break_start);
    //       } else {
    //         return "";
    //       }
    //     },
    //   },
    //   {
    //     headerName: "Break End Time",
    //     field: "break_end",
    //     width: 200,
    //     valueGetter: function(params) {
    //       if (params.data.break_end) {
    //         return getHyphenDDMMYYYYHHMMSS(params.data.break_end);
    //       } else {
    //         return "";
    //       }
    //     },
    //   },
    //   {
    //     headerName: "Break Time Duration (Hrs)",
    //     field: "break_time_seconds",
    //     width: 140,
    //     valueGetter: function(params) {
    //       console.log(
    //         "params.data.break_time_seconds",
    //         params.data.break_time_seconds
    //       );
    //       if (params.data.break_time_seconds) {
    //         return (
    //           Number(params.data.break_time_seconds) /
    //           (60 * 60)
    //         ).toFixed(2);
    //       } else {
    //         return "";
    //       }
    //     },
    //   },
    //   {
    //     headerName: "Suspicious Location",
    //     field: "suspicious_location",
    //     width: 150,
    //   },
    //   {
    //     headerName: "Consigner Name",
    //     field: "consigner_name",
    //     width: 150,
    //   },
    //   {
    //     headerName: "Consigner Code",
    //     field: "consigner_code",
    //     width: 100,
    //   },
    //   {
    //     headerName: "Distance To Customer",
    //     field: "distance_to_customer",
    //     width: 140,
    //   },
    //   {
    //     headerName: "Shipment Mode",
    //     field: "shipment_mode",
    //     width: 100,
    //   },
      
    //   {
    //     headerName: "City",
    //     field: "city",
    //     width: 100,
    //   },
    //   {
    //     headerName: "Break State",
    //     field: "break_state",
    //     width: 100,
    //   },
    //   {
    //     headerName: "Break District",
    //     field: "break_district",
    //     width: 170,
    //   },
    //   {
    //     headerName: "Break Address",
    //     field: "break_address",
    //     width: 200,
    //   },
    //   {
    //     headerName: "Latitude",
    //     field: "lat",
    //     width: 150,
    //   },
    //   {
    //     headerName: "longitude",
    //     field: "lng",
    //     width: 150,
    //   },
    // ]

    /**
     * Developer Name: Siva G
     * Bug NO: 2989
     * Description: GPS Wire Disconnection Grid Column definition. 
     ***/
    let gpsWireDisconnectionColDefs= [
      {
        headerName: "Truck No",
        field: "truck_no",
        width: 130,
        filter: true,
        resizable: true,
        sortable:true,
      },

      {
          headerName: "Device Id",
          field: "device_id",
          width: 150,
          editable: false,
          filter: "agSetColumnFilter",
          resizable: true
      },
      {
          headerName: "Consignment No",
          field: "shipment_id",
          width: 150,
          editable: false,
          filter: "agSetColumnFilter",
          resizable: true
      },
      {
        headerName: "Transporter Name",
        field: "consignmentDetails[0].transporter_name",
        width: 150,
        resizable: true,
        valueGetter: function (params) {
          if(params.data && params.data.consignmentDetails[0] && 
            params.data.consignmentDetails[0].transporter_name){
            return params.data.consignmentDetails[0].transporter_name
          }
          return ""
        }
      },
      {
        headerName: "Transporter Code",
        field: "consignmentDetails[0].transporter_code",
        width: 130,
        resizable: true,
        valueGetter: function (params) {
          if(params.data && params.data.consignmentDetails[0] && 
            params.data.consignmentDetails[0].transporter_code){
            return params.data.consignmentDetails[0].transporter_code
          }
          return ""
        }
      },
      {
          headerName: "Trip Start Time",
          field: "consignmentDetails[0].invoice_time",
          width: 150,
          cellRenderer: '', resizable: true,
          // comparator: dateComparator,
          valueGetter: function (params) {
              
              if (params.data && 
                  params.data.consignmentDetails[0] &&
                  params.data.consignmentDetails[0].invoice_time && 
                  params.data.consignmentDetails[0].invoice_time != '' && 
                  params.data.consignmentDetails[0].invoice_time != undefined && 
                  params.data.consignmentDetails[0].invoice_time !== ' '
                  ) {
                  return getHyphenDDMMMYYYYHHMM(params.data.consignmentDetails[0].invoice_time);
              }
              else {
                  return "NA";
              }
          },
      },
      {
          headerName: "Reached Destination At",
          field: "consignmentDetails[0].first_dealer_reported",
          width: 180,
          //cellRenderer:'dateFormater',
          valueGetter: function (params) {
              if (params.data && 
                  params.data.consignmentDetails[0] &&
                  params.data.consignmentDetails[0].first_dealer_reported && 
                  params.data.consignmentDetails[0].first_dealer_reported != '' && 
                  params.data.consignmentDetails[0].first_dealer_reported != undefined && 
                  params.data.consignmentDetails[0].first_dealer_reported !== ' ') {
                  return getHyphenDDMMMYYYYHHMM(params.data.consignmentDetails[0].first_dealer_reported);
              }
              else {
                  return "";
              }

          },
          resizable: true,

      },
      {
          headerName: "Wire Disconnection Time",
          field: "exception_timestamp",
          width: 177,
          editable: false,
          filter: "agSetColumnFilter",
          resizable: true,
          valueGetter: function (params) {
              if (params.data && params.data.exception_timestamp && params.data.exception_timestamp != "") {
                  return getHyphenDDMMMYYYYHHMM(params.data.exception_timestamp);
              }
              else {
                  return "";
              }

          },
      },
      {
          headerName: "Wire Disconnection Address",
          field: "wire_disconnection_address",
          width: 270,
          editable: false,
          filter: "agSetColumnFilter",
          resizable: true
      },
      {
          headerName: "Wire Reconnection Time",
          field: "exception_end_timestamp",
          width: 177,
          editable: false,
          filter: "agSetColumnFilter",
          resizable: true,
          valueGetter: function (params) {
              if (params.data && params.data.exception_end_timestamp && params.data.exception_end_timestamp != "") {
                  return getHyphenDDMMMYYYYHHMM(params.data.exception_end_timestamp);
              }
              else {
                  return "";
              }

          },
      },
      {
          headerName: "Wire Reconnection Address",
          field: "wire_reconnection_address",
          width: 270,
          editable: false,
          filter: "agSetColumnFilter",
          resizable: true
      },
      {
          headerName: "Latitude",
          field: "exception_start_lat",
          width: 150,
          editable: false,
          filter: "agSetColumnFilter",
          resizable: true
      },
      {
          headerName: "Longitude",
          field: "exception_start_lng",
          width: 150,
          editable: false,
          filter: "agSetColumnFilter",
          resizable: true
      },
      {
          headerName: "Created date",
          field: "created_date",
          width: 150,
          editable: false,
          filter: "agSetColumnFilter",
          resizable: true,
          hide:true
      },
		];

    return (
      <div className="container-fluid">
        <h5 >
        <i className="icofont icofont-vehicle-delivery-van cus-i mr-1 mt-1"></i>
        <span style={{ fontFamily: "Inter-SemiBold" }}>GPS Wire Disconnection Dashboard - Transporter Vehicles</span>
        </h5>
        <div className={"dataLoadpage " + this.state.loadshow}></div>
        <div className={"dataLoadpageimg " + this.state.loadshow}>
          <div className="loader-box">
            <div className="loader-box">
              <div className={`new-loader ${this.state.loadshow}`}></div>
            </div>
          </div>
        </div>
        <div
          className={"overlay-part " + this.state.overly}
          onClick={this.onClickHideAll}
        ></div>
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        />
        
        <div className="row">
          <div className="col-xl-12 col-lg-12 mt-2 p-2 mb-4" style={{ minHeight: "100vh" }}>
            {/* Filter card */}
            <div className="card shadow pt-3 mt-2 mb-3" style={{ minHeight: "100px" }}>
              
              <div className="col-lg-12 col-xl-12 mx-0 row">
                <div
                  className="col-xl-2 col-lg-2 form-group"
                  style={{ zIndex: "12", minWidth: "200px" }}
                >
                  <label>From Date</label>
                  <Datetime
                    timeFormat={false}
                    value={this.state.fromDate}
                    disableCloseOnClickOutside={false}
                    closeOnSelect={true}
                    inputProps={{
                      placeholder: "From",
                      name: "fromDate",
                      autoComplete: "off",
                    }}
                    dateFormat="YYYY-MM-DD"
                    name="fromDate"
                    onChange={this.handleFromDate.bind(this)}
                  />
                </div>

                <div
                  className="col-xl-2 col-lg-2 form-group"
                  style={{ zIndex: "12", minWidth: "200px" }}
                >
                  <label>To Date</label>
                  <Datetime
                    timeFormat={false}
                    value={this.state.toDate}
                    disableCloseOnClickOutside={false}
                    closeOnSelect={true}
                    inputProps={{
                      placeholder: "To Date",
                      name: "toDate",
                      autoComplete: "off",
                    }}
                    dateFormat="YYYY-MM-DD"
                    name="toDate"
                    onChange={this.handleToDate.bind(this)}
                  />
              </div>
                {/* Transporter Name */}
                <div
                  className="col-xl-3 col-lg-3 form-group"
                  style={{ zIndex: "12" }}
                >
                  <label>Transporter Name</label>
                  <Select
                    closeMenuOnSelect={true}
                    name="transportername"
                    value={this.state.transportername}
                    className="border-radius-0"
                    onChange={this.newFilter.bind(this, "transportername")}
                    isMulti={true}
                    style={{ borderRadius: "0px", zIndex: "9999" }}
                    options={this.state.transporternames}
                    placeholder={"Select"}
                    required
                  />
                </div>
                {/* Vehicle Number */}
                <div
                  className="col-xl-2 col-lg-2 form-group"
                  style={{ zIndex: "12", minWidth: "250px" }}
                >
                  <label>Vehicle Number</label>
                  <Select
                    closeMenuOnSelect={true}
                    name="vehiclenumber"
                    value={this.state.vehiclenumber}
                    className="border-radius-0"
                    onChange={this.newFilter.bind(this, "vehiclenumber")}
                    isMulti={true}
                    style={{ borderRadius: "0px", zIndex: "9999" }}
                    options={this.state.vehicleNumbers}
                    placeholder={"Select"}
                    required
                  />
                </div>
                {/* Driver Name */}
                {/* <div
                  className="col-xl-3 col-lg-3 form-group"
                  style={{ zIndex: "12" }}
                >
                  <label>Driver Name</label>
                  <Select
                    closeMenuOnSelect={true}
                    name="drivername"
                    value={this.state.drivername}
                    className="border-radius-0"
                    onChange={this.newFilter.bind(this, "drivername")}
                    isMulti={true}
                    style={{ borderRadius: "0px", zIndex: "9999" }}
                    options={this.state.driverNames}
                    placeholder={"Select"}
                    required
                  />
                </div> */}

                <div className={"col-xl-1 col-lg-1 form-group"}>
                  <label htmlFor=""></label>
                  <br />
                  <button
                    type="button"
                    className="btn btn-info shadow mt-2"
                    onClick={this.getFilterData}
                    // disabled={this.state.vehiclenumber ? null : "disabled"}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>

            {/* Counter Card */}
            {/* <div className="card shadow mt-2 mb-3">
              <div className="row mx-0">
                <div
                  className="col cirlce-d pt-26px counterBorder"
                  // onClick={this.onClickCounterShowData.bind(this, "all")}
                >
                  <span className="f13 countClass">Over Speeding</span>
                  <h4 className="txt-pink-medium f30 txt-center">
                    <span className="counter">
                      {
                        this.state.inPlantBtn === "btn-info"? 
                        <CountUp end={this.state.overspeedingTicketsCountInPlant} />:
                        <CountUp end={this.state.overspeedingTicketsCount} />
                      }
                    </span>
                  </h4>
                </div>
                {this.state.inBoundBtn==="btn-info" ? 
                  <div
                  className="col cirlce-d pt-26px counterBorder"
                  // onClick={this.onClickCounterShowData.bind(this, "all")}
                  >
                    <span className="f13 countClass">Enroute Stoppage</span>
                    <h4 className="txt-pink-medium f30 txt-center">
                      <span className="counter">
                        <CountUp end={this.state.enrouteStoppageTicketsCount} />
                      </span>
                    </h4>
                </div>
                :""}
                <div
                  className="col cirlce-d pt-26px counterBorder"
                >
                  <span className="f13 countClass">Deviation</span>
                  <h4 className="txt-pink-medium f30 txt-center">
                    <span className="counter">
                      <CountUp end={this.state.deviationTicketsCount} />
                    </span>
                  </h4>
                </div>
                <div
                  className="col cirlce-d pt-26px counterBorder"
                >
                  <span className="f13 countClass">Suspicious Stoppage</span>
                  <h4 className="txt-pink-medium f30 txt-center">
                    <span className="counter">
                      <CountUp end={this.state.suspiciousStoppageCount} />
                    </span>
                  </h4>
                </div>
              </div>
            </div> */}

            {/* Data Card*/}
            <div className="card shadow p-4">
              {/*top data*/}
              <div className="row justify-content-around mx-0">

                {/* GPS Wire Disconnection */}
                <div className="col-xl-12 col-lg-12 graphCard">
                  {/**
                   * Developer Name: Siva G
                   * Bug NO: 2989
                   * Description: Button to show chart and grid.
                   ***/}
                  <div className="dataChartButton">
                    <button
                      className="btn btn-danger"
                      onClick={this.gpsWireDisconnectionBoxVisibility}
                      style={{
                        zIndex: "11",
                        fontSize: "12px",
                        padding: "5px 15px",
                        borderRadius: "3px",
                        marginTop: "12px",
                      }}
                      disabled = { this.state.gpsWireDisconnectionGridData.length > 0 ? false : true}
                    >
                      {this.state.gpsWireDisconnectionButtonText}
                    </button>
                  </div>
                 
                  {
                    this.state.gpsWireDisconnectionParentData.length > 0 &&
                    this.state.gpsWireDisconnectionChildData.length > 0 ? (
                      <> 
                      {/**
                       * Developer Name: Siva G
                       * Bug NO: 2989
                       * Description: Displays GPS Wire Disconnection Bar Graph.
                       ***/}
                        <span className={this.state.gpsWireDisconnectionGraph}>
                          <DrilldownBarGraph
                            width={900}
                            yaxistitle={"No of GPS Wire Disconnection"}
                            title={this.state.gpsWireDisconnectionHeading}
                            level2Title={"Vehicle Wise - GPS Wire Disconnection"}
                            subtitle={this.state.gpsWireDisconnectionSubHeading}
                            parentData={this.state.gpsWireDisconnectionParentData}
                            childData={this.state.gpsWireDisconnectionChildData}
                            context={this}
                            unit=""
                            seriesHeading="GPS Wire Disconnection"
                          />
                        </span>
                      </>
                    ) : (
                      <>
                      {/**
                       * Developer Name: Siva G
                       * Bug NO: 2989
                       * Description: Displays when there is no gpsWireDisconnection Parent/child data.
                       ***/}
                        <span className={this.state.gpsWireDisconnectionGraph} 
                          style={{
                              height:"400px",
                              display:"flex",
                              flexDirection:"column",
                              justifyContent:"space-evenly"
                            }}>
                          <div className="txt-center" style={{ marginTop: "20px" }}>
                            <h6>{this.state.gpsWireDisconnectionHeading}</h6>
                            <p>
                              {this.state.gpsWireDisconnectionSubHeading}
                            </p>
                          </div>
                          <div
                            style={{
                              textAlign: "center",
                              // marginTop: "20px",
                              fontWeight: "bold",
                              // marginBottom: "20%",
                            }}
                          >
                            <div style={{ marginBottom: "20px" }}>
                              <i className="icofont icofont-search icofont-3x"></i>
                            </div>
                            No Data Found
                          </div>
                        </span>
                      </>
                  )}

                {/**
                   * Developer Name: Siva G
                   * Bug NO: 2989
                   * Description: Displays GPS Wire Disconnection Grid data.
                   ***/}
                  {
                    this.state.gpsWireDisconnectionGridData.length > 0 ?
                    <div className={`${this.state.gpsWireDisconnectionGrid} txt-center p-2`}>
                      <div className="txt-center" style={{ marginTop: "1%" }}>
                        <h6>{this.state.gpsWireDisconnectionHeading}</h6>
                        <p>
                          {this.state.gpsWireDisconnectionSubHeading}
                        </p>
                      </div>
                      <DashboardGrid
                        columnDefs={gpsWireDisconnectionColDefs}
                        rowData={this.state.gpsWireDisconnectionGridData}
                      />
                    </div>
                  :""}
                </div>   

                {/* Overspeed */}
                {/* <div className="col-xl-6 col-lg-6 graphCard">
                  <div className="dataChartButton">
                    <button
                      className="btn btn-danger"
                      onClick={this.overSpeedBoxVisibility}
                      style={{
                        zIndex: "11",
                        fontSize: "12px",
                        padding: "5px 15px",
                        borderRadius: "3px",
                        marginTop: "12px",
                      }}
                    >
                      {this.state.overSpeedButtonText}
                    </button>
                  </div>
                  <span
                    className={`${this.state.overSpeedGraph} ${this.state.overspeedDataFound}`}
                  >
                    <DrilldownBarGraph
                      width={520}
                      yaxistitle={"Count"}
                      title={this.state.overSpeedHeading}
                      subtitle={this.state.overSpeedSubHeading}
                      parentData={this.state.overspeedParentData}
                      childData={this.state.overspeedChildData}
                      context={this}
                      unit="  Time(s)"
                      seriesHeading="Overspeed"
                    />
                  </span>

                  {this.state.overspeedParentData.length > 0 &&
                  this.state.overspeedChildData.length > 0 ? (
                    <></>
                  ) : (
                    <>
                      <span className={this.state.overSpeedGraph}>
                        <div className="txt-center" style={{ marginTop: "8%" }}>
                          <h6>No. of Instances of overspeeding</h6>
                          <p>
                            Inside plant &gt;20km/hr for 10 sec and outside plant
                            &gt; 60km/hr for 10 sec
                          </p>
                        </div>
                        <div
                          style={{
                            textAlign: "center",
                            marginTop: "27%",
                            fontWeight: "bold",
                            marginBottom: "20%",
                          }}
                        >
                          <div style={{ marginBottom: "20px" }}>
                            <i className="icofont icofont-search icofont-3x"></i>
                          </div>
                          No Data Found
                        </div>
                      </span>
                    </>
                  )}

                  <div className={`${this.state.overSpeedGrid} txt-center p-2`}>
                    <div className="txt-center" style={{ marginTop: "1%" }}>
                      <h6>No. of Instances of overspeeding</h6>
                      <p>
                        Inside plant &gt;20km/hr for 10 sec and outside plant &gt;
                        60km/hr for 10 sec
                      </p>
                    </div>
                    <DashboardGrid
                      columnDefs={overspeedColDefs}
                      rowData={this.state.inBoundBtn==="btn-info"?this.state.overspeedingTicketsData: this.state.overspeedingTicketsDataInPlant}
                    />
                  </div>
                </div>                 */}
              </div>

              {/*top second data*/}
              {/* <div className="row justify-content-around mx-0 mt-3"> */}

                {/* Enroute Stoppage */}
                
                {/* <div className="col-xl-6 col-lg-6 graphCard">
                
                <>
                  <div className="dataChartButton">
                    <button
                      className="btn btn-danger"
                      onClick={this.enrouteStoppageBoxVisibility}
                      style={{
                        zIndex: "11",
                        fontSize: "12px",
                        padding: "5px 15px",
                        borderRadius: "3px",
                        marginTop: "12px",
                      }}
                    >
                      {this.state.enrouteStoppageButtonText}
                    </button>
                  </div>

                  {this.state.enrouteStoppageData.data.length > 0 ? (
                    <>
                      <span className={this.state.enrouteStoppageGraph}>
                        <ScatterChartLoadingDashboard
                          title={this.state.enrouteStoppageHeading}
                          subtitle={this.state.enrouteStoppageSubHeading}
                          width={520}
                          context={this}
                          series={{
                            ...this.state.enrouteStoppageData,
                            name: "enroutestoppage Data",
                            dataSorting: { enabled: true },
                          }}
                          units=" Hrs"
                        />
                      </span>
                    </>
                  ) : (
                    <>
                      <span className={this.state.enrouteStoppageGraph}>
                        <div className="txt-center" style={{ marginTop: "8%" }}>
                          <h6>Enroute Stoppage Analysis</h6>
                          <p>
                            Avg. stoppage time of truck from Port-Plant per trip
                            in corresponding time
                          </p>
                        </div>
                        <div
                          style={{
                            textAlign: "center",
                            marginTop: "27%",
                            fontWeight: "bold",
                            marginBottom: "20%",
                          }}
                        >
                          <div style={{ marginBottom: "20px" }}>
                            <i className="icofont icofont-search icofont-3x"></i>
                          </div>
                          No Data Found
                        </div>
                      </span>
                    </>
                  )}

                  <div
                    className={`${this.state.enrouteStoppageGrid} txt-center p-2`}
                  >
                    <div className="txt-center" style={{ marginTop: "1%" }}>
                      <h6>Enroute Stoppage Analysis</h6>
                      <p style={{ fontSize: "12px" }}>
                        Avg. stoppage time of truck from Port-Plant per trip in
                        corresponding time
                      </p>
                    </div>
                    <DashboardGrid
                      columnDefs={enrouteColumnDefs}
                      rowData={this.state.enrouteStoppageTicketsData}
                    />
                  </div>
                </>
                </div> */}
                
                {/* Suspicious Stoppage */}
                {/* <div className="col-xl-6 col-lg-6 graphCard">
                <>
                  <div className="dataChartButton">
                    <button
                      className="btn btn-danger"
                      onClick={this.suspiciousStoppageBoxVisibility}
                      style={{
                        zIndex: "11",
                        fontSize: "12px",
                        padding: "5px 15px",
                        borderRadius: "3px",
                        marginTop: "12px",
                      }}
                    >
                      {this.state.suspiciousStoppageButtonText}
                    </button>
                  </div>

                  {this.state.suspiciousStoppageChartData.data.length > 0 ? (
                    <>
                      <span className={this.state.suspiciousStoppageGraph}>
                        <ScatterChartLoadingDashboard
                          title={this.state.suspiciousStoppageHeading}
                          subtitle={this.state.suspiciousStoppageSubHeading}
                          width={520}
                          context={this}
                          series={{
                            ...this.state.suspiciousStoppageChartData,
                            name: "suspiciousstoppage Data",
                            dataSorting: { enabled: true },
                          }}
                          units=" Hrs"
                        />
                      </span>
                    </>
                  ) : (
                    <>
                      <span className={this.state.suspiciousStoppageGraph}>
                        <div className="txt-center" style={{ marginTop: "8%" }}>
                          <h6>Suspicious Stoppage Analysis</h6>
                          <p>
                             sub heading
                          </p>
                        </div>
                        <div
                          style={{
                            textAlign: "center",
                            marginTop: "27%",
                            fontWeight: "bold",
                            marginBottom: "20%",
                          }}
                        >
                          <div style={{ marginBottom: "20px" }}>
                            <i className="icofont icofont-search icofont-3x"></i>
                          </div>
                          No Data Found
                        </div>
                      </span>
                    </>
                  )}

                  <div
                    className={`${this.state.suspiciousStoppageGrid} txt-center p-2`}
                  >
                    <div className="txt-center" style={{ marginTop: "1%" }}>
                      <h6>Suspicious Stoppage Analysis</h6>
                      <p style={{ fontSize: "12px" }}>
                        Sub Heading
                      </p>
                    </div>
                    <DashboardGrid
                      columnDefs={suspiciousStoppageColDef}
                      rowData={this.state.suspiciousStoppageRecords}
                    />
                  </div>
                </>
                </div> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
