import React, { Component } from 'react';
import { render } from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _, { escape } from "lodash";
import Select from 'react-select';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import { getDDMMYYYY, getDDMMYYYYHHMMSS,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY } from '../common/utils'; 
import 'react-vertical-timeline-component/style.min.css';
import DrawMap from '../common/drawmap';
import $ from 'jquery';
//import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import CountUp from 'react-countup';
import { func } from 'prop-types';
import Unmapdevice from './unmapdevice';
import { toUpper } from 'lodash';
// import { getElockTrucksList } from '../../../../carlsberg_node/routes/controller/consignmentController';
 
var redirectURL = require('../redirectURL');
var moment = require('moment');
// var Constant = require('../common/Constant');
var googleAnalytics = require("../common/googleanalytics");

const cipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);

    return text => text.split('')
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join('');
}
var encode = cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
export default class DeviceMapping extends Component{

	constructor(props){
		super(props);
		this.state={
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			loadshow:'show-n',
			showDiv:'show-n',
			overly:'show-n',
			alert:null,
            show: false,
            basicTitle:'',
			basicType:"default",
			modules: AllModules,
			sidebarSubHeader:'',
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true

      	    },
      	    rowData: [],
              frameworkComponents:{
                Unmapdevice:Unmapdevice,

            },
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
			  overlayNoRowsTemplate: "",
			 
            animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,
				
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			rowClassRules: {
				
            },
            transporterslist:[],
            container_no:'',
            updatesearatebtn:'show-n',
            searates:'',
            pipavaContainers:[],
            mundraContainers:[],
            containerslist:[],
            errorMessage:'',
            successMessage:'',
			transporter_code:'',
			alltransporters:[],
			components: { datePicker: getDatePicker() },
			totalpod:[],
            pendingpod:[],
            completedpod:[],
			sliderAddTransporter:"",
			rowDataforHistory:[],
			atplant:[],
			intransit:[],
			atdestination:[],
			unloadingForPickup:[],
			noprocess:[],
			returnInTransit:[],
			misplace:[],
			notAvailable:[],
			nearCustomer:[],
			check:0,
			sliderForpivottable:"",
            manual_status: "",
            plantList: [],
             items : [],
             qcnotok : [],
             qcok : [],
             lqcnotok : [],
             lqcok : [],
             qctotal : [],
             registereddevices : [],
             registereddevices_today : [],
             no_gps_today : [],
             nogpslast6hrs : [],
             replacedtoday : [],
             nodatareceived6hrs : [],
             nogpsfix : [],
             nodatareceived6hrstoday : [],
             deviceId:"",
             truck_no:"",
             unAssignedDeviceList:[],
             unAssignedTruckArr:[],
             getinstalledlocation:[],
            install_Location: '',
            show1: false,
            basicTitle1: '',
            basicType1: "default",
            registerdevices: [],
            unmapTruckDetails:"",
            plantName:""



           
        }
		
        this.onLoadGetShipments = this.onLoadGetShipments.bind(this);
        this.onClickUpdateItem = this.onClickUpdateItem.bind(this);
        this.onClickUnMapDeviceFromTruck = this.onClickUnMapDeviceFromTruck.bind(this)
	}
	componentDidMount = async () => {
        var params={
        }
        if(localStorage.getItem("pc")!="" && localStorage.getItem("pc")!=undefined){
            params.plant = localStorage.getItem("pc")
        }
        await redirectURL.post("/consignments/plantcoordinates",params).then((resp)=>{
            if(resp.data.length>0){
                var plant = resp.data[0].plant_name
            }
            else{
                var plant=""
            }
            this.setState({
                plantName:plant,
            })
            
            
        })
        var from_date = moment.parseZone().subtract(3,'days').format("YYYY-MM-DD")
        var to_date = moment.parseZone().format("YYYY-MM-DD")
        $("#osfromDate").val(from_date);
		$("#ostoDate").val(to_date);
        var parameters = {
            startdate : from_date,
			enddate : to_date
        }
        this.onLoadGetShipments(parameters);

        redirectURL.post("/dashboard/getUnAssignedTrucksDevices").then((resp)=>{
            let records = resp.data
            let unAssignedTrucks = records.unAssignedTrucks
            let unAssignedDevices = records.unAssignedDevices
            var trucks =  unAssignedTrucks.filter(e => e.attached_orgs == 'CARLSBERG')
            unAssignedTrucks = trucks
            var devices = unAssignedDevices.filter(e => e.org == 'carlsberg')
            unAssignedDevices = devices
            let unAssignedTruckArr = unAssignedTrucks.map(e => ({label : e.truck_no, value : e.truck_no}))
            let unAssignedDeviceList = unAssignedDevices.map(e => ({label : e.device_id, value : e.device_id}))
            console.log(unAssignedTruckArr,"unAssignedTruckArr")
            this.setState({
                unAssignedTruckArr:unAssignedTruckArr,
                unAssignedDeviceList:unAssignedDeviceList
            })

        })
        redirectURL.post("/dashboard/getinstalledlocation").then((resp)=>{
            console.log(resp.data,"installedlocation")
            var records = resp.data 
            var getinstalledlocation = records.map(e=>({label:e.installation_location,value:e.installation_location}))
            this.setState({
                getinstalledlocation:getinstalledlocation
            })
        })
        


        loadDateTimeScript();
		if(localStorage.getItem("roles").indexOf("SITE_ADMIN") >= 0 || localStorage.getItem("roles").indexOf("CB_HEAD") >= 0){
            redirectURL.post("/consignees/plantsData").then(async(response)=>{
                // console.log(response.data,"177")
                let selectedPlant = response.data.filter(e => e.value == localStorage.getItem("pc"))
                var resp = [{"value":"" ,"label":"ALL"}];
                var plantList = [...resp, ...response.data]
                this.setState({
                    plantList : plantList,
                })
            })
        }
    }
    onLoadGetShipments(parameters){
        this.setState({
            loadshow:"show-m"
        })
        var params = {
            
        }
        var plant_name = this.state.plantName.toUpperCase()
        console.log(plant_name,"ppp")
        redirectURL.post("/dashboard/getDeviceMapping",params).then(async (response) =>{
			console.log(response.data,"12121") //1735
            var records = response.data 
            
            var rows = records.filter(e => {
                if(e.registereddevices.length>0){
                    if(!["" , undefined , 'undefined' , null].includes(plant_name)){
                        if(plant_name==e.registereddevices[0].installed_location){
                            return e
                        }
                        
                    }else{
                        return e
                    }
                    
                }else{
                    return e 
                }
            })
            records = rows
            var items = [];
            var qcnotok = [];
            var qcok = [];
            var lqcnotok = [];
            var lqcok = [];
            var qctotal = [];
            var registereddevices = []
            var registereddevices_today = [];
            var no_gps_today = [];
            var nogpslast6hrs = [];
            var replacedtoday = [];
            var nodatareceived6hrs = [];
            var nogpsfix = [];
            var nodatareceived6hrstoday = [];
            var nowdate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
            // console.log(nowdate);
            var recordsarr = []
            if(records.length > 0)
            {
                records.map((item) =>{
                    if(item.device_id != "" && item.device_id != null && item.device_id != undefined)
                    {
                        // console.log("item.device_id.length ", item.device_id.length)
                        if(item.device_id.length >= 15)
                        {
                            recordsarr.push(item);
                        
                            items.push({"value":item.device_id,"label":item.device_id})
                            qctotal.push(item);
                            if(item.qc_check != undefined)
                            {
                                if(item.qc_check == "OK")
                                {
                                    qcok.push(item) 
                                }
                                if(item.qc_check == "NOT OK")
                                {
                                    qcnotok.push(item) 
                                }
                            }
                            if(item.lifetime_qc_check != undefined)
                            {
                                if(item.lifetime_qc_check == "OK")
                                {
                                    lqcok.push(item)
                                }
                                if(item.lifetime_qc_check == "NOT OK")
                                {
                                    lqcnotok.push(item)
                                }
                            }
                            if(item.truck_no != undefined)
                            {
                                registereddevices.push(item)
                                if(item.registereddevices.length)
                                {
                                    try{
                                        if((item.registereddevices[0].device_registered_on).toString().split("T")[0] == moment.parseZone(new Date()).format("YYYY-MM-DD"))
                                        {
                                            registereddevices_today.push(item)
                                        }
                                    }
                                    catch(e){
    
                                    }
                                    try{
                                        if(item.registereddevices[0].replaced_on != undefined && item.registereddevices[0].replaced_on != "")
                                        {
                                            if((item.registereddevices[0].replaced_on).toString().split("T")[0] == moment.parseZone(new Date()).format("YYYY-MM-DD"))
                                            {
                                                replacedtoday.push(item)
                                            }
                                        }
                                    }
                                    catch(e){
    
                                    }
                                    
                                }
                                try{
                                    var activon = moment.parseZone(item.last_active_on).format("YYYY-MM-DD");
                                    var activontod = moment.parseZone().format("YYYY-MM-DD");
                                    var d1 = moment.parseZone(new Date(activon+" 00:00:00")).format("x");
                                    var d2 = moment.parseZone(new Date(activontod+" 00:00:00")).format("x");
                                    
                                    // if((item.last_active_on).toString().split("T")[0] != moment.parseZone(new Date()).format("YYYY-MM-DD"))
                                    // {
                                    //     no_gps_today.push(item)
                                    // }
                                    if(d1 != d2)
                                    {
                                        // console.log("NO GPS Today ", item)
                                        no_gps_today.push(item)
                                    }
                                }
                            catch(e){
                                
                            }
                                var lastactive = moment.parseZone(item.last_active_on).format("YYYY-MM-DD HH:mm:ss");
                                var hrss = Math.round(getHours(nowdate,lastactive));
                            // console.log("hrs ", hrss)
                                if(hrss >= 6)
                                {
                                    var rnow = nowdate+"===="+lastactive;
                                    // console.log("rnow ",rnow)
                                    nogpslast6hrs.push(item)
                                }
                                
                                try{
                                    var modifieddata = moment.parseZone(item.modified_date).format("YYYY-MM-DD HH:mm:ss");
                                    var hrss = Math.round(getHours(nowdate,modifieddata));
                                    var cdt = moment.parseZone(new Date()).format("YYYY-MM-DD");
                                    var md1 = moment.parseZone(new Date(cdt+" 00:00:00")).format("YYYY-MM-DD HH:mm:ss");
                                    var mdsc1 = moment.parseZone(md1).format("x");
                                    var cditem = moment.parseZone(item.modified_date).format("YYYY-MM-DD");
                                    var md2 = moment.parseZone(new Date(cditem+" 00:00:00")).format("YYYY-MM-DD HH:mm:ss");
                                    var mdsc2 = moment.parseZone(md2).format("x");
                                // console.log("hrs ", hrss)
                                
                                    if(hrss >= 6)
                                    {
                                        var mdrnow = nowdate+"===="+modifieddata;
                                        // console.log("mdrnow ",mdrnow)
                                        nodatareceived6hrs.push(item)
                                        if(mdsc1 == mdsc2)
                                        {
                                            nodatareceived6hrstoday.push(item)
                                        }
                                    }
                                }
                                catch(e){
    
                                }
                                
                                if(item.truck_no != "" && item.last_gps_fix == 0)
                                {
                                    nogpsfix.push(item)
                                }
                                
                            }
                        }
                    }
                    
                })
            }
            var todayInstalledActDevices = [];
            var todayInstalledInActDevices = [];
            if(registereddevices_today.length > 0)
            {
                registereddevices_today.map((e) => {
                    try{
                        if((e.last_active_on).toString().split("T")[0] != moment.parseZone(new Date()).format("YYYY-MM-DD"))
                        {
                            todayInstalledActDevices.push(e)
                        }
                        else
                        {
                            todayInstalledInActDevices.push(e)
                        }
                    }
                    catch(e){
                        todayInstalledInActDevices.push(e)
                    }
                    
                });
            }
            // console.log("nogpslast6hrs ", no_gps_today)
            // console.log("recordscheck",records)
            this.setState({
				rowData:records,
                loadshow:'show-n',
                overly:'show-n',
                devices:recordsarr,
                originaldata:records,
                qctotal:qctotal,
                qcok:qcok,
                qcnotok:qcnotok,
                lqcok:lqcok,
                lqcnotok:lqcnotok,
                registereddevices: registereddevices,
                registereddevices_today: registereddevices_today,
                no_gps_today: no_gps_today,
                nogpslast6hrs:nogpslast6hrs,
                todayInstalledActDevices:todayInstalledActDevices,
                todayInstalledInActDevices:todayInstalledInActDevices,
                replacedtoday:replacedtoday,
                nogpsfix:nogpsfix,
                nodatareceived6hrs:nodatareceived6hrs,
                nodatareceived6hrstoday:nodatareceived6hrstoday
            })

        })
    }

	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
	}
    onClickUnMapDeviceFromTruck=(rowdata)=>{
        this.setState({
            show1:true,
            basicTitle1:"Remarks",
            unmapTruckDetails : rowdata
        })
    }
	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderAddTransporter:'',
			sliderEditTransporter:""
		});
    }
    changeHandler(event){
		var name = event.target.name;
		var value = event.target.value;
		this.setState({[name]:value});
	}
    saveDevice(event){
        event.preventDefault();
        var deviceid = this.state.deviceId.value
        console.log(this.state.deviceId,"deviceid")
        console.log(deviceid,"deviceid1")
        var truck_no = this.state.truck_no.value
        var install_Location = this.state.install_Location.value
        var params={
            device_id:deviceid,
            truck_no:truck_no,
            install_Location:install_Location,
            email:localStorage.getItem("email")
        }
        redirectURL.post("/dashboard/assignDevice",params)
        .then((resp)=>{
            console.log(resp.data,"123")
            if(resp.data.status=="success"){
                this.setState({
                    show:true,
                    basicTitle:"Successfully Assigned",
                    basicType:"success",
                    show1:false,
                    loadshow:'show-n'
                })
                this.componentDidMount()
            }
        })
    }
    newFilter=(filterType,selOption)=>{
        this.setState({[filterType]:selOption})
    }
	onClickHide(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderAddTransporter:'',
			sliderEditTransporter:""
		});
		
    }
	closeAlert = () => {
        this.setState({
            show: false
        });
    }
    confirmDeviceUnMap = (input) =>{
        var params = this.state.unmapTruckDetails
        var device_id = params.data.device_id;
        var truck_no = params.data.registereddevices[0].truck_no;
        var parameters = {
            device_id:device_id,
            truck_no:truck_no,
            olddata:params.data.registereddevices,
            email:localStorage.getItem("email"),
            username:localStorage.getItem("firstname"),
            org_name :params.data.org_name,
            remarks : input,
            installed_date : params.data.registereddevices[0].device_registered_on,
            parent_organization : params.data.parent_organization
        }
        console.log("parameteds ", parameters)
        redirectURL.post("/dashboard/unmapdevicefromtruck",parameters)
        .then((response) => {
            console.log(response.data.status)
            if(response.data.status == "success")
            {
                this.setState({
                    show:true,
                    basicTitle:"Successfully unmap device",
                    basicType:"success",
                    show1:false,
                })
                this.componentDidMount()
            }
            else{
                this.setState({
                    show:true,
                    basicTitle:"Failed to unmap device",
                    basicType:"danger",
                    show1:false,
                })
            }

        })

    }
    hideAlert =() => {
        this.setState({
            show1:false
        })
    }
    onClickCounter(atype)
   {
        if(parseInt(atype) == 0)
        {
            this.setState({
                rowData:this.state.originaldata
            });
        }
        if(parseInt(atype) == 1)
        {
            this.setState({
                rowData:this.state.registereddevices
            })
        }
        if(parseInt(atype) == 2)
        {
            this.setState({
                rowData:this.state.registereddevices_today
            })
        }
        if(parseInt(atype) == 3)
        {
            this.setState({
                rowData:this.state.replacedtoday
            })
        }
        if(parseInt(atype) == 4)
        {
            this.setState({
                rowData:this.state.no_gps_today
            })
        }
        //nogpslast6hrs
        if(parseInt(atype) == 5)
        {
            this.setState({
                rowData:this.state.nogpslast6hrs
            })
        }
        // replacedtoday
        
       
        
        if(parseInt(atype) == 6)
        {
            this.setState({
                rowData:this.state.nogpsfix
            })
        }
        if(parseInt(atype) == 7)
        {
            this.setState({
                rowData:this.state.nodatareceived6hrs
            })
        }
        
        if(parseInt(atype) == 8)
        {
            this.setState({
                rowData:this.state.nodatareceived6hrstoday
            })
        }
   }
   
	onCellUpdateData = (params) => {
        
	}
	onCellValueChanged=(params)=>{
		var newValue = params.newValue 
		this.setState({manual_status:newValue})
	}
	resetState = () => {

		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Reset Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		try {

			this.gridColumnApi.resetColumnState();
			this.gridColumnApi.resetColumnGroupState();
			this.gridApi.setSortModel(null);
			this.gridApi.setFilterModel(null);
			//console.log('column state reset');
			var reqparams = {
				userId: localStorage.getItem("userid"),
				screenurl: window.location.pathname,
				screentitle: this.state.screenpage
			}

			redirectURL.post("/consignments/removeUserGridState", reqparams)
				.then((response) => {
					this.setState({
						show: true,
						basicTitle: "Successfully reset default grid layout",
						basicType: "success"
					})
				})
		}
		catch (e) { }

	};
    onClickAssignDevice(){
        this.setState({
			sliderAddTransporter:'slider-translate',
			overly:"show-m"
		});
    }
    onClickUpdateItem(params){
        redirectURL.post("/dashboard/updatecustomerpod",params.data)
        .then((response) => {
            if(response.data.status == "success")
            {
                this.setState({
                    show:true,
                    basicTitle:"Successfully update data",
                    basicType:"success"
                })
            }
            else{
                this.setState({
                    show:true,
                    basicTitle:"Failed to update data",
                    basicType:"danger"
                })
            }
        })
    }
	render(){
        var columnwithDefs = [
            // {
            //     headerName: '',
            //     field: '',
            //     width:40,
            //     filter: true,
            //     cellRendererSelector:function(params){
            //         return {
            //             component:"viewdevicealerts"
            //         }
            //     },
            //     cellClass:function(params){
            //         try{
            //             if(params.data.qc_check != "" && params.data.qc_check != undefined)
            //             {
            //                 if(params.data.qc_check == "OK")
            //                 {
            //                     return ["bgColorSuccessLight"];
            //                 }
            //                 else if(params.data.qc_check == "NOT OK")
            //                 {
            //                     return ["highlitRow"];
            //                 }
            //                 else{
            //                     return "";
            //                 }
                            
            //             }
            //         }
            //         catch(e){
            //             return "";
            //         }
            //     }
            // },  
            
            // {
            //     headerName: '',
            //     field: '',
            //     width:40,
            //     filter: true,
            //     cellRendererSelector:function(params){
            //         return {
            //             component:"viewdevicelogs"
            //         }
            //     },
            //     cellClass:function(params){
            //         try{
            //             if(params.data.qc_check != "" && params.data.qc_check != undefined)
            //             {
            //                 if(params.data.qc_check == "OK")
            //                 {
            //                     return ["bgColorSuccessLight"];
            //                 }
            //                 else if(params.data.qc_check == "NOT OK")
            //                 {
            //                     return ["highlitRow"];
            //                 }
            //                 else{
            //                     return "";
            //                 }
                            
            //             }
            //         }
            //         catch(e){
            //             return "";
            //         }
            //     }
            // },  
            {
                headerName: 'Device ID',
                field: 'device_id',
                width:140,
                filter: true,
                cellClass:function(params){
                    try{
                        if(params.data.qc_check != "" && params.data.qc_check != undefined)
                        {
                            if(params.data.qc_check == "OK")
                            {
                                return ["bgColorSuccessLight"];
                            }
                            else if(params.data.qc_check == "NOT OK")
                            {
                                return ["highlitRow"];
                            }
                            else{
                                return "";
                            }
                            
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName: 'Device Type',
                width:160,
                field: 'type',
                filter: true,
                cellClass:function(params){
                    try{
                        if(params.data.qc_check != "" && params.data.qc_check != undefined)
                        {
                            if(params.data.qc_check == "OK")
                            {
                                return ["bgColorSuccessLight"];
                            }
                            else if(params.data.qc_check == "NOT OK")
                            {
                                return ["highlitRow"];
                            }
                            else{
                                return "";
                            }
                            
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName: 'Truck No',
                width:120,
                field: 'truck_no',
                filter: true,
                // valueGetter: params =>{
                //     console.log("params", params.data.truck_no)
                //     return params.data.truck_no;
                // } ,
                cellClass:function(params){
                    try{
                        if(params.data.qc_check != "" && params.data.qc_check != undefined)
                        {
                            if(params.data.qc_check == "OK")
                            {
                                return ["bgColorSuccessLight"];
                            }
                            else if(params.data.qc_check == "NOT OK")
                            {
                                return ["highlitRow"];
                            }
                            else{
                                return "";
                            }
                            
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            
            {
                headerName: 'Install Location',
                width:200,
                field: 'registereddevices',
                filter: true,
                valueGetter:function(params){
                    // console.log("params ", params.data.registereddevices)
                    try{
                        if(params.data.registereddevices.length > 0)
                        {
                            return params.data.registereddevices[0].installed_location;
                        }
                        else{
                            return "";
                        }
                    }
                    catch(e){
                        return "";
                    }
                },
                cellClass:function(params){
                    try{
                        if(params.data.qc_check != "" && params.data.qc_check != undefined)
                        {
                            if(params.data.qc_check == "OK")
                            {
                                return ["bgColorSuccessLight"];
                            }
                            else if(params.data.qc_check == "NOT OK")
                            {
                                return ["highlitRow"];
                            }
                            else{
                                return "";
                            }
                            
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName: 'Last Active On',
                width:160,
                field: 'last_active_on',
                filter: true,
                resizable:true,
                valueGetter:function(params){
                    try{
                        if(params.data.last_active_on != "" && params.data.last_active_on != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.last_active_on);
                        }
                    }
                    catch(e){
                        return "";
                    }
                },
                cellClass:function(params){
                    try{
                        if(params.data.qc_check != "" && params.data.qc_check != undefined)
                        {
                            if(params.data.qc_check == "OK")
                            {
                                return ["bgColorSuccessLight"];
                            }
                            else if(params.data.qc_check == "NOT OK")
                            {
                                return ["highlitRow"];
                            }
                            else{
                                return "";
                            }
                            
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName: 'Last Data Received On',
                width:180,
                field: 'modified_date',
                filter: true,
                resizable:true,
                valueGetter:function(params){
                    try{
                        if(params.data.modified_date != "" && params.data.modified_date != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.modified_date);
                        }
                    }
                    catch(e){
                        return "";
                    }
                },
                cellClass:function(params){
                    try{
                        if(params.data.qc_check != "" && params.data.qc_check != undefined)
                        {
                            if(params.data.qc_check == "OK")
                            {
                                return ["bgColorSuccessLight"];
                            }
                            else if(params.data.qc_check == "NOT OK")
                            {
                                return ["highlitRow"];
                            }
                            else{
                                return "";
                            }
                            
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName: 'Last Registered On',
                width:160,
                field: 'created_date',
                filter: true,
                resizable:true,
                valueGetter:function(params){
                    try{
                        if(params.data.created_date != "" && params.data.created_date != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.created_date);
                        }
                    }
                    catch(e){
                        return "";
                    }
                },
                cellClass:function(params){
                    try{
                        if(params.data.qc_check != "" && params.data.qc_check != undefined)
                        {
                            if(params.data.qc_check == "OK")
                            {
                                return ["bgColorSuccessLight"];
                            }
                            else if(params.data.qc_check == "NOT OK")
                            {
                                return ["highlitRow"];
                            }
                            else{
                                return "";
                            }
                            
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName: 'Assigned On',
                width:160,
                field: 'truck_no',
                filter: true,
                valueGetter:function(params){
                    // console.log("params ", params.data.registereddevices)
                    try{
                        if(params.data.registereddevices.length > 0)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.registereddevices[0].device_registered_on);
                        }
                        else{
                            return "";
                        }
                    }
                    catch(e){
                        return "";
                    }
                },
                cellClass:function(params){
                    try{
                        if(params.data.qc_check != "" && params.data.qc_check != undefined)
                        {
                            if(params.data.qc_check == "OK")
                            {
                                return ["bgColorSuccessLight"];
                            }
                            else if(params.data.qc_check == "NOT OK")
                            {
                                return ["highlitRow"];
                            }
                            else{
                                return "";
                            }
                            
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName: 'Phone Number',
                field: 'phone_no',
                width:180,
                filter: true,
                // hide:hidecol,
                cellClass:function(params){
                    try{
                        if(params.data.qc_check != "" && params.data.qc_check != undefined)
                        {
                            if(params.data.qc_check == "OK")
                            {
                                return ["bgColorSuccessLight"];
                            }
                            else if(params.data.qc_check == "NOT OK")
                            {
                                return ["highlitRow"];
                            }
                            else{
                                return "";
                            }
                            
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName: 'CCID',
                field: 'ccid',
                width:180,
                filter: true,
                // hide:hidecol,
                cellClass:function(params){
                    try{
                        if(params.data.qc_check != "" && params.data.qc_check != undefined)
                        {
                            if(params.data.qc_check == "OK")
                            {
                                return ["bgColorSuccessLight"];
                            }
                            else if(params.data.qc_check == "NOT OK")
                            {
                                return ["highlitRow"];
                            }
                            else{
                                return "";
                            }
                            
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName: 'Enalytics PH No',
                field: 'enalytics_phone_no',
                width:180,
                filter: true,
                // hide:hidecol,
                cellClass:function(params){
                    try{
                        if(params.data.qc_check != "" && params.data.qc_check != undefined)
                        {
                            if(params.data.qc_check == "OK")
                            {
                                return ["bgColorSuccessLight"];
                            }
                            else if(params.data.qc_check == "NOT OK")
                            {
                                return ["highlitRow"];
                            }
                            else{
                                return "";
                            }
                            
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName: 'Sim Provider',
                field: 'sim_provider',
                width:120,
                filter: true,
                // hide:hidecol,
                cellClass:function(params){
                    try{
                        if(params.data.qc_check != "" && params.data.qc_check != undefined)
                        {
                            if(params.data.qc_check == "OK")
                            {
                                return ["bgColorSuccessLight"];
                            }
                            else if(params.data.qc_check == "NOT OK")
                            {
                                return ["highlitRow"];
                            }
                            else{
                                return "";
                            }
                            
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName: 'FW Version',
                width:120,
                field: 'fw',
                filter: true,
                // hide:hidecol,
                cellClass:function(params){
                    try{
                        if(params.data.qc_check != "" && params.data.qc_check != undefined)
                        {
                            if(params.data.qc_check == "OK")
                            {
                                return ["bgColorSuccessLight"];
                            }
                            else if(params.data.qc_check == "NOT OK")
                            {
                                return ["highlitRow"];
                            }
                            else{
                                return "";
                            }
                            
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            // {
            //     headerName: 'Device Version',
            //     width:140,
            //     field: 'board_version',
            //     filter: true,
            // },
            {
                headerName: 'Organization Name',
                width:250,
                field: 'org_name',
                filter: true,
                // hide:hidecol,
                cellClass:function(params){
                    try{
                        if(params.data.qc_check != "" && params.data.qc_check != undefined)
                        {
                            if(params.data.qc_check == "OK")
                            {
                                return ["bgColorSuccessLight"];
                            }
                            else if(params.data.qc_check == "NOT OK")
                            {
                                return ["highlitRow"];
                            }
                            else{
                                return "";
                            }
                            
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            
            {
                headerName: 'Transporter Name',
                width:200,
                field: 'registereddevices',
                filter: true,
                // hide:hidecol,
                valueGetter:function(params){
                    // console.log("params ", params.data.registereddevices)
                    try{
                        if(params.data.registereddevices.length > 0)
                        {
                            return params.data.registereddevices[0].transporter_name;
                        }
                        else{
                            return "";
                        }
                    }
                    catch(e){
                        return "";
                    }
                },
                cellClass:function(params){
                    try{
                        if(params.data.qc_check != "" && params.data.qc_check != undefined)
                        {
                            if(params.data.qc_check == "OK")
                            {
                                return ["bgColorSuccessLight"];
                            }
                            else if(params.data.qc_check == "NOT OK")
                            {
                                return ["highlitRow"];
                            }
                            else{
                                return "";
                            }
                            
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            
            {
                headerName: 'Battery',
                width:120,
                field: 'last_battery',
                filter: true,
                // hide:hidecol,
                cellClass:function(params){
                    try{
                        if(params.data.qc_check != "" && params.data.qc_check != undefined)
                        {
                            if(params.data.qc_check == "OK")
                            {
                                return ["bgColorSuccessLight"];
                            }
                            else if(params.data.qc_check == "NOT OK")
                            {
                                return ["highlitRow"];
                            }
                            else{
                                return "";
                            }
                            
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            
            {
                headerName: 'Connected Voltage',
                width:120,
                field: 'last_connected_voltage',
                filter: true,
                // hide:hidecol,
                cellClass:function(params){
                    try{
                        if(params.data.qc_check != "" && params.data.qc_check != undefined)
                        {
                            if(params.data.qc_check == "OK")
                            {
                                return ["bgColorSuccessLight"];
                            }
                            else if(params.data.qc_check == "NOT OK")
                            {
                                return ["highlitRow"];
                            }
                            else{
                                return "";
                            }
                            
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            
            {
                headerName: 'GPS Fix',
                width:120,
                field: 'last_gps_fix',
                filter: true,
                // hide:hidecol,
                cellClass:function(params){
                    try{
                        if(params.data.qc_check != "" && params.data.qc_check != undefined)
                        {
                            if(params.data.qc_check == "OK")
                            {
                                return ["bgColorSuccessLight"];
                            }
                            else if(params.data.qc_check == "NOT OK")
                            {
                                return ["highlitRow"];
                            }
                            else{
                                return "";
                            }
                            
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            
            {
                headerName: 'Device Orientation',
                width:120,
                field: 'device_orientation',
                filter: true,
                cellClass:function(params){
                    try{
                        if(params.data.qc_check != "" && params.data.qc_check != undefined)
                        {
                            if(params.data.qc_check == "OK")
                            {
                                return ["bgColorSuccessLight"];
                            }
                            else if(params.data.qc_check == "NOT OK")
                            {
                                return ["highlitRow"];
                            }
                            else{
                                return "";
                            }
                            
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName: 'Ignition',
                width:120,
                field: 'last_ignition',
                filter: true,
                // hide:hidecol,
                cellClass:function(params){
                    try{
                        if(params.data.qc_check != "" && params.data.qc_check != undefined)
                        {
                            if(params.data.qc_check == "OK")
                            {
                                return ["bgColorSuccessLight"];
                            }
                            else if(params.data.qc_check == "NOT OK")
                            {
                                return ["highlitRow"];
                            }
                            else{
                                return "";
                            }
                            
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            
            {
                headerName: 'Last Main Power Status',
                width:120,
                field: 'last_main_power_status',
                filter: true,
                // hide:hidecol,
                cellClass:function(params){
                    try{
                        if(params.data.qc_check != "" && params.data.qc_check != undefined)
                        {
                            if(params.data.qc_check == "OK")
                            {
                                return ["bgColorSuccessLight"];
                            }
                            else if(params.data.qc_check == "NOT OK")
                            {
                                return ["highlitRow"];
                            }
                            else{
                                return "";
                            }
                            
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            
            {
                headerName: 'Satellites',
                width:120,
                field: 'last_satellites',
                filter: true,
                // hide:hidecol,
                cellClass:function(params){
                    try{
                        if(params.data.qc_check != "" && params.data.qc_check != undefined)
                        {
                            if(params.data.qc_check == "OK")
                            {
                                return ["bgColorSuccessLight"];
                            }
                            else if(params.data.qc_check == "NOT OK")
                            {
                                return ["highlitRow"];
                            }
                            else{
                                return "";
                            }
                            
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            
            {
                headerName: 'QC Check',
                width:120,
                field: 'qc_check',
                filter: true,
                // hide:hidecol,
                cellClass:function(params){
                    try{
                        if(params.data.qc_check != "" && params.data.qc_check != undefined)
                        {
                            if(params.data.qc_check == "OK")
                            {
                                return ["bgColorSuccessLight"];
                            }
                            else if(params.data.qc_check == "NOT OK")
                            {
                                return ["highlitRow"];
                            }
                            else{
                                return "";
                            }
                            
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            
            {
                headerName: 'Lifetime QC Check',
                width:120,
                field: 'lifetime_qc_check',
                filter: true,
                // hide:hidecol,
                cellClass:function(params){
                    try{
                        if(params.data.qc_check != "" && params.data.qc_check != undefined)
                        {
                            if(params.data.qc_check == "OK")
                            {
                                return ["bgColorSuccessLight"];
                            }
                            else if(params.data.qc_check == "NOT OK")
                            {
                                return ["highlitRow"];
                            }
                            else{
                                return "";
                            }
                            
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName: '',
                field: '',
                width:100,
                filter: true,
                cellRendererSelector:function(params){	
					var rendComponent = {
						component: 'Unmapdevice'
					};
					return rendComponent;
				},
            },
            // {
            //     headerName: '',
            //     field: '',
            //     width:100,
            //     filter: true,
            //     // hide:hidecol,
            //     cellRendererSelector:function(params){
            //         try{
            //             if(params.data.registereddevices.length > 0)
            //             {
            //                 return {
            //                     component:"modifieddevice"
            //                 }
            //             }
            //             else{
            //                 return "";
            //             }
            //         }
            //         catch(e){
            //             return "";
            //         }
                    
            //     },
            //     cellClass:function(params){
            //         try{
            //             if(params.data.qc_check != "" && params.data.qc_check != undefined)
            //             {
            //                 if(params.data.qc_check == "OK")
            //                 {
            //                     return ["bgColorSuccessLight"];
            //                 }
            //                 else if(params.data.qc_check == "NOT OK")
            //                 {
            //                     return ["highlitRow"];
            //                 }
            //                 else{
            //                     return "";
            //                 }
                            
            //             }
            //         }
            //         catch(e){
            //             return "";
            //         }
            //     }
            // } ,

            {
                headerName : "Device Sent To",
                field : "org",
                width : "120"
            }
        ];
		var columnwithDefsHistory = [
			{
                headerName:"Device ID",
                field:"device_id",
                width:150,
            },
			{
                headerName:"Shipment ID",
                field:"shipment_id",
                width:150,
            },
			{
				headerName: "Shipment Time",
				field: "shipment_time",
				width: 150,
				valueGetter:function(params){
                    try{
                       
                        if(params.data.shipment_time != "" && params.data.shipment_time != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.shipment_time);
                        }
                        else{
                            return "Not Available";
                        }
                    }
                    catch(e){}
                },
			},
			{
                headerName:"Status",
                field:"status",
                width:120,
				valueGetter:function(params){
					try {
						var status = "NA"
						if (params.data.status == 3 || params.data.status == "3") {
							status = "At Plant";
						}
						if (params.data.status == 8 || params.data.status == "8") {
							status = "Intransit To Customer";
						}
						if (params.data.status == 9 || params.data.status == "9") {
							status = "Proximity Customer Location";
						}
						if (params.data.status == 10 || params.data.status == "10") {
							status = "Near Customer Vicinity";
						}
						if (params.data.status == 11 || params.data.status == "11") {
							status = "Reached Customer Vicinity";
						}
						if (params.data.status == 12 || params.data.status == "12") {
							status = "Left Customer Vicinity";
						}
						if (params.data.status == 13 || params.data.status == "13") {
							status = "Possible Trip Clouser";
						}
						if (params.data.status == 14 || params.data.status == "14") {
							status = "Proximity Trip Clouser";
						}
						if (params.data.status == 15 || params.data.status == "15") {
							status = "Trip Completed";
						}
						return status
					}
                    catch(e){}
                }
            },
			{
                headerName:"Customer Name",
                field:"consignee_name",
                width:150,
            },
			{
                headerName:"Customer City",
                field:"consignee_city",
                width:150,
            },
			{
                headerName:"Customer State",
                field:"consignee_state",
                width:150,
            },
			{
                headerName:"Customer Code",
                field:"consignee_code",
                width:150,
            },
			{
                headerName:"Customer Pincode",
                field:"consignee_pincode",
                width:150,
            },
		]	
		return (
			<div className="container-fluid">
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}
				>
				</SweetAlert>
                <SweetAlert
                    show={this.state.show1}
                    title={this.state.basicTitle1}
                    input
                    required = {false}
                    showCancel
                    cancelBtnBsStyle="default"
                    onConfirm={this.confirmDeviceUnMap}
                    onCancel={this.hideAlert}
                >
                </SweetAlert> 
				
				<div className="col-xl-12 col-lg-12 col-sm-12 beffect">
					<div className="card">
						<div className="card-body" id="counterCardBody">
							<div className="crm-numbers pb-0">
								<div className="row">
									<div className="col cursorPointer pt-30px" onClick={this.onClickCounter.bind(this, "0")}>
										<span className="f13"><i className="icofont icofont-truck-alt f22 txt-primary"></i><br />Total</span>
										<h4 className="txt-primary f45"><span className="counter"><CountUp end={this.state.qctotal.length} /></span></h4>
									</div>
									<div className="col cursorPointer pt-30px" onClick={this.onClickCounter.bind(this, "1")}>
										<span className="f13"><i className="fa fa-tachometer f22 f22 txt-secondary"></i><br />Total Installed</span>
										<h4 className="txt-secondary f45"><span className="counter"><CountUp end={this.state.registereddevices.length} /></span></h4>
									</div>
									<div className="col cursorPointer pt-30px" onClick={this.onClickCounter.bind(this, "2")}>
										<span className="f13"><i className="icofont icofont-truck-alt f22 txt-success"></i><br />Installed Today</span>
										<h4 className="txt-success f45"><span className="counter"><CountUp end={this.state.registereddevices_today.length} /></span></h4>
									</div>
									<div className="col cursorPointer pt-30px" onClick={this.onClickCounter.bind(this, "3")}>
										<span className="f13"><i className="icofont icofont-truck-alt f22 txt-danger"></i><br />Replaced Today</span>
										<h4 className="txt-danger f45"><span className="counter"><CountUp end={this.state.replacedtoday.length} /></span></h4>
									</div>
									<div className="col cursorPointer pt-30px" onClick={this.onClickCounter.bind(this, "4")}>
										<span className="f13"><i className="icofont icofont-truck-alt f22 txt-warning"></i><br />No GPS Today</span>
										<h4 className="txt-warning f45"><span className="counter"><CountUp end={this.state.no_gps_today.length} /></span></h4>
									</div>
									<div className="col cursorPointer pt-30px" onClick={this.onClickCounter.bind(this, "5")}>
										<span className="f13"><i className="icofont icofont-truck-alt f22 txt-info"></i><br />No GPS(6 HRS)</span>
										<h4 className="txt-info f45"><span className="counter"><CountUp end={this.state.nogpslast6hrs.length} /></span></h4>
									</div>
									<div className="col cursorPointer pt-30px" onClick={this.onClickCounter.bind(this, "6")}>
										<span className="f13"><i className="icofont icofont-truck-alt f22 txt-danger"></i><br />No GPS Fix</span>
										<h4 className="txt-danger f45"><span className="counter"><CountUp end={this.state.nogpsfix.length} /></span></h4>
									</div>
                                    <div className="col cursorPointer pt-30px" onClick={this.onClickCounter.bind(this, "7")}>
										<span className="f13"><i className="icofont icofont-truck-alt f22 txt-danger"></i><br />No Data Received(6 Hrs)</span>
										<h4 className="txt-danger f45"><span className="counter"><CountUp end={this.state.nodatareceived6hrs.length} /></span></h4>
									</div>
                                    <div className="col cursorPointer pt-30px" onClick={this.onClickCounter.bind(this, "8")}>
										<span className="f13"><i className="icofont icofont-truck-alt f22 txt-danger"></i><br />No Data Received Today(6 Hrs)</span>
										<h4 className="txt-danger f45"><span className="counter"><CountUp end={this.state.nodatareceived6hrstoday.length} /></span></h4>
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
				{this.state.alert}
				<div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>
                                    <i className="menu-icon page-title-icon"></i> <span>Devices</span>

                                </h5>
                            </div>

                            <div className="card-body">
                            <div className="row">
									
									<div className="col-xl-12 mb-10p">
                                        <span className="layoutbtns">
                                        <button className="float-right custom-btn white btn-info" style={{ marginRight: "10px" }} onClick={this.resetState.bind(this)}>
												<i className="icofont icofont-refresh"></i> Reset Default Layout
											</button>

                                            <button className="float-right custom-btn white btn-warning" onClick={this.onClickAssignDevice.bind(this)} style={{ marginRight: "10px" }}  >
                                                Assign Device
                                            </button>

                                        </span>
                                    </div>
								</div>
                                <div className="row col-xl-12 col-lg-12">
                                </div>
                                <div className="row col-xl-12 col-lg-12">
                                    <div className="col-xl-12 col-lg-12">
										<div id="myGrid" style={{ height: "500px", width: "100%" }} className="ag-theme-balham">
											<AgGridReact
												modules={this.state.modules}
												columnDefs={columnwithDefs}
												defaultColDef={this.state.defaultColDef}
												rowData={this.state.rowData}
												enableCharts={false}
												enableRangeSelection={true}
												autoGroupColumnDef={this.state.autoGroupColumnDef}
												onGridReady={this.onGridReady}
												onGridState={this.onGridState}
												statusBar={this.state.statusBar}
												sideBar={this.state.sideBar}
												paginationPageSize={this.state.paginationPageSize}
												pagination={true}
												components={this.state.components}
												frameworkComponents={this.state.frameworkComponents}

												//floatingFilter={true}
												onCellEditingStopped={this.onCellUpdateData.bind(this)}
												//onCellClicked={this.popmarker}
												gridOptions={{
													context: { componentParent: this }
												}}
												onCellClicked={this.handleCellClicked}
												onCellValueChanged={this.onCellValueChanged}
											/>
										</div>
										{/* <button type="button" onClick={this.formAssignHandler.bind(this)} className="btn btn-primary float-right">Update Now</button> */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={"dataLoadpage " + (this.state.loadshow)}></div>
				<div className={"dataLoadpageimg " + (this.state.loadshow)}>
					<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
				<div className={"slide-r "+(this.state.sliderAddTransporter)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Assign Device
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.saveDevice.bind(this)}>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Select Device*</label>
							    <Select 
                                    className="col-sm-12" 
                                    name="deviceId" 
                                    id="deviceId" 
                                   onChange={this.newFilter.bind(this,"deviceId")}
                                    value={this.state.deviceId} 
                                      options={this.state.unAssignedDeviceList}
                                    required ={false}
                                />
							</div>
                            
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Select Truck*</label>
							    <Select
								className="col-sm-12"
								name="truck_no"
								id="truck_no"
								value={this.state.mobile_network}
								 onChange={this.newFilter.bind(this,"truck_no")}
								  options={this.state.unAssignedTruckArr}
                                required ={true}
										/>
							</div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Install Location*</label>
							    <Select
								className="col-sm-12"
								name="install_Location"
								id="install_Location"
								value={this.state.mobile_network}
								 onChange={this.newFilter.bind(this, "install_Location")}
								 options={this.state.getinstalledlocation}
                                required ={true}
										/>
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>

                        </div>
					</div>
				</div>

			</div>
		);
	}
}

function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}                 
function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = parseInt(hours);
	var minutes = (num-(rhours*3600)) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}                              

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
function dateScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function distance(lat1, lon1, lat2, lon2, unit) {
	if ((lat1 == lat2) && (lon1 == lon2)) {
		return 0;
	}
	else {
		var radlat1 = Math.PI * lat1/180;
		var radlat2 = Math.PI * lat2/180;
		var theta = lon1-lon2;
		var radtheta = Math.PI * theta/180;
		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = dist * 180/Math.PI;
		dist = dist * 60 * 1.1515;
		if (unit=="K") { dist = dist * 1.609344 }
		if (unit=="N") { dist = dist * 0.8684 }
		return dist;
	}
}
function getDatePicker() {
	function Datepicker() {}
	Datepicker.prototype.init = function(params) {
	  this.eInput = document.createElement('input');
	  this.eInput.value = params.value;
	  $(this.eInput).datepicker({ dateFormat: 'dd-mm-yy' });
	};
	Datepicker.prototype.getGui = function() {
	  return this.eInput;
	};
	Datepicker.prototype.afterGuiAttached = function() {
	  this.eInput.focus();
	  this.eInput.select();
	};
	Datepicker.prototype.getValue = function() {
	  return this.eInput.value;
	};
	Datepicker.prototype.destroy = function() {};
	Datepicker.prototype.isPopup = function() {
	  return false;
	};
	return Datepicker;
  }


  function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};
function dateComparator(date1, date2) {
	
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }

function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}
function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}
function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		format:'Y-m-d',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function getHours(startdate, enddate)
{
    var end = moment(enddate); //todays date
    var start = moment(startdate); // another date
    var duration = moment.duration(start.diff(end));
    var hours = duration.asHours();
   // var days = duration.asDays();
   return hours;
}
$(document).on("click",".rowstatus",function(){
    var clickedData=$(this).attr("id");
    var splitData=clickedData.split("_");
    var status=splitData[0];
    var rowId=splitData[1];
    if(status !='' && rowId !='')
    {
        var params={
            status:status,
            rowId:rowId
        }
        redirectURL.post("/common/changeCustomerStatus",params).then((response)=>{
            // console.log(response.data);
            if(response.data.status == 'success')
            {
                alert("Status Changed sucessfully");
            }
            else{
                alert("Failed to change the status");
            }
            window.location.reload();

        }).catch(function(error){
            console.log(error);
		})
    }
    // console.log("clickedData",clickedData);
});

$(document).ready(function(){
    $(document).on("click",".dropdown-truck-no",function(){
        var vals = $(this).text();
         $("#inputTruck").val(vals);
         $("#trucks-dropdown").removeClass("show-m");
         $("#trucks-dropdown").addClass("show-n");
    });

    $("body").on("click",function(e){
        var container = $("#inputTruck");
        if(!container.is(e.target))
        {
            try{
                if($("#trucks-dropdown").attr("class").includes("show-m") || $("#trucks-dropdown").attr("class") == "trucks-dropdown")
                {
                    $("#trucks-dropdown").removeClass("show-m");
                    $("#trucks-dropdown").addClass("show-n");
                }
            }catch(e){}
            
        }
        
        
    })

    $(document).on("click",".dropdown-device",function(){
        var vals = $(this).text();
         $("#inputDevice").val(vals);
         $("#device-dropdown").removeClass("show-m");
         $("#device-dropdown").addClass("show-n");
    });

    $("body").on("click",function(e){
        var container = $("#inputDevice");
        if(!container.is(e.target))
        {
            try{
                if($("#device-dropdown").attr("class").includes("show-m") || $("#device-dropdown").attr("class") == "device-dropdown")
                {
                    $("#device-dropdown").removeClass("show-m");
                    $("#device-dropdown").addClass("show-n");
                }
            }catch(e){}
            
        }
        
        
    })

    
    $(document).on("click",".dropdown-device",function(){
        var vals = $(this).text();
         $("#unmapinputDevice").val(vals);
         $("#device-dropdown").removeClass("show-m");
         $("#device-dropdown").addClass("show-n");
    });

    $("body").on("click",function(e){
        var container = $("#unmapinputDevice");
        if(!container.is(e.target))
        {
            try{
                if($("#device-dropdown").attr("class").includes("show-m") || $("#device-dropdown").attr("class") == "device-dropdown")
                {
                    $("#device-dropdown").removeClass("show-m");
                    $("#device-dropdown").addClass("show-n");
                }
            }catch(e){}
            
        }
        
        
    })

})