/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';
import { Map, InfoWindow, Marker, GoogleApiWrapper, Polygon, Polyline } from 'google-maps-react';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';

import SweetAlert from 'react-bootstrap-sweetalert';
import DrawMap from '../common/drawmap';
import MyHeaderComponent from "./deviationheaderaction";
import Showtolls from "./showtolls";
import ShowChokePoints from "./showchokepoints";
import ShowTollsList from "./showtollslist";
import TollGrid from "./deviationtollgrid";
import ChokePointsGrid from "./deviationchokepoints";
import ApprovalDeviationRoute from "./approvaldeviationroute";
import DateDeviationRoute from "./datedeviationroute";
import ViewDeviationRouteData from "./viewdeviationroutedata";
import RouteGrid from "./deviationroutedata";
import $ from 'jquery';
import { getHyphenYYYYMMDD, getHyphenYYYYMMDDHHMMSS, getDDMMYYYYHHMMSS } from '../common/utils';
var moment = require('moment');
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var aggriddata = [];
var originaldata;
var infoBox = require('../common/google-infowindow');
const map_plants = { "consigner_code": "consigner_name" }
const plant_coords = { }
var map;
var waypoints = [];
var globalWayPointCount = 0;
var directionsDisplays = [];


export default class CreateBaseRoute extends Component {

    state = {
        loadshow: 'show-n',
        overly: 'show-n',
        modules: AllModules,
        alert: null,
        show: false,
        basicTitle: '',
        basicType: "default",
        dept_code: '',
        defaultColDef: {
            sortable: true,
            filter: true,
            //editable: true,
            autoHeight: true,
            resizable: true,
            width: 130
        },
        rowData: [],
        headerHeight: 60,
        rowHeight: 50,
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
        animateRows: true,
        debug: true,
        showToolPanel: false,
        griddata: [],
        routeRowData: {},
        rowSelection: "single",
        context: { componentParent: this },
        frameworkComponents: {
            MyHeaderComponent: MyHeaderComponent,
            Showtolls: Showtolls,
            ShowTollsList: ShowTollsList,
            ApprovalDeviationRoute: ApprovalDeviationRoute,
            DateDeviationRoute: DateDeviationRoute,
            ViewDeviationRouteData: ViewDeviationRouteData,
            ShowChokePoints: ShowChokePoints
        },
        loadingCellRenderer: "customLoadingCellRenderer",
        loadingCellRendererParams: { loadingMessage: "One moment please..." },
        rowModelType: "serverSide",
        paginationPageSize: 50,
        cacheBlockSize: 50,
        maxBlocksInCache: 2,
        pageTitle: "Summary",
        originaldata: "",
        orignalresponse: "",
        mapinfo: "",
        originoption: { "value": "--Select--", "label": "--Select--" },
        originoption1: { "value": "--Select--", "label": "--Select--" },
        destinationoption: { "value": "--Select--", "label": "--Select--" },
        destinationoption1: { "value": "--Select--", "label": "--Select--" },
        destinationarr: [],
        selectedorigin: '',
        selecteddestination: '',
        columnDefs: [],
        checkedinputs: [],
        uncheckedinputs: [],
        noofroutes: 3,
        tolls: [],
        tollshow: false,
        plantcoords: "",
        plantname: "",
        sliderTranslate: "",
        viewtolls: [],
        tollrowid: "",
        routeid: "",
        routeapprovestatus: "",
        open: false,
        route_reason: "",
        gridapi: [],
        viewroutedata: [],
        sliderRouteTranslate: "",
        sliderRouteTranslate1: "",
        sliderChokeTranslate: "",
        chokepoints: [],
        chokepointdata: [],
        originoptions: [],
        btn1: "btn-default",
        btn2: "btn-default",
        btn3: "btn-default",
        btn4: "btn-default",
        complete_analysis: [],
        base_routes_anlsysis: [],
        transporters_analysis: [],
        complete_trips: [],
        breaks_list: [],
        route_coords: [],
        gridHeight: "160px",
        "mapfor": "",
        destinationOptions: [],
        defaultTrips: [],
        selectedRoutes: [],
        dealersList: [],
        route_coords1: [],
        breaks_list1: [],
        tripsGridApi: {},
        defaultCompleteTrips: [],
        hideSummaryTab: false,
        lspusers_options: [],
        truck_types: [],
        period_types: [],
        selected_provider: { "label": "ALL", "value": "ALL" },
        selected_truck_type: { "label": "ALL", "value": "ALL" },
        selected_period_type: { "label": "ALL", "value": "ALL" },
        coords_to_plot: [],
        google_distance_via_waypoints: 0,
        expected_distance: 0,
        consigner_coordinates: {},
        savedBaseRoutes: [],
        selectedSource: "",
        selectedCity: "",
        alteredHeroPRTBaseRoute: {},
        heroSapRoute: "",
        waypointsmodal: false,
        selectedRow: {},
        addNewWayPoints: [],
        existing_route: false,
        changedWaypoints: [],
        defaultRowData: []
    };

    /*Alert Popups*/
    closeAlert = () => {
        // if (this.state.basicTitle == "Saved Successfully") {
        //     window.location.reload();
        // }else{
        //     this.setState({
        //             show: false
        //         });
        // }
        this.setState({
            show: false
        });
        window.location.reload();
    }

    hideAlert = () => {
        this.setState({
            alert: null
        });
    }
    onCloseWaypointsModal() {
        this.setState({
            waypointsmodal: false
        })
    }
    componentDidMount() {
        this.setState({
            overly: "show-m",
            loadshow: "show-m"
        })

        var map_plants = { "consigner_code": "consigner_name" }
        redirectURL.post("/dashboard/getInitialDataForDeviationAnalysis")
            .then((response) => {
                var plantdata = response.data.consigners;
                let mineMap = {};
                console.log("fjdklsjfsdkjlfsd",response.data);
                var originoptions = [{ "label": "--Select--", "value": "--Select--" }];
                plantdata.map((item) => {
                    console.log(item,"item123")
                    originoptions.push({
                        value: item.consigner_code,
                        label:[item.consigner_code+" - "+item.consigner_name]
                    })
                    mineMap[`${item.consigner_code}`] = item.consigner_name
                })
                var destinationOptions = [];
                var destinationarr = response.data.destinations;
                destinationarr.map((item) => {
                    const containsObject = destinationOptions.some(obj => {
                        return obj.value === item && obj.label === item;
                      });
                    if(!containsObject){
                    destinationOptions.push({
                        value: item, label: item
                    })}
                })
                this.setState({
                    originoptions: originoptions,
                    destinationOptions: destinationOptions,
                    destinationarr,
                    mineMap
                })
            })
        redirectURL.post("/dashboard/getallsavedbaseroutesforprt").then((response) => {
            let rowData = response.data.prtsavedbaseroutes;
            console.log("rowData", rowData)
            let destinationarr = this.state.destinationarr;
            let destinationOptions = this.state.destinationOptions;
            destinationarr.map((item) => {
                const containsObject = destinationOptions.some(obj => {
                    return obj.value === item && obj.label === item;
                  });
                if(!containsObject){
                destinationOptions.push({
                    value: item, label: item
                })}
            })
            // rowData.map(e => {
            //     if (destinationarr.includes(e.consignee_city)) { }
            //     else {
            //         destinationOptions.push({ value: e.consignee_city, label: e.consignee_city })
            //     }
            // })
            response.data.prtsavedbaseroutes.map((e) => {
                e["consigner_name"] = this.state.mineMap[e.consigner_code]
            })
            this.setState({
                savedBaseRoutes: response.data.prtsavedbaseroutes,
                rowData: response.data.prtsavedbaseroutes,
                overly: "show-n",
                loadshow: "show-n",
                destinationOptions
            })
            this.renderMap();

        })
    }

    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.setState({
            gridapi: params.api
        })
        this.gridColumnApi = params.columnApi;
        var sort = [
            {
                colId: 'no_of_followers_percentage',
                sort: 'desc',
            },
        ];
        this.gridApi.setSortModel(sort);
        this.gridApi.addEventListener('paginationChanged', (e) => {
            //Reset rows selection based on current page
            // this.resetPaginationSelection(this);
        });

        this.gridApi.forEachLeafNode((node) => {
            // console.log("node here ",node)
            // node.setSelected(true);

        });

    };

    onGridState = () => {
        //  console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);




        //
    }

    changeOriginHandler = originoption => {
        this.setState(
            { originoption }
        );
    }

    changeOriginHandler1 = originoption1 => {
        this.setState(
            { originoption1 }
        );
    }

    changeDestinationHandler = destinationoption => {
        this.setState(
            { destinationoption }
        );

    }

    changeDestinationHandler1 = destinationoption1 => {
        this.setState(
            { destinationoption1 }
        );
    }

    reloadPageData = () => {
        window.location.reload();
    }

    formHandler = (event) => {
        event.preventDefault();
        // loadchecked()
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pagetitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventGridAction,
                "label": googleAnalytics.page.action.formAction,
            }
            googleAnalytics.logEvent(eventOptions);
        }
        try {
            var tcheck = document.getElementById("tollcheck")
            if (tcheck.checked == true) {
                tcheck.checked = false;
            }
        }
        catch (e) {

        }
        this.setState({
            loadshow: 'show-m',
            tolllist: []
        });
        console.log(this.state.originoption,"originoption786")
       
         if(this.state.originoption.value != "" && this.state.destinationoption.value != "") {

            var formdata = {
                consigner_code: this.state.originoption.value,
                consignee_city: this.state.destinationoption.value,
            }
            this.setState({
                selectedSource: this.state.originoption.value,
                selectedCity: this.state.destinationoption.value
            })
            // console.log(formdata)
            this.gridApi.forEachNode((node) => {
                if (node.data.consigner_code == this.state.originoption.value 
                    && (node.data.consignee_city).toLowerCase().includes((this.state.destinationoption.value).toLowerCase())) {
                    node.setSelected(true)
                }
            });
            if (this.state.originoption.value == "ALL" || this.state.destinationoption.value == "ALL") {
                this.setState({
                    // rowData: this.state.savedBaseRoutes,
                    // loadshow: "show-n",
                    // overly: "show-n",
                    // coords_to_plot: [],
                    // consigner_coordinates: {},
                    // heroSapRoute: [],
                basicTitle: "Fields Should not be Empty",
				basicType: "danger",
				show: true,
				loadshow: "show-n",
				overly: "show-n"
                })
                this.renderMap()
            }
            else {
                this.onLoadFormRequest(formdata)
            }
        }
        else {
            this.setState({
                basicTitle: "Please select Consigner and Consignee City",
                basicType: "warning",
                show: true,
                overly: "show-n",
                loadshow: "show-n"
            })
        }

    }

    onLoadFormRequest = (formdata) => {
        this.setState({
            loadshow: "show-m",
            overly: "show-m"
        })
        // console.log("formdata",formdata)
        // console.log("this.state.savedBaseRoutes",this.state.savedBaseRoutes)
        var filteredData = this.state.savedBaseRoutes.filter(e => 
            e.consigner_code == formdata.consigner_code && e.consignee_city.toLowerCase().includes(formdata.consignee_city.toLowerCase()))
        if (filteredData.length == 0) {
            this.setState({
                basicTitle: "No Base route available for selected Coal Mine and Plant",
                basicType: "danger",
                show: true,
                coords_to_plot: [],
                consigner_coordinates: {},
                heroSapRoute: [],
                rowData: []
            })
            this.gridApi.deselectAll();
            this.renderMap()
        }
        else {
            var formdata = {
                "plant_code": this.state.originoption.value,
                "consigner_code": formdata.consigner_code,
                "consignee_city": formdata.consignee_city,
                'dept_code': 'IBL'
            }
            redirectURL.post("dashboard/getConsignerCoordinates", formdata).then((resp) => {
                var coords_to_plot = [];
                var alteredHeroPRTBaseRoute = [];
                if (resp.data.map_coords.length) {
                    if (resp.data.map_coords.route_coords != undefined) {
                        coords_to_plot = eval(resp.data.map_coords[0].route_coords)
                    }
                    if (resp.data.map_coords[0].alteredHeroPRTBaseRoute != undefined) {
                        alteredHeroPRTBaseRoute = JSON.parse(decodeURI(resp.data.map_coords[0].alteredHeroPRTBaseRoute))
                    }
                }
console.log(coords_to_plot,"coords_to_plot434")
                this.setState({ 
                    coords_to_plot: coords_to_plot,
                    heroSapRoute: alteredHeroPRTBaseRoute,
                    google_distance_via_waypoints: filteredData[0]['google_distance_via_waypoints'],
                    expected_distance: filteredData[0]['expected_distance'],
                    consigner_coordinates: resp.data.consigner_coords,
                    selectedRow: filteredData[0],
                    rowData: filteredData
                })
                this.gridApi.selectAll();
                this.renderMap()
            })
        }
        // redirectURL.post("dashboard/getSavedBaseRoute", formdata).then((response)=>{
        //     console.log(response.data)
        //     if(response.data.length == 0)
        //     {
        //         this.setState({
        //             basicTitle: "No Hero SAP Route marked",
        //             basicType: "warning",
        //             show: true,
        //             coords_to_plot: [],
        //             consigner_coordinates: {}
        //         })
        //         this.renderMap()

        //     }
        //     else
        //     {
        //         var formdata = {
        //             "plant_code": this.state.originoption.value
        //         }
        //         redirectURL.post("dashboard/getConsignerCoordinates", formdata).then((resp)=>{
        //             console.log("consigner_coordinates", resp.data, typeof(resp.data))
        //             this.setState({
        //                 coords_to_plot: eval(response.data[0]['route_coords']),
        //                 google_distance_via_waypoints: response.data[0]['google_distance_via_waypoints'],
        //                 expected_distance: response.data[0]['expected_distance'],
        //                 consigner_coordinates: resp.data
        //             })
        //             this.renderMap()

        //         })

        //     }

        // })
    }
    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            sliderTranslate: '',
            sliderRouteTranslate: '',
            sliderChokeTranslate: ""
        });

    }

    changeHandler(event) {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({
            [name]: value
        });
    }

    renderMap = () => {
        loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyBQUmk7hdajZEAYllxKhVPnUx5pXuK-j_Y&libraries=places,drawing,geometry&callback=initMapWeather");
        window.initMapWeather = this.initMap
    }

    initMap = () => {
        this.setState({
            overly: "show-m",
            loadshow: "show-m"
        })
        var heroSapRoutePresent = 0
        if (this.state.heroSapRoute != undefined) {
            if (this.state.heroSapRoute != "") {
                heroSapRoutePresent = 1
            }
        }
        var arr2 = this.state.coords_to_plot;
        console.log('arr2', arr2)
        waypoints = [];
        // for waypoints
        if (arr2.length && heroSapRoutePresent == 0) {
            var freq = Math.round(arr2.length / 25)
            var start_index = freq;
            for (var i = 1; i < 26; i++) {
                // console.log(i, start_index)
                try {
                    waypoints.push({
                        location: new window.google.maps.LatLng(arr2[start_index].lat, arr2[start_index].lng),
                        stopover: true,
                    });
                    start_index += freq
                }
                catch (e) {
                    break
                }
            }

        }
        try {
            var map_type = window.google.maps.MapTypeId.ROADMAP;
            map = new window.google.maps.Map(document.getElementById('map'), {
                // zoom: 8,
                center: new window.google.maps.LatLng(21.904339, 83.186610),
                mapTypeId: map_type,
                mapTypeControl: true,
                mapTypeControlOptions: {
                    style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                    position: window.google.maps.ControlPosition.TOP_RIGHT
                },
                disableDefaultUI: true,
                fullscreenControl: true,
                zoomControl: true,
                panControl: false,

            });

            var bounds = new window.google.maps.LatLngBounds();

            bounds.extend(new window.google.maps.LatLng(23.63936, 68.14712));
            bounds.extend(new window.google.maps.LatLng(28.20453, 97.34466));

            // for (var i = 0; i < arr2.length - 1; i++) {
            //     var locationLatLng = [];
            //     locationLatLng.push(new window.google.maps.LatLng(arr2[i].lat, arr2[i].lng));
            //     locationLatLng.push(new window.google.maps.LatLng(arr2[i + 1].lat, arr2[i + 1].lng));

            //     var consignments_missing_route_line = new window.google.maps.Polyline({
            //         map: map,
            //         path: locationLatLng,
            //         strokeColor: '#157254',
            //         strokeOpacity: .3,
            //         strokeWeight: 2.5,
            //         icons: []
            //     });

            //     var is_straight_line = 0
            //     if(arr2[i].dist_from_prev_point >= 20000)
            //     {
            //         is_straight_line = 1
            //     }
            //     if (arr2[i].msgtype == "G") {
            //         consignments_missing_route_line.setOptions({
            //             strokeColor: "#452a68",
            //             strokeWeight: 5.5,
            //         })
            //     }
            //     if (arr2[i].set == undefined && arr2[i].msgtype != "G") {
            //         consignments_missing_route_line.setOptions({
            //             strokeColor: "#157254"
            //         })
            //     }
            //     else if (arr2[i].set == 1 && arr2[i].msgtype != "G" ) {
            //         consignments_missing_route_line.setOptions({
            //             strokeColor: "#157254"
            //         })
            //     }
            //     else if (arr2[i].set == 2 && arr2[i].msgtype != "G" ) {
            //         consignments_missing_route_line.setOptions({
            //             strokeColor: "#0000ff",
            //             strokeWeight: 5.5,
            //         })
            //     }
            //     else if (arr2[i].set == 3 && arr2[i].msgtype != "G") {
            //         consignments_missing_route_line.setOptions({
            //             strokeColor: "#980000",
            //             strokeWeight: 5.5,
            //         })
            //     }

            //     if(is_straight_line)
            //     {
            //         consignments_missing_route_line.setOptions({
            //             strokeColor: "red",
            //             strokeWeight: 3.5,
            //         })
            //     }
            //     bounds.extend(new window.google.maps.LatLng(arr2[i].lat, arr2[i].lng));
            // }
            // console.log("arrCoords", arr2)
            if (arr2.length && heroSapRoutePresent == 0) {
                this.plotGoogleDirections(new window.google.maps.LatLng(arr2[0].lat, arr2[0].lng),
                    new window.google.maps.LatLng(arr2[(arr2.length - 1)].lat, arr2[(arr2.length - 1)].lng))
            }
            else {
                // console.log(this.state.heroSapRoute, "herosap")
                if (Object.keys(this.state.heroSapRoute).length > 0) {
                    waypoints = this.state.heroSapRoute.request.waypoints;
                }
                let origin = this.state.consigner_coordinates
                let source = new window.google.maps.LatLng(origin.lat, origin.lng)
                this.plotGoogleDirections(source, this.state.selectedRow['consignee_city'], waypoints)
            }


        } catch (e) {
            console.log(e, "error")
        }
        try {
            //plant icon
            let plantLat = this.state.consigner_coordinates.lat;
            let plantLng = this.state.consigner_coordinates.lng;

            let plantMarker = new window.google.maps.Marker({
                position: new window.google.maps.LatLng(plantLat, plantLng),
                icon: require('../../assets/icons/business.png'),
                map
            })
            var infowindow = new window.google.maps.InfoWindow();
            window.google.maps.event.addListener(plantMarker, 'mouseover', ((plantMarker, index) => {
                return () => {
                    var contentarr = []
                    var header = "Plant"
                    contentarr.push({ "key": "Plant", "value": this.state.selectedCity })
                    var contentString = infoBox(plantMarker.icon, header, contentarr)
                    infowindow.setContent(contentString);
                    var currentwindow = infowindow;
                    infowindow.open(map, plantMarker);
                }
            })(plantMarker,));
            window.google.maps.event.addListener(plantMarker, 'mouseout', function () {
                infowindow.close();
            });

            // ending point
            if (arr2.length > 0) {

                var marker = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(arr2[(arr2.length - 1)].lat, arr2[(arr2.length - 1)].lng),
                    icon: require('../../assets/icons/truck-end.png'),
                    map: map,
                });
                window.google.maps.event.addListener(marker, 'click', ((marker) => {
                    return () => {
                        //	console.log(clusters[0])

                        //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
                        var contentarr = []
                        //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                        var header = "End Point"
                        contentarr.push({ "key": "Destination", "value": this.state.selectedCity })

                        var contentString = infoBox(marker.icon, header, contentarr, '')

                        infowindow.setContent(contentString);
                        infowindow.open(map, marker);
                        // console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
                    }
                })(marker));
            }
        }
        catch (e) {
            console.log("error", e)
        }
        map.fitBounds(bounds)
        this.setState({
            overly: "show-n",
            loadshow: "show-n"
        })


    }

    onCellClicked(cell) {
        if (cell.colDef.field == "_id") {
            cell.node.setSelected(true)
            var formdata = {
                "plant_code": cell.data.consigner_code,
                "consigner_code": cell.data.consigner_code,
                "consignee_city": cell.data.consignee_city,
                'dept_code': 'IBL'
            }
            redirectURL.post("dashboard/getConsignerCoordinates", formdata).then((resp) => {
                var heroSapRoute = [];
                var coords_to_plot = [];
                // console.log(resp.data.map_coords,"map_coords234")
                if (resp.data.map_coords.length) {
                    if (resp.data.map_coords[0].route_coords != undefined) {
                        coords_to_plot = eval(resp.data.map_coords[0].route_coords)
                    }
                    if (resp.data.map_coords[0].alteredHeroPRTBaseRoute != undefined) {
                        heroSapRoute = JSON.parse(decodeURI(resp.data.map_coords[0].alteredHeroPRTBaseRoute))
                    }
                }
                // console.log("741",heroSapRoute)
                // console.log("742",coords_to_plot)
                this.setState({
                    coords_to_plot: coords_to_plot,
                    heroSapRoute: heroSapRoute,
                    google_distance_via_waypoints: cell.data['google_distance_via_waypoints'],
                    expected_distance: cell.data['expected_distance'],
                    consigner_coordinates: resp.data.consigner_coords,
                    selectedSource: map_plants[cell.data.consigner_code],
                    selectedCity: cell.data.consignee_city,
                    selectedRow: cell.data,
                    existing_route: true
                })
                this.renderMap()
            })
        }
        else if (cell.colDef.field == "add_way_points") {
            this.setState({
                waypointsmodal: true,
                selectedRow: cell.data,
                existing_route: true
            })
        }
    }
    onRowSelected(cell) {
        // console.log(cell);
        this.setState({
            existing_route: true
        })
    }

    onClickSaveRoute() {
        let selectedNodes = this.gridApi.getSelectedNodes();
        // console.log("waypoints", this.state.addNewWayPoints);
        // if (selectedNodes.length) {
            let selectedData = selectedNodes.map(node => node.data);
            var alteredHeroPRTBaseRoute = this.state.alteredHeroPRTBaseRoute;
            // console.log("selected data 800", selectedData)
            // console.log("alteredHeroPRTBaseRoute 800", alteredHeroPRTBaseRoute)
            if (Object.keys(alteredHeroPRTBaseRoute).length || this.state.addNewWayPoints.length) {
                // console.log(alteredHeroPRTBaseRoute, "alteredHeroPRTBaseRoute")
                if (this.state.existing_route) {
                    var formdata = {
                        "row_id": selectedData[0]['_id'],
                        consigner_code: selectedData[0]['consigner_code'],
                        consignee_city: selectedData[0]['consignee_city'],
                        alteredHeroPRTBaseRoute: encodeURI(JSON.stringify(alteredHeroPRTBaseRoute)),
                        existing_route: this.state.existing_route
                    }
                }
                else {
                    var expected_distance = 0;
                    console.log(1111,alteredHeroPRTBaseRoute.routes);
                    if(alteredHeroPRTBaseRoute.routes){
                    alteredHeroPRTBaseRoute.routes[0].legs.map((e) => {
                        expected_distance = expected_distance + e.distance.value
                    })
                
                    expected_distance = Math.round(expected_distance / 1000)
                    var formdata = {
                        consigner_code: this.state.originoption1.value,
                        // consigner_name: this.state.originoption.label.split("-")[1],
                        // consignee_city: this.state.destinationoption.value,
                        consignee_city: this.state.destination_new,
                        alteredHeroPRTBaseRoute: encodeURI(JSON.stringify(alteredHeroPRTBaseRoute)),
                        existing_route: this.state.existing_route,
                        created_by: localStorage.getItem("email"),
                        expected_distance: expected_distance,
                        google_distance_via_waypoints: expected_distance
                    }
                }else{
                    this.setState({
                        basicTitle: "Route unchanged",
                        basicType: "warning",
                        show: true
                    });
                    return false
                }
                    // this.setState({
                    // basicTitle: "Fields Should not be Empty",
                    // basicType: "danger",
                    // show: true,
                    // loadshow: "show-n",
                    // overly: "show-n"
                    // })
                }

                // console.log(formdata, "formdata")
                this.setState({
                    loadshow: "show-m",
                    overly: "show-m"
                })
                redirectURL.post("/dashboard/saveheroprtbaseroute", formdata).then((response) => {
                    if (response.data.message == "success") {
                        this.setState({
                            basicTitle: "Saved Successfully",
                            basicType: "success",
                            show: true,
                            addNewWayPoints:[],
                            consigner_code:""
                            
                        })
                    }
                    else {
                        this.setState({
                            basicTitle: "Unable to store data",
                            basicType: "warning",
                            show: true
                        })
                    }
                    this.setState({
                        loadshow: "show-n",
                        overly: "show-n"
                    })
                })
            }
            else {
                this.setState({
                    basicTitle: "Route unchanged",
                    basicType: "warning",
                    show: true
                })
            }

        // }
        // else {
        //     this.setState({
        //         basicTitle: "Select atleast one route",
        //         basicType: "warning",
        //         show: true
        //     })
        // }

    }

    // plotSavedGoogleDirections(start, end) {
    //     var routes = response.routes;
    //     console.log(response, "response")
    //     var colors = ['#157254'];
    //     directionsDisplays = [];

    //     // Reset the start and end variables to the actual coordinates
    //     var start = response.routes[0].legs[0].start_location;
    //     var end = response.routes[0].legs[0].end_location;
    //     console.log(start, end)
    //     // Loop through each route
    //     for (var i = 0; i < routes.length; i++) {

    //         var directionsDisplay = new window.google.maps.DirectionsRenderer({
    //         map: map,
    //         directions: response,
    //         routeIndex: i,
    //         draggable: true,
    //         polylineOptions: {

    //             strokeColor: colors[i],
    //             strokeWeight: 4,
    //             strokeOpacity: 1
    //         }
    //         });

    //         // Push the current renderer to an array
    //         directionsDisplays.push(directionsDisplay);

    //         // Listen for the directions_changed event for each route
    //         window.google.maps.event.addListener(directionsDisplay, 'directions_changed', ((directionsDisplay, i)=> {

    //         return ()=> {

    //             var directions = directionsDisplay.getDirections();
    //             this.setState({
    //                 alteredHeroPRTBaseRoute: directions
    //             })
    //             // var new_start = directions.routes[0].legs[0].start_location;
    //             // var new_end = directions.routes[0].legs[0].end_location;

    //             // if ((new_start.toString() !== start.toString()) || (new_end.toString() !== end.toString())) {

    //                 // Remove every route from map
    //                 // for (var j = 0; j < directionsDisplays.length; j++) {

    //                 //     directionsDisplays[j].setMap(null);
    //                 // }

    //                 // // Redraw routes with new start/end coordinates
    //                 // this.plotSavedGoogleDirections(new_start, new_end);
    //             // }
    //         }
    //         })(directionsDisplay, i)); // End listener
    //     } // End route loop
    // }

    plotGoogleDirections(start, end, waypoints) {
        directionsDisplays.map((e) => {
            e.setMap(null)
        })
        var directionsService = new window.google.maps.DirectionsService();

        var method = 'DRIVING';
        // console.log(waypoints, "waypoints")
        var request = {
            origin: start,
            destination: end,
            travelMode: window.google.maps.DirectionsTravelMode[method],
            provideRouteAlternatives: false,
            waypoints: waypoints || [],
            optimizeWaypoints: true,
        };
        console.log(request,"request123")

        directionsService.route(request, (response, status) => {

            if (status == window.google.maps.DirectionsStatus.OK) {

                var routes = response.routes;
                console.log(response, "response")
                var colors = ['#157254'];
                directionsDisplays = [];

                // Reset the start and end variables to the actual coordinates
                // var start = response.routes[0].legs[0].start_location;
                // var end = response.routes[0].legs[0].end_location;

                // Loop through each route
                for (var i = 0; i < routes.length; i++) {

                    var directionsDisplay = new window.google.maps.DirectionsRenderer({
                        map: map,
                        directions: response,
                        routeIndex: i,
                        draggable: true,
                        // suppressMarkers: true,
                        polylineOptions: {
                            strokeColor: colors[i],
                            strokeWeight: 4,
                            strokeOpacity: 1
                        }
                    });
                    var directions = directionsDisplay.getDirections();
                    var changedWaypoints = this.state.changedWaypoints;
                    changedWaypoints.push(directions)
                    this.setState({
                        changedWaypoints: changedWaypoints
                    })
                    // console.log("changedWayPoints 985", changedWaypoints)
                    // after submitting new waypoints inputs, storing the route to save
                    if (this.state.addNewWayPoints.length) {
                        this.setState({
                            alteredHeroPRTBaseRoute: directions
                        })
                    }

                    // Push the current renderer to an array
                    directionsDisplays.push(directionsDisplay);
                    // Listen for the directions_changed event for each route
                    window.google.maps.event.addListener(directionsDisplay, 'directions_changed', ((directionsDisplay, i) => {

                        return () => {

                            var directions = directionsDisplay.getDirections();
                            // var wayPoint = directions.routes[0].legs[0].via_waypoint[0]; //get waypoint as a result of a user dragging

                            // waypoints.push({
                            //     location: wayPoint.location,
                            //     stopover: true
                            // })
                            // console.log("in return statment -------------------------------",waypoints,this.state.changedWaypoints);
                            var changedWaypoints = this.state.changedWaypoints;
                            changedWaypoints.push(directions)

                            // waypoints=directions.geocoded_waypoints
                            // console.log("changedWayPoints 1011", changedWaypoints)
                            // console.log("alteredHeroPRTBaseRoute 1013", directions)
                            this.setState({
                                alteredHeroPRTBaseRoute: directions,
                                changedWaypoints: changedWaypoints
                            })

                            // var new_start = directions.routes[0].legs[0].start_location;
                            // var new_end = directions.routes[0].legs[0].end_location;

                            // if ((new_start.toString() !== start.toString()) || (new_end.toString() !== end.toString())) {

                            //     // Remove every route from map
                            //     for (var j = 0; j < directionsDisplays.length; j++) {

                            //     directionsDisplays[j].setMap(null);
                            //     }

                            //     // Redraw routes with new start/end coordinates
                            //     this.plotGoogleDirections(new_start, new_end);
                            // }
                        }
                    })(directionsDisplay, i)); // End listener
                } // End route loop
            }else{
                this.setState({
                    // rowData: this.state.savedBaseRoutes,
                    // loadshow: "show-n",
                    // overly: "show-n",
                    // coords_to_plot: [],
                    // consigner_coordinates: {},
                    // heroSapRoute: [],
                basicTitle: "Invalid search data",
				basicType: "danger",
				show: true,
				loadshow: "show-n",
				overly: "show-n"
                })            }
        });
    }


    onClickAddWaypoints() {
        // let selectedNodes = this.gridApi.getSelectedNodes();
        // if(selectedNodes.length)
        // {
        // let selectedRow = selectedNodes.map(node => node.data);
        // console.log(selectedRow,"selectedRow")
        this.setState({
            waypointsmodal: true,
            selectedRow: {},
            existing_route: false
        })
        // }
        // else
        // {
        //     this.setState({
        //         basicTitle: "Please select atleast one route",
        //         basicType: "warning",
        //         show: true
        //     })
        // }
    }
    onFocusSearch(idx) {
        var input = document.getElementById('way_point_search_' + idx);
        var searchBox = new window.google.maps.places.SearchBox(input);
        searchBox.addListener('places_changed', function () {
            var places = searchBox.getPlaces();

            if (places.length == 0) {
                return;
            }
            var bounds = new window.google.maps.LatLngBounds();
            places.forEach(function (place) {
                if (!place.geometry) {
                    //   console.log("Returned place contains no geometry");
                    return;
                }


                if (place.geometry.viewport) {
                    // Only geocodes have viewport.
                    bounds.union(place.geometry.viewport);
                } else {
                    bounds.extend(place.geometry.location);
                }
            });
        });
    }

    onDestinationSearch = () => {
        var input = document.getElementById('way_point_destination_search');
        var searchBox = new window.google.maps.places.SearchBox(input);
        searchBox.addListener('places_changed', function () {
            var places = searchBox.getPlaces();

            if (places.length == 0) {
                return;
            }
            var bounds = new window.google.maps.LatLngBounds();
            places.forEach(function (place) {
                if (!place.geometry) {
                    //   console.log("Returned place contains no geometry");
                    return;
                }


                if (place.geometry.viewport) {
                    // Only geocodes have viewport.
                    bounds.union(place.geometry.viewport);
                } else {
                    bounds.extend(place.geometry.location);
                }
            });
        });

    }

    onClickAddNewWayPoint() {
        // var wrapper = $(".addNewWayPointLink");
        globalWayPointCount = globalWayPointCount + 1;
        if (globalWayPointCount > 25) {
            globalWayPointCount = globalWayPointCount - 1;
            this.setState({
                basicTitle: "Only 25 Way Points are allowed",
                basicType: "warning",
                show: true
            })
        }
        else {
            var addNewWayPoints = this.state.addNewWayPoints;
            addNewWayPoints.push(globalWayPointCount)
            this.setState({
                addNewWayPoints: addNewWayPoints,
                search:""
            })
        }

        // $(wrapper).before("<div class='form-group col-xl-12 col-lg-12'><label style='width:100%'>Way Point "+globalWayPointCount+" <i class='fa fa-trash' id='delete_waypoint_"+globalWayPointCount+"' style='color:red;float:right'></i></label><input type='text' name='search' class='form-control' style={{height:'37px'}} id='way_point_"+globalWayPointCount+"' onchange='onWayPointchanged(this);' placeholder='Enter Address to search' autoComplete='new-password' required/></div>"); //add input box
        // onWayPointchanged()

    }

    onSubmitWayPoints(e) {
        e.preventDefault();
        waypoints = []
        source = []
        for (var i = 1; i <= globalWayPointCount; i++) {
            var inputVal = $("#way_point_search_" + i).val();
            waypoints.push({
                location: inputVal,
                stopover: true,
            });
        }
        console.log(waypoints, "waypoints")
        this.setState({
            waypointsmodal: false
        })
        if (this.state.existing_route) {
            let origin = plant_coords[map_plants[this.state.selectedRow['consigner_code']]];
            console.log({origin:this.state.selectedRow['consigner_code']});
            let source = this.state.selectedRow['consigner_code'];
            console.log({source},this.state.selectedRow['consignee_city']);
            const destination = this.state.selectedRow['consignee_city'];
            // console.log("origin",origin)
            // console.log("map_plants", map_plants[this.state.selectedRow['consigner_code']],'\n',this.state.selectedRow['consignee_city'])
            // this.plotGoogleDirections(source, this.state.selectedRow['consignee_city'], waypoints)
            if (['', undefined, null].includes(source) == false && ['', undefined, null].includes(destination) == false) {
                redirectURL.post("dashboard/getConsignerCoordinates", {
                    "plant_code": source,
                    "map_coords": false, destination, dept_code: 'IBL'
                }).then((resp) => {

                    source = new window.google.maps.LatLng(resp.data.consigner_coords.lat, resp.data.consigner_coords.lng)
                    // destination = new window.google.maps.LatLng(resp.data.destinationCoords.lat, resp.data.destinationCoords.lng)
                    console.log("source234",source,'\n destination',destination)
                    // this.gridApi.deselectAll();
                    this.plotGoogleDirections(source, destination, waypoints)

                })
            }
            else {
                console.log(1111);
                // this.gridApi.deselectAll();
                this.setState({
                    basicTitle: "Source & Destination should be selected",
                    basicType: "warning",
                    show: true,
                    addNewWayPoints:[],
                    consigner_code:""
                })
            }
        }
        else {
            let destination_new = $('#way_point_destination_search').val()
            this.setState({
                destination_new
            })
            // console.log('destination_new',destination_new)
            var source = this.state.originoption1.value;
            var destination = destination_new;
            if (['', undefined, null].includes(source) == false && ['', undefined, null].includes(destination) == false) {
                redirectURL.post("dashboard/getConsignerCoordinates", {
                    "plant_code": source,
                    "map_coords": false, destination, dept_code: 'IBL'
                }).then((resp) => {

                    source = new window.google.maps.LatLng(resp.data.consigner_coords.lat, resp.data.consigner_coords.lng)
                    // destination = new window.google.maps.LatLng(resp.data.destinationCoords.lat, resp.data.destinationCoords.lng)
                    console.log("source234",source,'\n destination',destination)
                    // this.gridApi.deselectAll();
                    this.plotGoogleDirections(source, destination_new, waypoints)

                })
            }
            else {
                // this.gridApi.deselectAll();
                this.setState({
                    basicTitle: "Source & Destination should be selected",
                    basicType: "warning",
                    show: true,
                    addNewWayPoints:[],
                    consigner_code:""
                })
            }

        }

    }

    onClickRemoveWayPoint(idx) {
        // console.log(idx)
        var addNewWayPoints = this.state.addNewWayPoints;
        var filtered = addNewWayPoints.filter(e => e != idx);
        if (addNewWayPoints.length == 0) {
            globalWayPointCount = 0;
        }
        else {
            addNewWayPoints = []
            globalWayPointCount = 0
            filtered.map((e, idx) => {
                addNewWayPoints.push(idx + 1)
                globalWayPointCount = globalWayPointCount + 1
            })
        }
        this.setState({
            addNewWayPoints: addNewWayPoints
        })
    }

    render() {
        const dStyles = {
            width: '100%',
            height: '568px',
        }
        const modalStyles = {
            width: '500px !important',
        }
        const columnDefs = [
            {
                headerName: "",
                field: "checkbox",
                width: 50,
                checkboxSelection: true
            },
            {
                headerName: "",
                field: "add_way_points",
                cellRenderer: (params) => {
                    return '<i class="fa fa-map-marker f25"></i> '
                },
                width: 50
            },
            {
                headerName: "",
                field: "_id",
                cellRenderer: (params) => {
                    return '<i class="icofont icofont-map-pins f25"></i> '
                },
                width: 60
            },
            {
                headerName: "Source",
                field: "consigner_name",
                width: 120,
                // valueGetter: (params) => {
                //     return map_plants[params.data.consigner_code]
                // }
            },
            {
                headerName: "Destination",
                field: "consignee_city",
                width: 120
            },
            {
                headerName: "Route Distance (Km)",
                field: "expected_distance",
                width: 120
            },
        ]
        return (

            <div className="container-fluid">
                <div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div class="loader-box">
						<div class="loader-box">
							<div className={`new-loader ${this.state.loadshow}`}></div>
						</div>
					</div>
				</div>
				<div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                {this.state.alert}
                <div className="">
                <div className="mb-4">
                                <h5 >
                                    <i className="icofont icofont-map-pins f25"></i> <span style={{ fontFamily: "Inter-SemiBold" }}>Create Base Routes </span>
                                </h5>
                            </div>
                            </div>
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="row col-xl-12 col-lg-12">
                            <div className="form-group col-xl-2 col-lg-2" style={{ paddingLeft: 0 }}>
                                <label>Coal Mines  </label>
                                <Select
                                    name="originoption"
                                    closeMenuOnSelect={true}
                                    value={this.state.originoption}
                                    className="border-radius-0"
                                    onChange={this.changeOriginHandler.bind(this)}
                                    style={{ borderRadius: "0px" }}
                                    options={this.state.originoptions} required
                                />
                            </div>
                            <div className="form-group col-xl-2 col-lg-2">
                                <label>Plant </label>
                                <Select
                                    name="destinationoption"
                                    closeMenuOnSelect={true}
                                    isMulti={false}
                                    autoComplete="off"
                                    value={this.state.destinationoption}
                                    className="border-radius-0"
                                    onChange={this.changeDestinationHandler.bind(this)}
                                    style={{ borderRadius: "0px" }}
                                    options={this.state.destinationOptions} required
                                />
                            </div>
                            <div className="form-group">
                                <button style={{ marginTop: "30px" }} onClick={this.formHandler.bind(this)} type="button" className="btn btn-success mr-3">Submit</button>
                            </div>
                            <div className="form-group">
                                    {/* <span style={{ "float": "right" }}><i class="fa fa-minus fa-2x" style={{ color: "#157254" }}></i> <b style={{ "paddingRight": "10px" }}>Hero SAP Route </b></span>
                                    <span style={{ "float": "right" }}><i class="fa fa-minus fa-2x" style={{ color: "#452a68" }}></i> <b style={{ "paddingRight": "10px" }}>Missed GPS Route </b></span> */}
                                    <button className="btn btn-warning mr-3" style={{ marginTop: "30px" }}  onClick={this.onClickSaveRoute.bind(this)}>Save Route</button>
                                </div>
                            <div className="form-group">
                                <button style={{ marginTop: "30px" }} onClick={this.reloadPageData.bind(this)} type="button" className="btn btn-info">Reset Filters</button>
                            </div>

                        </div>
                        <div className="card">
                            <div className="card-body row">
                                <div className="col-xl-4 col-lg-4">
                                    <button className="btn btn-success" onClick={this.onClickAddWaypoints.bind(this)}>Add Waypoints</button>

                                </div>
                                <div className="row col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                    <div className="col-xl-4 col-lg-4">
                                        <div id="myGrid" style={{ height: "600px", width: "100%" }} className="ag-theme-balham">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                                columnDefs={columnDefs}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.rowData}
                                                enableCharts={false}
                                                enableRangeSelection={true}
                                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                frameworkComponents={this.state.frameworkComponents}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                                statusBar={this.state.statusBar}
                                                //sideBar={this.state.sideBar}
                                                pagination={true}
                                                onCellClicked={this.onCellClicked.bind(this)}
                                                onRowSelected={this.onRowSelected.bind(this)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-8 col-lg-8" id="map" style={dStyles}></div>
                                </div>
                                {/* <div className="col-xl-12 col-lg-12">
                                    <div className="crm-numbers pb-0">
                                        <h3 className="subH">{"Details"}</h3>
                                        <div className="col-xl-12 col-lg-12 row">
                                            <div className="col route-block">
                                                <label className="sidebar-label">Hero SAP Route Distance(Km)</label>
                                                <div>{this.state.expected_distance}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div> */}
                <Modal open={this.state.waypointsmodal} onClose={this.onCloseWaypointsModal.bind(this)} classNames={{
                    modal: 'customModal2'
                }} >
                    <div className={"row wayPointsDiv"} style={{ padding: '5px 0px', zIndex: '9999', }} >
                        <h5 className="model-title" style={{backgroundColor:"#00c292"}}>Select Waypoints</h5>
                        <form className="row col-xl-12 col-lg-12 mt-20p wayPointsBody" onSubmit={this.onSubmitWayPoints.bind(this)}>
                            <div className='form-group col-xl-12 col-lg-12' >
                                <label>Coal Mines<span style={{ color: "red" }}>*</span> </label>
                                {(this.state.existing_route) ?
                                    <input className="form-control"  name="originoption1"  value={this.state.selectedRow.consigner_name} style={{ height: "37px" }} disabled />
                                    :
                                    <Select
                                        name="originoption1"
                                        closeMenuOnSelect={true}
                                        value={this.state.originoption1}
                                        className="border-radius-0"
                                        onChange={this.changeOriginHandler1.bind(this)}
                                        style={{ borderRadius: "0px" }}
                                        options={this.state.originoptions} required
                                    />
                                }

                            </div>

                            {(this.state.addNewWayPoints.map((e, idx) => {
                                return <div className='form-group col-xl-12 col-lg-12'>
                                    <label className='col-xl-12 col-lg-12'>Way Point {e}<span style={{ color: "red" }}>*</span> <i className='fa fa-trash' style={{ color: 'red', float: "right", cursor: "pointer" }} onClick={this.onClickRemoveWayPoint.bind(this, e)}></i></label>
                                    <input type="text" name="search" onChange={this.onFocusSearch.bind(this, e)} className="form-control" style={{ height: "37px" }} id={"way_point_search_" + (e)} placeholder="Enter Address to search" autoComplete='new-password' required />
                                </div>
                            }))}
                            <div className='col-xl-12 col-lg-12 addNewWayPointLink'>
                                <a href="#0" onClick={this.onClickAddNewWayPoint.bind(this)} style={{ float: "right" }}><i className='fa fa-plus'></i> Add New Waypoint</a>
                            </div>

                            <div className='form-group col-xl-12 col-lg-12'>
                                <label>Plant<span style={{ color: "red" }}>*</span> </label>
                                {(this.state.existing_route) ?
                                    <input className="form-control" value={this.state.selectedRow.consignee_city}  id={"way_point_destination_search"}style={{ height: "37px" }} disabled />
                                    :
                                    <input type="text" name="search1" onChange={this.onDestinationSearch} className="form-control" style={{ height: "37px" }} id={"way_point_destination_search"} placeholder="Enter Destination to search" autoComplete='new-password'
                                    required />

                                    // <Select 
                                    //     name="destinationoption"
                                    //     closeMenuOnSelect={true} 
                                    //     value={this.state.destinationoption} 
                                    //     className="border-radius-0" 
                                    //     onChange={this.changeDestinationHandler.bind(this)} 
                                    //     style={{borderRadius:"0px"}} 
                                    //     options={this.state.destinationOptions} required
                                    // />
                                }
                            </div>
                            {(this.state.addNewWayPoints.length > 0) ?
                                <div className="col-xl-12 col-lg-12" style={{ textAlign: "center" }}>
                                    <button className='btn btn-success'>Submit</button>
                                </div>
                                : ""}

                        </form>
                    </div>
                </Modal>

            </div>

        );
    }
}

function loadScript(url) {
    var index = window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src = url
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}


$(".wayPointsBody .form-group").on("input", "#way_point_" + globalWayPointCount, function () {
    // console.log(this, "check")
    var input = this;
    var searchBox = new window.google.maps.places.SearchBox(input);
    searchBox.addListener('places_changed', function () {
        var places = searchBox.getPlaces();

        if (places.length == 0) {
            return;
        }
        var bounds = new window.google.maps.LatLngBounds();
        places.forEach(function (place) {
            if (!place.geometry) {
                // console.log("Returned place contains no geometry");
                return;
            }


            if (place.geometry.viewport) {
                // Only geocodes have viewport.
                bounds.union(place.geometry.viewport);
            } else {
                bounds.extend(place.geometry.location);
            }
        });
    });
})


$(document).on("click", "i#delete_waypoint_" + globalWayPointCount, function () {
    $(this).remove();
    globalWayPointCount = globalWayPointCount - 1
})

function onWayPointchanged() {
    // console.log("check")
}
