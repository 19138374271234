
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const noKpiSuspStop = (props) => {
    const handleOverspeedClick = (e) => {
        e.stopPropagation();
        //console.log("TransitDelay ",props.data);
        if(props.data.dept_code == 'IBL')
        {
            if(props.data.show_legs == 1)
            {
                props.context.componentParent.onShowTransitKpi(props);
            }
            else
            {
                props.context.componentParent.onShowTransitKpi(0);
            }
        }
        else
        {
            props.context.componentParent.onShowTransitKpi(props);
        }
        
        
    };
    

    return (
        <div>
            <button 
                className="custom-btn f22 label label-success" 
                title="No Suspicious Stoppage" 
                style={{
                    marginRight: "10px", 
                    color: "#c6c6c6", 
                    width: "34px", 
                    display: "flex", 
                    justifyContent: "center", 
                    alignItems: "center",
                    height: "34px",
                    
                }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-siren" style={{ marginTop: '-4px', marginBottom: "5px" }}>
                    <path d="M7 18v-6a5 5 0 1 1 10 0v6"/>
                    <path d="M5 21a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-1a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2z"/>
                    <path d="M21 12h1"/>
                    <path d="M18.5 4.5 18 5"/>
                    <path d="M2 12h1"/>
                    <path d="M12 2v1"/>
                    <path d="m4.929 4.929.707.707"/>
                    <path d="M12 12v6"/>
                </svg>
            </button>
        </div>
    );
};

export default noKpiSuspStop;
