
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const KpiDeviationActions = (props) => {
   
    const handleDeviateClick = (e) => {
        e.stopPropagation();
        console.log("Deviation ",props);
        props.context.componentParent.onShowDeviate(props);
    };

    return (
        <div>
			<button onClick={handleDeviateClick} title="Deviation" style={{ background: "transparent", border: "0px", padding: "0px 5px 0px 8px" }}><img src={require("../../assets/icons/deviation.png")} style={{ width: "20px", filter: "invert(20%) sepia(100%) saturate(7500%) hue-rotate(0deg) brightness(100%) contrast(100%)" }}></img> &nbsp;</button>
       
        </div>
    );
};

export default KpiDeviationActions;
