
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const kpiDeviationColurActions = (props) => {
   
    const handleDeviateClick = (e) => {
        e.stopPropagation();
        console.log("Deviation ",props);
        props.context.componentParent.onShowDeviate(props);
    };

    return (
        <div>
            <button onClick={handleDeviateClick} title="No Deviation" style={{ background: "transparent", border: "0px", padding: "0px 5px 0px 8px" }}><img src={require("../../assets/icons/deviation.png")} style={{ width: "20px", filter: "contrast(0) grayscale(0) brightness(1.5)" }}></img> &nbsp;</button>
        </div>
    );
};

export default kpiDeviationColurActions;