import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { MenuModule } from "@ag-grid-enterprise/menu";
// FullCalendar imports
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction"
// import Loader from '../charts/dashboard/Loader';
// import timeGridPlugin from "@fullcalendar/timegrid"
import Select from "react-select";
import $ from "jquery";
import { getDDMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY, getHyphenYYYYMMDDHHMMSS, getHyphenDDMMYYYYHHMMSS, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import * as Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import {
    AllModules,
    GridOptionsWrapper,
} from "@ag-grid-enterprise/all-modules";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
var redirectURL = require("../redirectURL");
var moment = require('moment');

export default class DCTattendanceView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            events: [],
            modules: AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                width: 160,
                resizable: true,
            },
            eventAction: null,
            show: false,
            basicTitle: "",
            basicType: "default",
            loadshow: "show-n",
            overly: "",
            rowdata: [],
            totalData: [],
            sliderbulkupload: "",
            rowModelhighchartoptionsType: "enterprise",

            rowGroupPanelShow: "always",
            statusBar: {

            },
            paginationPageSize: 50,
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel",
                    },
                ],
            },
            animateRows: true,
            dept_code: "",
            pagetitle: "",
            csvData: 0,
            overly: "show-n",
            loadshow: "show-n",
            pagetitle: "",
            eventGridAction: "gridAction",
            csvcontent: [],
            file: "",
            batch_name: "",
            cluster_breaks: "2",
            cluster_radius: "0.5",
            notification_emails: "",
            bulkslide: "",
            trailor_no: "",

            rowId: "",
            show: false,
            basicTitle: "",
            basicType: "",
            transitDate: "",
            transpoterGSTDate: "",
            unloadedDate: "",
            reachedDate: "",
            SectororCountry: "",
            location: "",
            stateMetricTon: '',
            stateCBM: '',
            stateSectororCountry: '',
            stateLocation: '',
            employeeNameCollect: [],

            consigner: [],
            startDate: "",
            endDate: "",
            filterData: [],

        };
        // this.getAllTimesheetLogs = this.getAllTimesheetLogs.bind(this);
    }
    calendarRef = React.createRef();

    componentDidMount = async () => {
        const calendarApi = this.calendarRef.current.getApi();
        const view = calendarApi.view;


        this.setState({
            loadshow: "show-m",
            overly: "show-m",
        });
        var employeeNameCollect = []
        await redirectURL
            .post("/consignments/dctattendancelogssummary")
            .then((resp) => {

                //console.log(resp.data, "dctattendancelogs");

                resp.data.map((ele) => {
                    //  console.log(ele.employee_name,'employee_name')
                    employeeNameCollect.push(ele.employee_name)
                })
                this.setState({
                    employeeNameCollect: employeeNameCollect,
                    events: []
                })
            })
            .catch((e) => {
                console.log(e);
            });
    }



    getFormattedDateTime = (dateTime) => {
        const date = new Date(dateTime);
        const formattedDate = date.toLocaleString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
        });

        return formattedDate;
    };

    addNewRow = () => {
        // var rowId = e.data._id;
        this.setState({
            bulkslide: "slider-translate-30p",
            // rowId: rowId,
        });
    }

    closeAlert = () => {
        this.setState({
            show: false,
        });
    };
    onClickHideAll() {
        this.setState({
            loadshow: "show-n",
            overly: "show-n",
            bulkslide: "",
            bulkslide1: '',
        });
    }




    selectConsignerOptionsItems() {
        let items = [];
        const consigners = [];
        const uniqueNames = Array.from(new Set(this.state.employeeNameCollect));

        uniqueNames.forEach(name => {
            consigners.push({ "code": name, "value": name });
        });

        // items.push({ value: 'all', label: 'All' });

        consigners.forEach(item => {
            items.push({ value: item.code, label: item.value });
        });

        return items;
    }

    changeConsignerArrayHandler = consigner => {
        this.setState(
            { consigner },
            () => {
                //console.log(`Consigner Option selected:`, this.state.consigner)

                if (this.state.consigner == null) {

                    this.componentDidMount()
                }
            }
        );

        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }

    handlerStartDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);

        var startdate = d.getFullYear() + "-" + ((d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())


        //console.log(startdate, 'startdate')
        this.setState({
            startDate: startdate
        });
    }
    handlerEndDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);

        var edate = d.getFullYear() + "-" + ((d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
        //console.log(edate, '--edate')
        this.setState({
            endDate: edate
        });
    }

    onSubmitFilter = async () => {

        this.setState({
            loadshow: "show-m",
            overly: "show-m"
        })

        var consigners = []

        if (this.state.consigner == null || this.state.consigner == "") {
            // consigners.push("all")
        }
        else {
            var clist = this.state.consigner;
            clist.map((item) => {
                consigners.push(item.value)
            })
        }
        //console.log("selected consigners ", consigners)

        if (this.state.startDate == "NaN-NaN-NaN") {
            var sdate = ""
        }
        else {
            sdate = this.state.startDate
        }
        if (this.state.endDate == "NaN-NaN-NaN") {
            var edate = ""
        }
        else {
            edate = this.state.endDate
        }

        var requestparams = {
            startdate: sdate,
            enddate: edate,
            Employee_name: consigners,
        }
        //console.log(requestparams, 'requestparams')
        if (requestparams.Employee_name.length > 0) {

            await redirectURL
                .post("/consignments/dctattendancelogview", requestparams)
                .then((resp) => {

                    const reversedData = [...resp.data].reverse()

                    const records = reversedData.map(record => {
                        //console.log(record, 'records--')
                        const loginTime = record.login_time? new Date(record.login_time): new Date(record.current_time);
                        const user_status = record.login_time? false : true
                        //console.log(loginTime, 'loginTime1')
                        loginTime.setHours(loginTime.getHours() - 5);
                        loginTime.setMinutes(loginTime.getMinutes() - 30);
                        //console.log(loginTime, 'loginTime2')
                        // Parse logout_time (if present) and subtract 5 hours and 30 minutes
                        let logoutTime = record.logout_time ? new Date(record.logout_time) : null;
                        if (logoutTime) {
                            //console.log(logoutTime, 'logoutTime1')
                            logoutTime.setHours(logoutTime.getHours() - 5);
                            logoutTime.setMinutes(logoutTime.getMinutes() - 30);
                            //console.log(logoutTime, 'logoutTime2')
                        }
                        return {
                            ...record,
                            title: record.employee_name,
                            start: (loginTime),
                            end: (logoutTime),
                            duration: record.login_duration,
                            extendedProps: {
                                location: record.location || 'N/A',
                                user_status :  user_status
                            },
                            textColor: "#171c8f",
                        }
                    })
                    var events = [...records];
                    this.setState({
                        events: events,
                        showLoader: false,
                        // events: updatedEvents,
                    })
                })
        } else {
            alert("Please select Employee Name")
            this.setState({
                startDate: '',
                endDate: ''
            })
            this.componentDidMount()
        }


    }


    renderEventContent(eventInfo) {
        console.log(eventInfo, "eddd11")
        const { event } = eventInfo;
        const { title, start, end, extendedProps } = event;
        const { location, user_status } = extendedProps
        const formatTime = (time) => (time instanceof Date && !isNaN(time)) ? new Date(time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : "";

        const startTime = new Date(start);
        let endTime;

        let durationInMilliseconds;

        if (end != undefined && end != "" && end != "Invalid Date" && end != null) {
            endTime = new Date(end);
            durationInMilliseconds = endTime - startTime;
        } else {
            endTime = '';
            durationInMilliseconds = 0;
        }
        if (user_status) {
            return (
                <div className="event-wrapper">
                    <div className="event-details">
                        <p>&nbsp;&nbsp;<i className="fa fa-circle"></i>&nbsp;&nbsp;<b>{title}</b></p>
                        <p style={{ marginTop: '-12px' }}>&nbsp;&nbsp;{location}</p>
                    </div>
                </div>
            );
        }
        else {
            const hours = Math.floor(durationInMilliseconds / (60 * 60 * 1000));
            const minutes = Math.floor((durationInMilliseconds % (60 * 60 * 1000)) / (60 * 1000));

            const formattedDuration = `${hours}h ${minutes}m`;

            return (
                <div className="event-wrapper">
                    <div className="event-details">
                        <p>&nbsp;&nbsp;<i className="fa fa-circle"></i>&nbsp;&nbsp;<b>{title}</b></p>
                        <p style={{ marginTop: '-12px' }}>&nbsp;&nbsp;Login: {formatTime(start)}</p>
                        <p style={{ marginTop: '-12px' }}>&nbsp;&nbsp;Logout: {formatTime(endTime)}</p>
                        <p style={{ marginTop: '-12px' }}>&nbsp;&nbsp;Duration: {formattedDuration}</p>
                    </div>
                </div>
            );
        }
    }




    handleDateClick = (info) => {
        this.setState({
            isEditable: true,
            hasChanged: true,
        })

        if (this.state.userRole !== "FLEET_OWNER" && this.state.selfToggleActive === true) {
            this.setState({
                selectedUserId: localStorage.getItem("userid")
            })
        }

        if (this.state.selectedUserId !== "" && this.state.selectedUserId !== undefined && this.state.selectedUserId !== null) {
            // console.log(info, "date clicked")

            const { requestTypes, leaveTypes, dayTypes } = this.state;

            const requestTypeName = requestTypes.filter((item) => {
                return item.label === "Attendance";
            })

            let RequestTypeName = requestTypeName[0];

            this.setState({
                open: true,
                startDate: info.date,
                endDate: info.date,
                selectedRequestType: RequestTypeName,
                selectedLeaveType: "",
                selectedDayType: "",
                clockInTime: null,
                clockOutTime: null,
                logId: "",
                comments: "",
            })
        }
        else {
            if (this.state.teamToggleActive) {
                this.setState({
                    show: true,
                    basicTitle: "Please select a Team member from the dropdown!",
                    basicType: "error",
                });
            }
        }
    }


    handleDatesSet = (arg) => {
        const view = arg.view;
        // console.log('Current View:', view);
        this.setState({
            date: view.currentStart,
            showLoader: true,
        })

        // this.getAllTimesheetLogs();
    };



    render() {
        return (
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                ></SweetAlert>
                <div className="row col-xl-12 col-lg-12" style={{left:'-32px'}}>
                    <div className="form-group col-xl-4 col-lg-4" style={{ zIndex: 3 }}>
                        <label>Employee Name: </label>
                        <Select
                            // placeholder={"All"} 
                            closeMenuOnSelect={true}
                            value={this.state.consigner}
                            isMulti="true"
                            className="border-radius-0"
                            onChange={this.changeConsignerArrayHandler.bind(this)}
                            style={{ borderRadius: "0px" }}
                            options={this.selectConsignerOptionsItems()}
                            required
                        />

                    </div>
                    <div className="form-group col-xl-3 col-lg-3">
                        <label>From</label>
                        <Datetime
                            // value={this.state.startDate} 
                            disableCloseOnClickOutside={false}
                            closeOnSelect={true}
                            inputProps={{
                                placeholder: 'From',
                                name: 'startDate',
                                autoComplete: 'off'
                            }}
                            dateFormat="YYYY-MM-DD"
                            timeFormat={false}
                            name="startDate"
                            onChange={this.handlerStartDateTime.bind(this)}
                            required
                        />
                    </div>
                    <div className="col-xl-3 col-lg-3 form-group">
                        <label>To</label>
                        <Datetime
                            // value={this.state.endDate} 
                            disableCloseOnClickOutside={false}
                            closeOnSelect={true}
                            inputProps={{
                                placeholder: 'To',
                                name: 'endDate',
                                autoComplete: 'off'
                            }}
                            dateFormat="YYYY-MM-DD"
                            timeFormat={false}
                            onChange={this.handlerEndDateTime.bind(this)}
                            required
                        />

                    </div>
                    <div className="form-group col-xl-2 col-lg-2">
                        <label>&nbsp;</label><br />
                        <button type="button" className="btn btn-success" onClick={this.onSubmitFilter.bind(this)}>Submit</button>
                    </div>
                </div>
                <div className="row">
                    {/* <div className="col-xl-12 col-lg-12"> */}
                    <div className="card" style={{ width: "100%" }}>
                        <div className="card-header">
                            <div style={{ display: 'flex' }}>
                                <div>
                                    <h5>
                                        <i className="icofont icofont-vehicle-delivery-van cus-i"></i>  <span>DCT Attendance Logs</span>
                                    </h5>

                                </div>
                                <div style={{ marginLeft: '15px' }}>
                                    <a href="/dctattendancesummary" >

                                        <button type="submit" class="btn btn-success">Summary Screen</button>&nbsp;&nbsp;&nbsp;&nbsp;
                                    </a>

                                </div>
                            </div>
                            {/* <button className="btn btn-success" style={{float:"right"}} onClick={this.addNewRow.bind(this)}>Punch Attendance </button> */}
                        </div>

                        <div>

                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div
                                    id="myGrid"
                                    style={{ width: "100%", height: "478px" }}
                                    className="ag-theme-balham"
                                >
                                    <FullCalendar
                                        ref={this.calendarRef}
                                        plugins={[dayGridPlugin, interactionPlugin]}
                                        initialView="dayGridMonth"
                                        events={this.state.events}
                                        eventContent={this.renderEventContent}
                                        dateClick={this.handleDateClick}
                                        eventClick={this.handleEventClick}
                                        height="100%"
                                        datesSet={this.handleDatesSet}
                                        firstDay={1}
                                        buttonText={{
                                            today: 'Today',
                                        }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </div> */}
                </div>

            </div>

        );
    }
}


function DateEditor() { }

// gets called once before the renderer is used
DateEditor.prototype.init = function (params) {
    // create the cell
    //console.log(params, "params");
    this.eInput = document.createElement("input");
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        timepicker: false,
        changeMonth: true,
        changeYear: true,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

function loadDateTimeScript() {
    // alert("timeDate");
    $('.datetimepicker_mask').datetimepicker({
        mask: '39-19-9999 29:59',
        format: 'd-m-Y H:i'
    });
    $('.datetimepicker_date').datetimepicker({
        mask: '39-19-9999',
        format: 'd-m-Y',
        timepicker: true
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}
function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    //console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}

function formatDate(dateTime) {
    const date = new Date(dateTime);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    const formattedDate = `${day}-${month}-${year}`;
    const formattedTime = `${hours}:${minutes}`;

    return `${formattedDate} ${formattedTime}`;
}