import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { MenuModule } from "@ag-grid-enterprise/menu";
import Select from "react-select";
import $ from "jquery";
import { getDDMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY, getHyphenYYYYMMDDHHMMSS, getHyphenDDMMYYYYHHMMSS, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import * as Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import {
    AllModules,
    GridOptionsWrapper,
} from "@ag-grid-enterprise/all-modules";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { reverse } from "lodash";
var redirectURL = require("../redirectURL");
var moment = require('moment');

export default class DCTattendanceSummary extends Component {
    constructor(props) {
        super(props);
        var date2 = new Date();
        var currentDateTime = moment(date2).format("YYYY-MM-DD");
        var startDate1 = moment(date2).subtract(90, 'days').format("YYYY-MM-DD")
        this.state = {
            modules: AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                width: 160,
                resizable: true,
            },
            eventAction: null,
            show: false,
            basicTitle: "",
            basicType: "default",
            loadshow: "show-n",
            overly: "",
            rowdata: [],
            totalData: [],
            sliderbulkupload: "",
            rowModelhighchartoptionsType: "enterprise",

            rowGroupPanelShow: "always",
            statusBar: {

            },
            paginationPageSize: 50,
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel",
                    },
                ],
            },
            animateRows: true,
            dept_code: "",
            pagetitle: "",
            csvData: 0,
            overly: "show-n",
            loadshow: "show-n",
            pagetitle: "",
            eventGridAction: "gridAction",
            csvcontent: [],
            file: "",
            batch_name: "",
            cluster_breaks: "2",
            cluster_radius: "0.5",
            notification_emails: "",
            bulkslide: "",
            trailor_no: "",

            rowId: "",
            show: false,
            basicTitle: "",
            basicType: "",
            transitDate: "",
            transpoterGSTDate: "",
            unloadedDate: "",
            reachedDate: "",
            SectororCountry: "",
            location: "",
            stateMetricTon: '',
            stateCBM: '',
            stateSectororCountry: '',
            stateLocation: '',
            employeeNameCollect: [],

            consigner: [{ value: "ALL", label: "ALL" }],
            startDate: startDate1,
            endDate: currentDateTime,
            filterData: [],

        };
        // this.downloadbatchfile = this.downloadbatchfile.bind(this);
    }

    componentDidMount = async () => {
        // loadDateTimeScript()
        // document.body.addEventListener("click", this.handleClickOutside);
        this.setState({
            loadshow: "show-m",
            overly: "show-m",
        });

        var employeeNameCollect = []
        var params = {
            start_date: this.state.startDate,
            end_date: this.state.endDate,
        }
        await redirectURL
            .post("/consignments/dctattendancelogssummary", params)
            .then((resp) => {

                //console.log(resp.data, "dctattendancelogs");
                // employeeNameCollect.push({value:"ALL",label:"ALL"})
                resp.data.map((ele) => {
                    //console.log(ele.employee_name, 'employee_name')
                    employeeNameCollect.push(ele.employee_name)
                })
                // employeeNameCollect.shift({value:"ALL",label:"ALL"})
                //console.log(employeeNameCollect, 'employeeNameCollect')
                const reversedData = [...resp.data].reverse()
                // setTimeout(() => {
                const sortedLogs = reversedData.sort((a, b) => {
                    const timeA = new Date(a.current_time).getTime();
                    const timeB = new Date(b.current_time).getTime();
                    return timeB - timeA; // Sort in descending order
                });

                //console.log(sortedLogs, "sorted logs");

                this.setState({
                    employeeNameCollect: employeeNameCollect,
                    rowdata: sortedLogs,
                    totalData: resp.data,
                    loadshow: "show-n",
                    overly: "show-n",
                });
                // },1000);
            })
            .catch((e) => {
                console.log(e);
            });
    }

    addNewRow = () => {
        // var rowId = e.data._id;
        this.setState({
            bulkslide: "slider-translate-30p",
            // rowId: rowId,
        });
    }

    closeAlert = () => {
        this.setState({
            show: false,
        });
    };
    onClickHideAll() {
        this.setState({
            loadshow: "show-n",
            overly: "show-n",
            bulkslide: "",
            bulkslide1: '',
        });
    }




    selectConsignerOptionsItems() {
        let items = [];
        const consigners = [];
        const uniqueNames = Array.from(new Set(this.state.employeeNameCollect));

        uniqueNames.forEach(name => {
            consigners.push({ "code": name, "value": name });
        });

        items.unshift({ value: 'ALL', label: 'All' });

        consigners.forEach(item => {
            items.push({ value: item.code, label: item.value });
        });

        return items;
    }

    changeConsignerArrayHandler = consigner => {
        this.setState(
            { consigner },
            () => {
                //console.log(`Consigner Option selected:`, this.state.consigner)
                if (this.state.consigner == null) {
                    this.componentDidMount()
                }
            }
        );

        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }

    handlerStartDateTime = (date) => {
        const selectedDate = moment(date).format('YYYY-MM-DD');

        if (this.state.endDate && moment(selectedDate).isAfter(this.state.endDate)) {
            this.setState({
                show: true,
                basicTitle: 'Start date should be less than End date',
                basicType: "danger",
                loadshow: 'show-n',
                startDate: "",
            });
        } else {
            this.setState({
                startDate: selectedDate
            });
        }
    }
    handlerEndDateTime = (date) => {
        const selectedDate = moment(date).format('YYYY-MM-DD');

        if (this.state.startDate && moment(selectedDate).isBefore(this.state.startDate)) {
            this.setState({
                show: true,
                basicTitle: 'End date should be greater than Start date',
                basicType: "danger",
                loadshow: 'show-n',
                endDate: "",
            });
        } else {
            this.setState({
                endDate: selectedDate
            });
        }
    }

    onSubmitFilter = async () => {

        this.setState({
            loadshow: "show-m",
            overly: "show-m"
        })

        var consigners = []

        if (this.state.consigner == null || this.state.consigner == "") {
            // consigners.push("all")
        }
        else {
            var clist = this.state.consigner;
            clist.map((item) => {
                consigners.push(item.value)
            })
        }
        //console.log("selected consigners ", consigners)

        if (this.state.startDate == "NaN-NaN-NaN") {
            var sdate = ""
        }
        else {
            sdate = this.state.startDate
        }
        if (this.state.endDate == "NaN-NaN-NaN") {
            var edate = ""
        }
        else {
            edate = this.state.endDate
        }

        var requestparams = {
            startdate: sdate,
            enddate: edate,
            Employee_name: consigners,
        }

        //console.log(requestparams, 'requestparams')
        if (consigners.length != 0) {
            await redirectURL.post("/consignments/fetchdctattendanceData", requestparams).then((response) => {
                if (response.data.length > 0) {
                    //console.log(response.data, 'response--fetched')
                    var employeeNameCollect = []
                    response.data.map((ele) => {
                        employeeNameCollect.push(ele.employee_name)
                    })

                    this.setState({
                        loadshow: "show-n",
                        overly: "show-n",
                        rowdata: reverse(response.data),
                        employeeNameCollect: employeeNameCollect,
                        // filterData:response.data,
                    })
                    // this.OnlineStatusFilterShow()
                } else {
                    //console.log(response.data, 'response--not fetched')
                    this.setState({
                        rowdata: [],
                        loadshow: "show-n",
                        overly: "show-n",
                    })
                }
            })
        } else {
            alert("Please select Employee Name")
            this.setState({
                startDate: '',
                endDate: '',
            })
            this.componentDidMount()
        }


    }
    render() {
        const batchwisecolumns = [
            {
                headerName: "Employee Name",
                field: "employee_name",
            },
            {
                headerName: "Work Location",
                field: "location",
            },
            // {
            //   headerName: "Planned Leave Date",

            //   field: "planned_leave_date",
            //   valueGetter: (param) => {
            //       if (param.data.planned_leave_date !== "") {
            //         return getHyphenDDMMMYYYY(param.data.planned_leave_date);
            //       } else {
            //         return "";
            //       }
            //     },
            // },
            {
                headerName: "Login time",

                field: "login_time",
                valueGetter: (param) => {
                    if (param.data.login_time !== "") {
                        return getHyphenDDMMMYYYYHHMM(param.data.login_time);
                    } else {
                        return "";
                    }
                },
            },
            {
                headerName: "Logout time",

                field: "logout_time",
                valueGetter: (param) => {
                    if (param.data.logout_time !== "") {
                        return getHyphenDDMMMYYYYHHMM(param.data.logout_time);
                    } else {
                        return "";
                    }
                },
            },
            {
                headerName: "Login Duration",

                field: "login_duration",
                // valueGetter: (param) => {
                //     if (param.data.login_duration !== "") {
                //       return getHyphenDDMMMYYYYHHMM(param.data.login_duration);
                //     } else {
                //       return "";
                //     }
                //   },
            },
            {
                headerName: "Date",

                field: "current_time",
                valueGetter: (param) => {

                    if (param.data.login_time !== "") {
                        return getHyphenDDMMMYYYY(param.data.current_time);

                    } else {
                        return ''
                    }
                },
            },
            {
                headerName: "PresentStatus(Online/Offline)",
                field: "loginStatus",
                width: 140,
                filter: true,
                resizable: true,
                editable: false,
                cellRenderer: function (params) {
                    if (params.data.login_time != undefined && params.data.login_time != null && params.data.login_time != "") {
                        var currentDay = moment.parseZone(new Date()).format("YYYY-MM-DD 00:00:00")
                        var currentMoment = new Date(currentDay)
                        var loginTime = moment.parseZone(new Date(params.data.login_time)).format("YYYY-MM-DD 00:00:00")
                        var loginMoment = new Date(loginTime)
                        if (params.data.logout_time == undefined || params.data.logout_time == null || params.data.logout_time == "") {
                            var diff = currentMoment - loginMoment
                            if (diff == 0) {
                                return '<i class="fa fa-circle" style="color:green"></i>';
                            } else {
                                return '<i class="fa fa-circle" style="color:yellow"></i>';
                            }
                        } else {
                            return '<i class="fa fa-circle" style="color:yellow"></i>';
                        }

                    } else {
                        return '<i class="fa fa-circle" style="color:yellow"></i>';
                    }

                },
            },
            {
                headerName: "Status",
                field: "is_attendance_approved",
                valueGetter: (param) => {

                    if (param.data && param.data.is_attendance_approved) {
                        if (param.data.is_attendance_approved == 1) {
                            return "Approved"
                        }
                        else {
                            return "Pending For Approval"
                        }

                    } else {
                        return 'Pending For Approval'
                    }
                },
            },
        ];
        return (
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                ></SweetAlert>
                <div className="row col-xl-12 col-lg-12" style={{left:'-32px'}}>
                    <div className="form-group col-xl-4 col-lg-4">
                        <label>Employee Name </label>
                        <Select
                            // placeholder={"All"} 
                            closeMenuOnSelect={true}
                            value={this.state.consigner}
                            isMulti="true"
                            className="border-radius-0"
                            onChange={this.changeConsignerArrayHandler.bind(this)}
                            style={{ borderRadius: "0px" }}
                            options={this.selectConsignerOptionsItems()}
                            required
                        />
                    </div>
                    <div className="form-group col-xl-3 col-lg-3">
                        <label>From</label>
                        <Datetime
                            disableCloseOnClickOutside={false}
                            closeOnSelect={true}
                            inputProps={{
                                placeholder: 'From',
                                name: 'startDate',
                                autoComplete: 'off',
                                value: this.state.startDate
                            }}
                            dateFormat="YYYY-MM-DD"
                            timeFormat={false}
                            name="startDate"
                            onChange={this.handlerStartDateTime.bind(this)}
                            required
                        />
                    </div>
                    <div className="col-xl-3 col-lg-3 form-group">
                        <label>To</label>
                        <Datetime
                            disableCloseOnClickOutside={false}
                            closeOnSelect={true}
                            inputProps={{
                                placeholder: 'To',
                                name: 'endDate',
                                autoComplete: 'off',
                                value: this.state.endDate
                            }}
                            dateFormat="YYYY-MM-DD"
                            timeFormat={false}
                            onChange={this.handlerEndDateTime.bind(this)}
                            required
                        />
                    </div>
                    <div className="form-group col-xl-2 col-lg-2">
                        <label>&nbsp;</label><br />
                        <button type="button" className="btn btn-success"
                            onClick={this.onSubmitFilter.bind(this)}
                        >Submit</button>
                    </div>
                </div>
                <div className="row">
                    {/* <div className="col-xl-12 col-lg-12"> */}
                    <div className="card" style={{ width: "100%" }}>
                        <div className="card-header">
                            <div style={{ display: 'flex' }}>
                                <div>
                                    <h5>
                                        <i className="icofont icofont-vehicle-delivery-van cus-i"></i>  <span>DCT Attendance Logs</span>
                                    </h5>

                                </div>
                                <div style={{ marginLeft: '15px' }}>
                                    <a href="/dctattendanceview" >

                                        <button type="submit" class="btn btn-success">Calendar View</button>&nbsp;&nbsp;&nbsp;&nbsp;
                                    </a>
                                </div>
                            </div>
                            {/* <button className="btn btn-success" style={{float:"right"}} onClick={this.addNewRow.bind(this)}>Punch Attendance </button> */}
                        </div>
                        <div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div
                                    id="myGrid"
                                    style={{ width: "100%", height: "478px" }}
                                    className="ag-theme-balham"
                                >
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={batchwisecolumns}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowdata}
                                        enableCharts={false}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        frameworkComponents={this.state.frameworkComponents}
                                        rowClassRules={this.state.rowClassRules}
                                        sideBar={this.state.sideBar}
                                        stopEditingWhenGridLosesFocus={true}
                                        //   onCellClicked={this.onCellClicked.bind(this)}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        gridOptions={{
                                            context: { componentParent: this },
                                        }}
                                        // onCellClicked={this.onCellRowClicked.bind(this)}
                                        enableRangeSelection={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </div> */}
                </div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
        );
    }
}
function DateEditor() { }
// gets called once before the renderer is used
DateEditor.prototype.init = function (params) {
    // create the cell
    //console.log(params, "params");
    this.eInput = document.createElement("input");
    this.eInput.value = params.value;
    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        timepicker: false,
        changeMonth: true,
        changeYear: true,
    });
};
// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function () {
    return this.eInput;
};
// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
};
// returns the new value after editing
DateEditor.prototype.getValue = function () {
    return this.eInput.value;
};
// any cleanup we need to be done here
DateEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};
// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};
function loadDateTimeScript() {
    // alert("timeDate");
    $('.datetimepicker_mask').datetimepicker({
        mask: '39-19-9999 29:59',
        format: 'd-m-Y H:i'
    });
    $('.datetimepicker_date').datetimepicker({
        mask: '39-19-9999',
        format: 'd-m-Y',
        timepicker: true
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}
function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    //console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}