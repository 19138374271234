import React, { Component } from "react";
import DashboardGrid from "./DashboardGrid";
// import LineGraphComponent from "../AmChartComponents/LineGraphComponent";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import Select from "react-select";
// import DrilldownPieGraph from "../AmChartComponents/DrilldownPieGraph";
// import * as am5 from "@amcharts/amcharts5";
// import StackedBarComponent from "../AmChartComponents/StackedBarComponent";
import PiechartDrilldownComponent from "../layouts/PiechartDrilldownComponent";
import redirectURL from "../redirectURL";
import ColumnChartLoadingDashboard from "../layouts/columnchartloadingdashboard";

export default class DedicatedTruckTripDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // 1
      loadingTripGraph: "show-m",
      loadingTripGrid: "show-n",
      loadingTripButtonText: "Show Data",
      loadingTripHeading: "Avg. Loading Time taken per Trip",
      loadingTripSubHeading: "Avg. time taken for loading truck at Port",

      // 2

      unloadingTripGraph: "show-m",
      unloadingTripGrid: "show-n",
      unloadingTripButtonText: "Show Data",
      unloadingTripHeading: "Avg. Unloading Time taken per Trip",
      unloadingTripSubHeading: "Avg. time taken for unloading truck in Plant",

      // 3

      trucksPerTripsGraph: "show-m",
      trucksPerTripsGrid: "show-n",
      trucksPerTripsButtonText: "Show Data",
      trucksPerTripsHeading: "No. of Trucks per Trip",
      trucksPerTripsSubHeading:
        "Total no. of trips made by trucks in corresponding time",

      // 4

      tripsPerTrucksGraph: "show-m",
      tripsPerTrucksGrid: "show-n",
      tripsPerTrucksButtonText: "Show Data",
      tripsPerTrucksHeading: "No. of Trips per Truck",
      tripsPerTrucksSubHeading:
        "Total no. of trips made by trucks in corresponding time",

      // 6
      emptyTruckAnalysisGraph: "show-m",
      emptyTruckAnalysisGrid: "show-n",
      emptyTruckAnalysisButtonText: "Show Data",
      emptyTruckAnalysisHeading: "Empty Truck Analysis",
      emptyTruckAnalysisSubHeading: "Avg. time taken for truck from Plant-Port",

      fromDate: moment
        .parseZone()
        .subtract(7, "days")
        .format("YYYY-MM-DD"),
      toDate: moment.parseZone().format("YYYY-MM-DD"),
      show: false,
      basicType: "default",
      basicTitle: "",
      loadshow: "show-n",
      overly: "show-n",
      portNames: [],
      plantNames: [],
      vehicleNumbers: [],

      transporternames: [],
      portname: "",
      plantname: "",
      transportername: [],
      vehiclenumber: [],

      loadingParentData: [],
      loadingChildData: [],
      unloadingParentData: [],
      unloadingChildData: [],

      trucksPerTripSeriesData: [],
      trucksPerTripSeriesCategories: [],
      tripsPerTrucksSeriesData: [],
      tripsPerTrucksSeriesCategories: [],
      emptyTruckSeriesData: [],
      emptyTruckDataSeriesCategories: [],
      loadingData: [],
      unloadingData: [],
      completeTripData: [],

      columnDefs: [
        {
          headerName: "Truck No",
          field: "truck_no",
          width: 100,
        },
        {
          headerName: "Transporter Name",
          field: "transporter_name",
          width: 150,
          valueGetter: function(params) {
            if (params.data.transporter_name) {
              const words = params.data.transporter_name.split("_");
              const capitalizedWords = words.map(
                (word) => word.charAt(0).toUpperCase() + word.slice(1)
              );
              const result = capitalizedWords.join(" ");
              return result;
            } else {
              return "";
            }
          },
        },
        {
          headerName: "Consignment Number",
          field: "consignment_code",
          width: 200,
        },
      ],
    };
  }

  transformString(input) {
    const words = input.split("_");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    const result = capitalizedWords.join(" ");

    return result;
  }

  getAvgLoadUnloadTimePerTripData = async (payload, graphType) => {
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    });
    payload["dataFor"] = graphType;
    console.log("graphType", graphType);
    await redirectURL
      .post("/dashboard/getAvgLoadUnloadTimePerTripData", payload)
      .then((resp) => {
        console.log("getAvgLoadUnloadTimePerTripData data", resp.data.data);
        if (graphType === "loading") {
          if (resp.data.data.length > 0) {
            this.setState({
              loadingParentData: resp.data.data[0].parent,
              loadingChildData: resp.data.data[0].childData,
              loadshow: "show-n",
              overly: "show-n",
            });
          } else {
            this.setState({
              loadingParentData: [],
              loadingChildData: [],
              loadshow: "show-n",
              overly: "show-n",
            });
          }
        } else if (graphType === "unloading") {
          // console.log("unloading resp.data.data[0]", resp.data.data[0]);
          if (resp.data.data.length > 0) {
            this.setState({
              unloadingParentData: resp.data.data[0].parent,
              unloadingChildData: resp.data.data[0].childData,
              loadshow: "show-n",
              overly: "show-n",
            });
          } else {
            this.setState({
              unloadingParentData: [],
              unloadingChildData: [],
              loadshow: "show-n",
              overly: "show-n",
            });
          }
        }
      })
      .catch((err) => {
        console.log("getAvgLoadUnloadTimePerTripData err", err);
      });
  };

  getTrucksTripsCountData = (payload) => {
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    });
    redirectURL
      .post("/dashboard/getTrucksTripsCountData", payload)
      .then((resp) => {
        console.log("getTrucksTripsCountData", resp.data);
        // let
        let trucksPerTripSeriesData = {
          name: "No. of Trips",
          colorByPoint: true,
          data: resp.data.trucksPerTripsData,
        };

        let trucksPerTripSeriesCategories = [];

        resp.data.trucksPerTripsData.forEach((each) => {
          trucksPerTripSeriesCategories.push(each.name);
        });

        let tripsPerTrucksSeriesData = {
          name: "Truck Number",
          colorByPoint: true,
          data: resp.data.tripsPerTrucksData,
        };

        let tripsPerTrucksSeriesCategories = [];

        resp.data.tripsPerTrucksData.forEach((each) => {
          tripsPerTrucksSeriesCategories.push(each.name);
        });

        this.setState({
          trucksPerTripSeriesData,
          trucksPerTripSeriesCategories,
          tripsPerTrucksSeriesData,
          tripsPerTrucksSeriesCategories,
          loadshow: "show-n",
          overly: "show-n",
        });
      })
      .catch((err) => {
        console.log("getTrucksTripsCountData err", err);
      });
  };

  emptyTruckAnalysisData = (payload) => {
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    });
    redirectURL
      .post("/dashboard/emptyTruckAnalysisData", payload)
      .then((resp) => {
        console.log("emptyTruckAnalysisData resp", resp.data);

        let emptyTruckSeriesData = {
          name: "Truck No.",
          colorByPoint: true,
          data: resp.data.emptyTruckData,
        };

        let emptyTruckDataSeriesCategories = [];
        resp.data.emptyTruckData.forEach((each) => {
          emptyTruckDataSeriesCategories.push(each.name);
        });

        this.setState({
          emptyTruckSeriesData: emptyTruckSeriesData,
          emptyTruckDataSeriesCategories: emptyTruckDataSeriesCategories,
          loadshow: "show-n",
          overly: "show-n",
        });
      })
      .catch((err) => {
        console.log("emptyTruckAnalysisData err", err);
      });
  };

  getDedicatedTruckDashboardGridData = (payload) => {
    redirectURL
      .post("/dashboard/getDedicatedTruckDashboardGridData", payload)
      .then((resp) => {
        console.log("getDedicatedTruckDashboardGridData", resp.data);

        this.setState({
          unloadingData: resp.data.unloadingData,
          loadingData: resp.data.loadingData,
          completeTripData: resp.data.completeTripData,
        });
      });
  };

  componentDidMount = async () => {
    console.log("this.state.fromDate", this.state.fromDate);
    console.log("this.state.fromDate", this.state.toDate);
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    });
    await redirectURL
      .get("/dashboard/getalltransportertrucks")
      .then((resp) => {
        console.log("getalltransportertrucks resp", resp.data);
        let { allTransporters, allVehicles, allDrivers } = resp.data;
        let transporterOptions = [];
        allTransporters.forEach((each) => {
          let changedName = this.transformString(each);
          let option = { label: changedName, value: each };
          transporterOptions.push(option);
        });

        this.setState(
          {
            transporternames: transporterOptions,
            allVehicles: allVehicles,
            allDrivers: allDrivers,
          },
          async () => {
            let payload = {
              fromDate: this.state.fromDate,
              toDate: this.state.toDate,
              transporters: [],
              vehicles: [],
            };

            console.log("payload", payload);
            await this.getAvgLoadUnloadTimePerTripData(payload, "loading");
            await this.getAvgLoadUnloadTimePerTripData(payload, "unloading");

            this.getTrucksTripsCountData(payload);
            this.emptyTruckAnalysisData(payload);
            this.getDedicatedTruckDashboardGridData(payload);
          }
        );
      })
      .catch((err) => {
        console.log("getalltransportertrucks err", err);
      });
  };

  newFilter = (filterType, selOption) => {
    console.log("filterType", filterType);
    console.log("selOption", selOption);
    this.setState({ [filterType]: selOption }, () => {
      if (selOption && filterType === "transportername") {
        let allVehicles = [];
        selOption.forEach((each) => {
          let vehicles = this.state.allVehicles[each.value];
          vehicles.forEach((veh) => {
            allVehicles.push({ label: veh, value: veh });
          });
        });
        this.setState({
          vehicleNumbers: allVehicles,
          vehiclenumber: [],
        });
      }
      if (filterType === "transportername" && selOption === null) {
        this.setState({
          vehiclenumber: [],
          transportername: [],
        });
      }

      if (filterType === "vehiclenumber" && selOption === null) {
        this.setState({
          vehiclenumber: [],
        });
      }
    });
  };

  closeAlert = () => {
    this.setState({
      show: false,
    });
  };

  onClickHideAll = () => {
    this.setState({
      loadshow: "show-n",
      overly: "show-n",
    });
  };

  getFilterData = async (e) => {
    this.setState({
      overly: "show-m",
      loadshow: "show-m",
    });
    e.preventDefault();
    if (this.state.fromDate && this.state.toDate) {
      let transporterNames = [];
      this.state.transportername.forEach((each) => {
        transporterNames.push(each.value);
      });

      let vehicleNumbers = [];
      this.state.vehiclenumber.forEach((each) => {
        vehicleNumbers.push(each.value);
      });

      let payload = {
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
        transporters: transporterNames,
        vehicles: vehicleNumbers,
      };

      console.log("payload", payload);

      await this.getAvgLoadUnloadTimePerTripData(payload, "loading");
      await this.getAvgLoadUnloadTimePerTripData(payload, "unloading");
      this.getTrucksTripsCountData(payload);
      this.emptyTruckAnalysisData(payload);
      this.getDedicatedTruckDashboardGridData(payload);
      this.setState({
        overly: "show-n",
        loadshow: "show-n",
      });
    } else {
      this.setState({
        show: true,
        basicType: "danger",
        basicTitle: "Dates shouldn't be empty",
      });
    }
  };

  handleFromDate = (event) => {
    console.log("loadingParentData", event._d);
    let changedDate = moment(event._d).format("YYYY-MM-DD");
    if (this.state.toDate) {
      if (moment(changedDate).isAfter(moment(this.state.toDate))) {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "From Date shouldn't be lesser than To Date",
          fromDate: moment(this.state.toDate)
            .subtract(1, "days")
            .format("YYYY-MM-DD"),
        });
      } else {
        this.setState({
          fromDate: moment(changedDate),
        });
      }
    } else {
      this.setState({
        fromDate: moment(changedDate),
      });
    }
  };

  handlerStartDateTime = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);
    var mm = d.getMonth() + 1;
    if (mm < 10) {
      var month = "0" + mm;
    } else {
      var month = mm;
    }

    var startdate =
      d.getFullYear() +
      "-" +
      month +
      "-" +
      (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

    this.setState({
      fromDate: startdate,
    });
    //let value = event.target.value;
    //this.setState({'startDate':value});
  };

  handlerEndDateTime = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);
    var mm = d.getMonth() + 1;
    if (mm < 10) {
      var month = "0" + mm;
    } else {
      var month = mm;
    }

    var edate =
      d.getFullYear() +
      "-" +
      month +
      "-" +
      (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

    this.setState({
      toDate: edate,
    });
  };

  handleToDate = (event) => {
    let changedDate = moment(event._d).format("YYYY-MM-DD");
    if (this.state.fromDate) {
      if (moment(changedDate).isBefore(moment(this.state.fromDate))) {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "To Date shouldn't be lesser than From Date",
          toDate: moment(this.state.fromDate)
            .add(1, "days")
            .format("YYYY-MM-DD"),
        });
      } else {
        this.setState({
          toDate: moment(changedDate),
        });
      }
    } else {
      this.setState({
        toDate: moment(changedDate),
      });
    }
  };

  loadingTripBoxVisibility = () => {
    if (this.state.loadingTripButtonText === "Show Data") {
      this.setState({
        loadingTripGraph: "show-n",
        loadingTripGrid: "show-m",
        loadingTripButtonText: "Show Chart",
      });
    } else {
      this.setState({
        loadingTripGraph: "show-m",
        loadingTripGrid: "show-n",
        loadingTripButtonText: "Show Data",
      });
    }
  };

  unloadingTripBoxVisibility = () => {
    if (this.state.unloadingTripButtonText === "Show Data") {
      this.setState({
        unloadingTripGraph: "show-n",
        unloadingTripGrid: "show-m",
        unloadingTripButtonText: "Show Chart",
      });
    } else {
      this.setState({
        unloadingTripGraph: "show-m",
        unloadingTripGrid: "show-n",
        unloadingTripButtonText: "Show Data",
      });
    }
  };

  trucksPerTripsBoxVisibility = () => {
    if (this.state.trucksPerTripsButtonText === "Show Data") {
      this.setState({
        trucksPerTripsGraph: "show-n",
        trucksPerTripsGrid: "show-m",
        trucksPerTripsButtonText: "Show Chart",
      });
    } else {
      this.setState({
        trucksPerTripsGraph: "show-m",
        trucksPerTripsGrid: "show-n",
        trucksPerTripsButtonText: "Show Data",
      });
    }
  };

  tripsPerTrucksBoxVisibility = () => {
    if (this.state.tripsPerTrucksButtonText === "Show Data") {
      this.setState({
        tripsPerTrucksGraph: "show-n",
        tripsPerTrucksGrid: "show-m",
        tripsPerTrucksButtonText: "Show Chart",
      });
    } else {
      this.setState({
        tripsPerTrucksGraph: "show-m",
        tripsPerTrucksGrid: "show-n",
        tripsPerTrucksButtonText: "Show Data",
      });
    }
  };

  totalTripsBoxVisibility = () => {
    if (this.state.totalTripsButtonText === "Show Data") {
      this.setState({
        totalTripsGraph: "show-n",
        totalTripsGrid: "show-m",
        totalTripsButtonText: "Show Chart",
      });
    } else {
      this.setState({
        totalTripsGraph: "show-m",
        totalTripsGrid: "show-n",
        totalTripsButtonText: "Show Data",
      });
    }
  };

  emptyTruckAnalysisBoxVisibility = () => {
    if (this.state.emptyTruckAnalysisButtonText === "Show Data") {
      this.setState({
        emptyTruckAnalysisGraph: "show-n",
        emptyTruckAnalysisGrid: "show-m",
        emptyTruckAnalysisButtonText: "Show Chart",
      });
    } else {
      this.setState({
        emptyTruckAnalysisGraph: "show-m",
        emptyTruckAnalysisGrid: "show-n",
        emptyTruckAnalysisButtonText: "Show Data",
      });
    }
  };

  render() {
    let loadingColumnDefs = [
      ...this.state.columnDefs,
      {
        headerName: "Port Name",
        field: "consigner_name",
        width: 100,
      },
      {
        headerName: "State",
        field: "consigner_state",
        width: 100,
      },
      {
        headerName: "Port In",
        field: "inside_fence",
        width: 100,
        valueGetter: function(params) {
          if (params.data.inside_fence) {
            return moment(params.data.inside_fence).format("YYYY-MM-DD HH:mm");
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Port Out",
        field: "gate_out_time",
        width: 100,
        valueGetter: function(params) {
          if (params.data.gate_out_time) {
            return moment(params.data.gate_out_time).format("YYYY-MM-DD HH:mm");
          } else {
            return "";
          }
        },
      },
    ];

    let unloadingColumnDefs = [
      ...this.state.columnDefs,
      {
        headerName: "Plant Name",
        field: "consignee_name",
        width: 100,
      },
      {
        headerName: "State",
        field: "consignee_state",
        width: 100,
      },
      {
        headerName: "Plant In",
        field: "inside_fence",
        width: 100,
        valueGetter: function(params) {
          if (params.data.inside_fence) {
            return moment(params.data.inside_fence).format("YYYY-MM-DD HH:mm");
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Plant Out",
        field: "gate_out_time",
        width: 100,
        valueGetter: function(params) {
          if (params.data.gate_out_time) {
            return moment(params.data.gate_out_time).format("YYYY-MM-DD HH:mm");
          } else {
            return "";
          }
        },
      },
    ];

    let tripColumnDefs = [
      ...this.state.columnDefs,
      {
        headerName: "Port Name",
        field: "consigner_name",
        width: 100,
      },
      {
        headerName: "Port State",
        field: "consigner_state",
        width: 100,
      },
      {
        headerName: "Port In",
        field: "port_in_timestamp",
        width: 100,
        valueGetter: function(params) {
          if (params.data.port_in_timestamp) {
            return moment(params.data.port_in_timestamp).format(
              "YYYY-MM-DD HH:mm"
            );
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Port Out",
        field: "port_out_timestamp",
        width: 100,
        valueGetter: function(params) {
          if (params.data.port_out_timestamp) {
            return moment(params.data.port_out_timestamp).format(
              "YYYY-MM-DD HH:mm"
            );
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Plant Name",
        field: "consignee_name",
        width: 100,
      },
      {
        headerName: "State",
        field: "consignee_state",
        width: 100,
      },
      {
        headerName: "Plant In",
        field: "plant_in_timestamp",
        width: 100,
        valueGetter: function(params) {
          if (params.data.plant_in_timestamp) {
            return moment(params.data.plant_in_timestamp).format(
              "YYYY-MM-DD HH:mm"
            );
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Plant Out",
        field: "plant_out_timestamp",
        width: 100,
        valueGetter: function(params) {
          if (params.data.plant_out_timestamp) {
            return moment(params.data.plant_out_timestamp).format(
              "YYYY-MM-DD HH:mm"
            );
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Trip Closed Time",
        field: "trip_closed_timestamp",
        width: 130,
        valueGetter: function(params) {
          if (params.data.trip_closed_timestamp) {
            return moment(params.data.trip_closed_timestamp).format(
              "YYYY-MM-DD HH:mm"
            );
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Loading Time (Hrs)",
        field: "loading_time",
        width: 100,
        valueGetter: function(params) {
          if (params.data.loading_time) {
            return (Number(params.data.loading_time) / 3600).toFixed(2);
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Unloading Time (Hrs)",
        field: "unloading_time",
        width: 100,
        valueGetter: function(params) {
          if (params.data.unloading_time) {
            return (Number(params.data.unloading_time) / 3600).toFixed(2);
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Driver Name",
        field: "driver_name",
        width: 100,
      },
      {
        headerName: "Driver Mobile No.",
        field: "driver_mobile_no",
        width: 100,
      },
    ];
    return (
      <div className="container-fluid">
        <div className={"dataLoadpage " + this.state.loadshow}></div>
        <div className={"dataLoadpageimg " + this.state.loadshow}>
          <div className="loader-box">
            <div className="loader-box">
              <div className="rotate dashed colored"></div>
            </div>
          </div>
        </div>
        <div
          className={"overlay-part " + this.state.overly}
          onClick={this.onClickHideAll}
        ></div>
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        />

        <div
          className="col-xl-12 col-lg-12 mt-2 p-2 mb-4"
          style={{ minHeight: "100vh" }}
        >
          {/* Filter card */}
          <div className="card shadow pt-3 mt-2" style={{ minHeight: "100px" }}>
            <h5 style={{ marginLeft: "3%" }}>
              <i className="icofont icofont-vehicle-delivery-van"></i>
              <span className="pl-2">Tripwise Dashboard</span>
            </h5>
            <div className="col-lg-12 col-xl-12 mx-0 row pl-26px">
              <div className="col-xl-3 col-lg-3 form-group">
                <label>From Date</label>
                <Datetime
                  value={this.state.fromDate}
                  disableCloseOnClickOutside={false}
                  closeOnSelect={true}
                  inputProps={{
                    placeholder: "Start Date",
                    name: "startDate",
                    autoComplete: "off",
                  }}
                  dateFormat="YYYY-MM-DD"
                  name="fromDate"
                  onChange={this.handlerStartDateTime.bind(this)}
                />
              </div>

              <div className="col-xl-3 col-lg-3 form-group">
                <label>To Date</label>
                {/* <Datetime
                  timeFormat={false}
                  value={this.state.toDate}
                  disableCloseOnClickOutside={false}
                  closeOnSelect={true}
                  inputProps={{
                    placeholder: "To",
                    name: "toDate",
                    autoComplete: "off",
                  }}
                  dateFormat="YYYY-MM-DD"
                  name="toDate"
                  onChange={this.handleToDate}
                  required
                /> */}

                <Datetime
                  value={this.state.toDate}
                  disableCloseOnClickOutside={false}
                  closeOnSelect={true}
                  inputProps={{
                    placeholder: "To Date",
                    name: "toDate",
                    autoComplete: "off",
                  }}
                  dateFormat="YYYY-MM-DD"
                  name="endDate"
                  onChange={this.handlerEndDateTime.bind(this)}
                />
              </div>

              <div
                className="col-xl-2 col-lg-2 form-group"
                style={{ zIndex: "12" }}
              >
                <label>Transporter</label>
                <Select
                  closeMenuOnSelect={true}
                  name="vehiclenumber"
                  value={this.state.transportername}
                  className="border-radius-0"
                  onChange={this.newFilter.bind(this, "transportername")}
                  isMulti={true}
                  style={{ borderRadius: "0px", zIndex: "9999" }}
                  options={this.state.transporternames}
                  placeholder={"Select"}
                  required
                />
              </div>

              <div
                className="col-xl-2 col-lg-2 form-group"
                style={{ zIndex: "12" }}
              >
                <label>Vehicle</label>
                <Select
                  closeMenuOnSelect={true}
                  name="vehiclenumber"
                  value={this.state.vehiclenumber}
                  className="border-radius-0"
                  onChange={this.newFilter.bind(this, "vehiclenumber")}
                  isMulti={true}
                  style={{ borderRadius: "0px", zIndex: "9999" }}
                  options={this.state.vehicleNumbers}
                  placeholder={"Select"}
                  required
                />
              </div>

              <div className={"col-xl-1 col-lg-1 form-group"}>
                <label htmlFor=""></label>
                <br />
                <button
                  type="button"
                  className="btn btn-info shadow mt-2"
                  onClick={this.getFilterData}
                  disabled={
                    this.state.fromDate && this.state.toDate ? null : "disabled"
                  }
                >
                  Submit
                </button>
              </div>
            </div>
          </div>

          {/* Data Card*/}
          <div className="card shadow p-4">
            {/*top data*/}
            <div className="row justify-content-around mx-0">
              {/* Avg Loading time per trip*/}
              {this.state.loadingParentData.length > 0 &&
              this.state.loadingChildData.length > 0 ? (
                <>
                  <div className="col-xl-6 col-lg-6 graphCard">
                    {/* <div className="txt-center pt-2">
                  <h6>{this.state.loadingTripHeading}</h6>
                  <label>{this.state.loadingTripSubHeading}</label>
                </div> */}

                    <div className="dataChartButton">
                      <button
                        className="btn btn-danger"
                        onClick={this.loadingTripBoxVisibility}
                        style={{
                          zIndex: "11",
                          fontSize: "12px",
                          padding: "5px 15px",
                          borderRadius: "3px",
                          marginTop: "12px",
                        }}
                      >
                        {this.state.loadingTripButtonText}
                      </button>
                    </div>

                    <div className={this.state.loadingTripGraph}>
                      {/* {
                    this.state.loadingParentData.length > 0 ? <PiechartDrilldownComponent parentData={this.state.loadingParentData} drilldowData={this.state.loadingChildData} units="Avg Hrs" backButtonText = "Loading Data"/> : <></>
                  } */}
                      <PiechartDrilldownComponent
                        title={this.state.loadingTripHeading}
                        subtitle={this.state.loadingTripSubHeading}
                        parentData={this.state.loadingParentData}
                        drilldowData={this.state.loadingChildData}
                        width={490}
                        units="Avg Hrs"
                        backButtonText="Loading Data"
                      />
                    </div>
                    <div
                      className={`${this.state.loadingTripGrid} txt-center p-2`}
                    >
                      <div className="txt-center" style={{ marginTop: "1%" }}>
                        <h6>Avg. Loading Time taken per Trip</h6>
                        <p style={{ fontSize: "12px" }}>
                          Avg. time taken for loading truck at Port
                        </p>
                      </div>
                      <DashboardGrid
                        columnDefs={loadingColumnDefs}
                        rowData={this.state.loadingData}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-xl-6 col-lg-6 graphCard">
                    <div className="txt-center" style={{ marginTop: "8%" }}>
                      <h6>Avg. Loading Time taken per Trip</h6>
                      <p>Avg. time taken for loading truck at Port</p>
                    </div>
                    <div style={{ textAlign: "center", marginTop: "27%" }}>
                      No Data Found
                    </div>
                  </div>
                </>
              )}

              {/* Avg unloading time per trip*/}
              {this.state.unloadingParentData.length > 0 &&
              this.state.unloadingChildData.length > 0 ? (
                <>
                  <div className="col-xl-6 col-lg-6 graphCard">
                    {/* <div className="txt-center pt-2">
                  <h6>{this.state.unloadingTripHeading}</h6>
                  <label>{this.state.unloadingTripSubHeading}</label>
                </div> */}
                    <div className="dataChartButton">
                      <button
                        className="btn btn-danger"
                        onClick={this.unloadingTripBoxVisibility}
                        style={{
                          zIndex: "11",
                          fontSize: "12px",
                          padding: "5px 15px",
                          borderRadius: "3px",
                          marginTop: "12px",
                        }}
                      >
                        {this.state.unloadingTripButtonText}
                      </button>
                    </div>

                    <div className={this.state.unloadingTripGraph}>
                      <PiechartDrilldownComponent
                        title={this.state.unloadingTripHeading}
                        subtitle={this.state.unloadingTripSubHeading}
                        parentData={this.state.unloadingParentData}
                        drilldowData={this.state.unloadingChildData}
                        width={490}
                        units="Avg Hrs"
                        backButtonText="Unloading Data"
                      />
                    </div>
                    <div
                      className={`${this.state.unloadingTripGrid} txt-center p-2`}
                    >
                      <div className="txt-center" style={{ marginTop: "1%" }}>
                        <h6>Avg. Unloading Time taken per Trip</h6>
                        <p style={{ fontSize: "12px" }}>
                          Avg. time taken for unloading truck in Plant
                        </p>
                      </div>
                      <DashboardGrid
                        columnDefs={unloadingColumnDefs}
                        rowData={this.state.unloadingData}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-xl-6 col-lg-6 graphCard">
                    <div className="txt-center" style={{ marginTop: "8%" }}>
                      <h6>Avg. Unloading Time taken per Trip</h6>
                      <p>Avg. time taken for unloading truck in Plant</p>
                    </div>
                    <div style={{ textAlign: "center", marginTop: "27%" }}>
                      No Data Found
                    </div>
                  </div>
                </>
              )}
            </div>

            {/*top second data*/}
            <div className="row justify-content-around mx-0 mt-3">
              {/* Avg. no. of loading-trips/time */}
              {this.state.trucksPerTripSeriesCategories.length > 0 ? (
                <>
                  <div className="col-xl-6 col-lg-6 graphCard">
                    {/* <div className="txt-center pt-2">
                  <h6>{this.state.trucksPerTripsHeading}</h6>
                  <label>{this.state.trucksPerTripsSubHeading}</label>
                </div> */}

                    <div className="dataChartButton">
                      <button
                        className="btn btn-danger"
                        onClick={this.trucksPerTripsBoxVisibility}
                        style={{
                          zIndex: "11",
                          fontSize: "12px",
                          padding: "5px 15px",
                          borderRadius: "3px",
                          marginTop: "12px",
                        }}
                      >
                        {this.state.trucksPerTripsButtonText}
                      </button>
                    </div>

                    <div className={this.state.trucksPerTripsGraph}>
                      <ColumnChartLoadingDashboard
                        title={this.state.trucksPerTripsHeading}
                        subtitle={this.state.trucksPerTripsSubHeading}
                        // subtitle={"Avg. Time taken inside the plant from Gate in to Gate out"}
                        context={this}
                        width={520}
                        series={this.state.trucksPerTripSeriesData}
                        categories={this.state.trucksPerTripSeriesCategories}
                        yaxistitle={"No. of Trucks"}
                        dept_code={"IBL"}
                        unit=" trucks"
                      />
                    </div>
                    <div
                      className={`${this.state.trucksPerTripsGrid} txt-center p-2`}
                    >
                      {/* <h4> {this.state.loadingTripHeading} </h4>
                <label> {this.state.loadingTripSubHeading} </label> */}
                      <div className="txt-center" style={{ marginTop: "1%" }}>
                        <h6>No. of Trucks per Trip</h6>
                        <p style={{ fontSize: "12px" }}>
                          Total no. of trips made by trucks in corresponding
                          time
                        </p>
                      </div>
                      <DashboardGrid
                        columnDefs={tripColumnDefs}
                        rowData={this.state.completeTripData}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-xl-6 col-lg-6 graphCard">
                    <div className="txt-center" style={{ marginTop: "8%" }}>
                      <h6>No. of Trucks per Trip</h6>
                      <p>
                        Total no. of trips made by trucks in corresponding time
                      </p>
                    </div>
                    <div style={{ textAlign: "center", marginTop: "12%" }}>
                      No Data Found
                    </div>
                  </div>
                </>
              )}

              {/* Avg. no. of unloading-trips/time */}
              {this.state.tripsPerTrucksSeriesCategories.length > 0 ? (
                <>
                  <div className="col-xl-6 col-lg-6 graphCard">
                    {/* <div className="txt-center pt-2">
                  <h6 className="font-weight-bold">{this.state.tripsPerTrucksHeading}</h6>
                  <label>{this.state.tripsPerTrucksSubHeading}</label>
                </div> */}
                    <div className="dataChartButton">
                      <button
                        className="btn btn-danger"
                        onClick={this.tripsPerTrucksBoxVisibility}
                        style={{
                          zIndex: "11",
                          fontSize: "12px",
                          padding: "5px 15px",
                          borderRadius: "3px",
                          marginTop: "12px",
                        }}
                      >
                        {this.state.tripsPerTrucksButtonText}
                      </button>
                    </div>

                    <div className={this.state.tripsPerTrucksGraph}>
                      <ColumnChartLoadingDashboard
                        title={this.state.tripsPerTrucksHeading}
                        subtitle={this.state.tripsPerTrucksSubHeading}
                        // subtitle={"Avg. Time taken inside the plant from Gate in to Gate out"}
                        context={this}
                        width={520}
                        series={this.state.tripsPerTrucksSeriesData}
                        categories={this.state.tripsPerTrucksSeriesCategories}
                        yaxistitle={"No. of Trips"}
                        dept_code={"IBL"}
                        unit=" trips"
                      />
                    </div>
                    <div
                      className={`${this.state.tripsPerTrucksGrid} txt-center p-2`}
                    >
                      <div className="txt-center" style={{ marginTop: "1%" }}>
                        <h6>No. of Trips per Truck</h6>
                        <p style={{ fontSize: "12px" }}>
                          Total no. of trips made by trucks in corresponding
                          time
                        </p>
                      </div>
                      <DashboardGrid
                        columnDefs={tripColumnDefs}
                        rowData={this.state.completeTripData}
                        gridFor="trip"
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-xl-6 col-lg-6 graphCard">
                    <div className="txt-center" style={{ marginTop: "8%" }}>
                      <h6>No. of Trips per Truck</h6>
                      <p>
                        Total no. of trips made by trucks in corresponding time
                      </p>
                    </div>
                    <div style={{ textAlign: "center", marginTop: "12%" }}>
                      No Data Found
                    </div>
                  </div>
                </>
              )}
            </div>

            {/*Bottom Card*/}
            <div className="row justify-content-around mx-0 mt-3">
              {/* Total number of trips/time */}
              {this.state.emptyTruckDataSeriesCategories.length > 0 ? (
                <>
                  <div className="col-xl-12 col-lg-12 graphCard">
                    {/* <div className="txt-center pt-2">
                  <h6>{this.state.emptyTruckAnalysisHeading}</h6>
                  <label>{this.state.emptyTruckAnalysisSubHeading}</label>
                </div> */}
                    <div className="dataChartButton">
                      <button
                        className="btn btn-danger"
                        onClick={this.emptyTruckAnalysisBoxVisibility}
                        style={{
                          zIndex: "11",
                          fontSize: "12px",
                          padding: "5px 15px",
                          borderRadius: "3px",
                          marginTop: "12px",
                        }}
                      >
                        {this.state.emptyTruckAnalysisButtonText}
                      </button>
                    </div>

                    <div className={this.state.emptyTruckAnalysisGraph}>
                      <ColumnChartLoadingDashboard
                        title={this.state.emptyTruckAnalysisHeading}
                        subtitle={this.state.emptyTruckAnalysisSubHeading}
                        // subtitle={"Avg. Time taken inside the plant from Gate in to Gate out"}
                        context={this}
                        width={1073}
                        series={this.state.emptyTruckSeriesData}
                        categories={this.state.emptyTruckDataSeriesCategories}
                        yaxistitle={"Avg. No. of Hours"}
                        dept_code={"IBL"}
                        unit=" Hrs"
                      />
                    </div>
                    <div
                      className={`${this.state.emptyTruckAnalysisGrid} txt-center p-2`}
                    >
                      <div className="txt-center" style={{ marginTop: "1%" }}>
                        <h6>Empty Truck Analysis</h6>
                        <p style={{ fontSize: "12px" }}>Avg. time taken for truck from Plant-Port</p>
                      </div>
                      <DashboardGrid
                        columnDefs={tripColumnDefs}
                        rowData={this.state.completeTripData}
                        gridFor="trip"
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-xl-12 col-lg-12 graphCard">
                    <div className="txt-center" style={{ marginTop: "4%" }}>
                      <h6>Empty Truck Analysis</h6>
                      <p>Avg. time taken for truck from Plant-Port</p>
                    </div>
                    <div style={{ textAlign: "center", marginTop: "14%",marginBottom: "10%" }}>
                      No Data Found
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
