/* eslint-disable no-unreachable */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import _, { lte } from "lodash";
import { AutoComplete } from "material-ui";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { Redirect } from "react-router-dom";
import Modal from "react-responsive-modal";
import Select from "react-select";

/*
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
*/
import axios from "axios";
import SearchTruckActiveConsignments from "../../tptlogin/tptsearchtruckconsignments";
import ModelTruckData from "../../tptlogin/truckmapdatamodelComponent";

import $ from "jquery";
var redirectURL = require("../../redirectURL");

const googleAutoSuggestURL =
    "//suggestqueries.google.com/complete/search?client=youtube&ds=yt&q=";

const decipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
    return encoded => encoded.match(/.{1,2}/g)
        .map(hex => parseInt(hex, 16))
        .map(applySaltToChar)
        .map(charCode => String.fromCharCode(charCode))
        .join('');
}
var decode = decipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

const cipher = (salt) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code) =>
        textToChars(salt).reduce((a, b) => a ^ b, code);
    try {
        return (text) =>
            text
                .split("")
                .map(textToChars)
                .map(applySaltToChar)
                .map(byteHex)
                .join("");
        // eslint-disable-next-line no-unreachable
    } catch (e) { }
};

var encode = cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
class Search extends Component {
    state = {
            dataSource: [],
            inputValue: "",
            open: false,
            searchtruck: "",
            truckNoListSearc: [],
            filteredDataSearc: [],
            showTrucksListsrch: "show-n",
            activesearchtruckconsignments: [],
            tpttrucks: [],
            filopt: { value: "Truck", label: "Truck" },
            splaceholder: "Enter Truck No",
            searchType: "",
            loginplant: '',
            is_plant_user: false,
            grDropList : [],
            invoiceDropList : [],
            trucksList : [],
            searchList: [],
            inputTypeVal : {value: "", label: ""}
    };

  

    componentDidMount() {
        let tptCode = localStorage.getItem("transportercode");
        let vehicle_group = localStorage.getItem("vehicle_group");
        let loguser = decode(localStorage.getItem("m"));
        // console.log("LOGUSER ", JSON.parse(loguser));
        let loginplant = "";
        let loginPLantName = '';
        try {
            let luser = JSON.parse(loguser);
            loginplant = luser.is_plant_code;
            loginPLantName = luser.is_plant_name
            // console.log("logplant", luser)
            if(loginPLantName){
                this.setState({
                    loginplant: loginplant,
                    loginPLantName,
                    is_plant_user: true
                    // consigner:[{"value":luser.is_plant_code,"label":luser.is_plant_name}]
                })
            }
        }
        catch (e) { }
        var tptrucks = [];
        if (vehicle_group != "") {
            var tparms = {
                transporter_code: tptCode,
                region: vehicle_group,
            };
            redirectURL
                .post("/dashboard/tpregiontrucksdata", tparms)
                .then((response) => {
                    var records = response.data.records;
                    // console.log("records ", records)
                    this.setState({
                        tpttrucks: records,
                    });
                });
        }
        var depmntCode = "";
        // if (localStorage.getItem("dept_code") != undefined) {
        //     var depmntCodeJns = JSON.parse(localStorage.getItem("dept_code"));
        //     // console.log("depmntCodeJns120",depmntCodeJns[0])
        //     var depmntCode = depmntCodeJns[0];
        // }
        let dpt = localStorage.getItem("dept_code");
        let dropDownParams = {
            dept_code: encode(dpt),
        }
        let transporter_code= localStorage.getItem("transportercode")
        if(transporter_code !== undefined && transporter_code !== ""){
            dropDownParams.transporter_code = transporter_code
        }

        this.getDropDownValues(dropDownParams)
        let currentDepartmentCode = depmntCode;

        if (currentDepartmentCode == "SNDG") {
            var defaulttab = this.state.defaulttab;
        } else {
            var defaulttab = "";
        }

        var israil = 0;
        var userroles = localStorage.getItem("roles");
        if (userroles.indexOf("tpt_rail") > 0) {
            israil = 1;
            var defaulttab = this.state.defaulttab;
        }
        var depmntCode = "";
        if (localStorage.getItem("dept_code") != undefined) {
            var depmntCodeJns = JSON.parse(localStorage.getItem("dept_code"));
            var depmntCode = depmntCodeJns[0];
        }
        // var tptCode = localStorage.getItem("transportercode");
        let layoutdata = {
            //token:localStorage.getItem('token'),
            israil: israil,
            defaulttab: defaulttab,
            deptcode: encode(currentDepartmentCode),
            is_enmovil_transporter: localStorage.getItem("is_enmovil_transporter"),
            transporter_code: localStorage.getItem("transportercode"),
        };


        // commented below code on 26-07-2022 becoz of error 400 and this code is not used  in this screen functionality
        // redirectURL.post("/overview/truckWithGPSData", layoutdata)
        //     .then(async (response) => {
        //         var recs = JSON.parse(JSON.stringify(response)).data;
        //         if (recs.length > 0) {
        //             var transporterTrucks = [];
        //             await redirectURL
        //                 .post("/dashboard/devices")
        //                 .then(async (response1) => {
        //                     var transptrucklist = [];
        //                     response1.data.devices.map(function (e) {
        //                         transporterTrucks.push(e);
        //                     });
        //                     this.setState({
        //                         // truckNoListSearc:transptrucklist
        //                     });
        //                 });

        //             // var transporterTrucks = this.state.transporterTrucks;
        //             if (transporterTrucks.length > 0) {
        //                 var records = [];
        //                 // recs.map(function(t){
        //                 // 	if(transporterTrucks.indexOf(t.truck_no) > -1 )
        //                 // 	{
        //                 // 		records.push(t)
        //                 // 	}
        //                 // })
        //                 var records = await recs.filter((f) =>
        //                     transporterTrucks.filter((d) => {
        //                         if (d.truck_no == f.truck_no) {
        //                             // console.log("API ", d)
        //                             if (d.registereddevices != undefined) {
        //                                 if (d.registereddevices.length > 0) {
        //                                     f.device_address = d.registereddevices[0].address;
        //                                     f.device_city = d.registereddevices[0].city;
        //                                     f.device_state = d.registereddevices[0].state;
        //                                 }
        //                             }
        //                             f.timestamp = d.last_active_on;
        //                             f.modified_date = d.modified_date;
        //                             return f;
        //                         }
        //                     })
        //                 );
        //                 //console.log("In indexcomponent, /overview/truckWithGPSData, records = ", records);
        //                 var transptrucklist = [];
        //                 if (records.length > 0) {
        //                     records.map((item) => {
        //                         transptrucklist.push(item.truck_no);
        //                     });
        //                 }
        //                 await this.setState({
        //                     truckNoListSearc: transptrucklist,
        //                 });
        //             }
        //         }
        //     })
        //     .catch(function (error) {
        //         console.log(error);
        //     });
    }

    getDropDownValues = param =>{
        redirectURL.post("/dashboard/getDropDownValuesForGlobalSearch", param).then(response =>{
            // console.log("response data", response.data)
            const grDropList = response.data.grDropList
            const invoiceDropList = response.data.invoiceDropList
            const trucksList = response.data.trucksList
            this.setState({
                grDropList,invoiceDropList,trucksList, searchList : trucksList
            })
        })
    }
    onUpdateInput = (inputValue) => {
        const self = this;
        this.setState(
            {
                inputValue: inputValue,
            },
            function () {
                self.performSearch();
            }
        );
    }

    performSearch = () => {
        const self = this,
            //url  = googleAutoSuggestURL + this.state.inputValue;
            url = "http://localhost:3001/search/keyword?q=" + this.state.inputValue;

        if (this.state.inputValue !== "") {
            redirectURL(url).then((response, error) => {
                // console.log("Resjults ", response.data);
                let searchResults, retrievedSearchTerms;

                if (error) return error;

                searchResults = response.data[1];

                retrievedSearchTerms = searchResults.map(function (result) {
                    return result[0] + " (" + result[1] + ")";
                });

                self.setState({
                    dataSource: retrievedSearchTerms,
                });
            });
        }
    }

    callback = (params) => {
        // console.log("params ", params);
    }
    onSelectOption = (params) => {
        var keyword = document.querySelector("#keyword").value;
        if (keyword == "") {
        } else {
            // console.log("Keyword ", keyword);
            var keys = keyword.split("(");
            var boardtype = keys[1].split(")");
            var dashboardType = boardtype[0];
            // console.log(dashboardType);
            var num = keys[0].split(" ");
            var number = num[0];
            if (dashboardType.toLowerCase() == "consignment") {
                window.location.href = "/consignment/" + number;
            }
        }
    }
    changeHandler = (event) => {
        //console.log("Select", event.target.value);
        let name = event.target.name;
        let value = event.target.value;
        this.setState({ [name]: value });
    };
    onCloseModal = () => {
        this.setState({ open: false });
        $("#hinputTruck").val("");
    };

    onSubmitSearchTruck = async (event) => {
        event.preventDefault();
        // console.log("Submit success")
        // var searchtruck = $("#hinputTruck").val();
        var searchtruck = $("#selectedTypeFilter").text();        
        let searchType = $("#selectedFilter").text();
        
        var prms = {
            truck_no: [searchtruck],
            searchType,
        };
        // console.log("prms 315", prms)
        // console.log("searchType", searchType);
        if (
            searchtruck !== "" &&
            searchtruck !== undefined &&
            searchtruck !== null
        ) {
            this.setState({
                open: true,
                searchType,
            });
        }

        await redirectURL.post("/dashboard/searchtruckconsignments", prms).then(async (response) => {
                var records = response.data.consignments;
                records = await records.sort(GetSearchSortDescOrder("_id"));
                // console.log("records[0].truck_no ", records)
                if (records.length > 0) {
                    await this.setState({
                        activesearchtruckconsignments: records,
                        searchtruck: records[0].truck_no,
                        inputTypeVal : {value: "", label: ""}
                    });
                } else {
                    await this.setState({
                        activesearchtruckconsignments: [],
                        searchtruck: searchtruck,
                    });
                }
            });
    };

    initalListsearc = (event) => {
        $(".trucks-dropdown-srch").removeClass("show-n");
        var dataset = this.state.truckNoListSearc;
        dataset = dataset.slice(0, 100);
        var tpttrucks = this.state.tpttrucks;
        var tptunqrecords = [];
        if (tpttrucks.length > 0) {
            dataset.filter((f) =>
                tpttrucks.filter((d) => {
                    if (d.truck_no == f) {
                        tptunqrecords.push(f);
                    }
                })
            );
        } else {
            tptunqrecords = dataset;
        }
        this.setState({
            filteredDataSearc: tptunqrecords,
            showTrucksListsrch: "show-m",
        });
    };
    handlerFormSearc = (event) => {
        try {
            $(".trucks-dropdown-srch").removeClass("show-n");
            var dataset = this.state.truckNoListSearc;
            var tpttrucks = this.state.tpttrucks;
            var tptunqrecords = [];
            if (tpttrucks.length > 0) {
                dataset.filter((f) =>
                    tpttrucks.filter((d) => {
                        if (d.truck_no == f) {
                            tptunqrecords.push(f);
                        }
                    })
                );
            } else {
                tptunqrecords = dataset;
            }
            var filteredResult = tptunqrecords.filter(function (e) {
                if (e != "" && e != null) {
                    return e
                        .toString()
                        .toLowerCase()
                        .includes(
                            $("#hinputTruck")
                                .val()
                                .toLocaleLowerCase()
                        );
                }
            });
            filteredResult = filteredResult.slice(0, 100);
            this.setState({
                filteredDataSearc: filteredResult,
                showTrucksListsrch: "show-m",
            });
        } catch (e) { }
    };
    onClickTruck = (event) => {
        $("#hinputTruck").val($(this).text());
    }
    onChangeFilOpt = (filopt) => {
        const {trucksList, grDropList, invoiceDropList} = this.state
        this.setState({ filopt }, () => {
            // console.log("opt ", this.state.filopt);
            if (this.state.filopt.value == "Truck") {
                this.setState({
                    splaceholder: "Enter Truck No",
                    searchList : trucksList
                });
            } else if (this.state.filopt.value == "Consingments") {
                this.setState({
                    splaceholder: "Enter GR No",
                    searchList : grDropList

                });
            } else {
                this.setState({
                    splaceholder: "Enter Invoice No",
                    searchList : invoiceDropList
                });
            }
        });
    };

    onChangeSearchItem = param =>{
        const {filopt} = this.state
        // console.log("param",param)
        this.setState({
            inputTypeVal : param
        })
        // if (this.state.filopt.value == "Truck") {
        //     this.setState({
        //         searchTruckNum : param.value
        //     });
        // } else if (this.state.filopt.value == "Consingments") {
        //     this.setState({
        //     });
        // } else {
        //     this.setState({
        //     });
        // }
    }

    render() {
        const modalStyles = {
            width: "1200px !important",
            maxWidth: "1200px !important",
        };
        const { open } = this.state;

        const SearchIcon = require("../../../assets/images/dashboard/search.png");

        var searchStyle = {
            backgroundImage: "url(" + SearchIcon + ")",
            backgroundPosition: "left",
            paddingLeft: "20px",
            fontSize: "13px",
            backgroundRepeat: "no-repeat",
            transform: "translateY(0) scaleY(1)",
            opacity: 1,
            visibility: "visible",
            transition: "all linear .3s",
        };
        /*if(this.state.searchbar)
            {
                searchStyle = {
                    backgroundImage: "url("+SearchIcon+")",
                    transform: 'translateY(0) scaleY(1)',
                    opacity: 1,
                    visibility: 'visible',
                    transition: 'all linear .3s'
                }
            }else{
                searchStyle = {
                    backgroundImage: "url("+SearchIcon+")"
                }
            }
            */
        var listDatasearch = [];
        try {
            this.state.filteredDataSearc.map(function (e) {
                listDatasearch.push(
                    <li
                        className="dropdown-truck-no"
                        style={{ textAlign: "left", width: "100%" }}
                    >
                        {e}
                    </li>
                );
            });
        } catch (e) {
            console.log(e);
        }

        var is_enmovil_transporter = localStorage.getItem("is_enmovil_transporter");

        const { is_plant_user, loginPLantName } = this.state
        return (
            <div>

                {/* {is_plant_user ? <h5 style={{fontFamily:"Roboto", fontWeight: 'bold'}}>{loginPLantName}</h5> :
                } */}
                    <form
                        className="form-inline search-form"
                        method="POST"
                        onSubmit={this.onSubmitSearchTruck}
                    >
                        <div className="form-group" style={{ position: "relative" }}>
                            <div
                                className={
                                    "trucks-dropdown-srch " + this.state.showTrucksListsrch
                                }
                                style={{ width: "16em", left: "0px" }}
                            >
                                {/* <ul>
                                    {listDatasearch}
                                </ul> */}
                            </div>
                            {/* <input
                        type="search"
                        id="hinputTruck"
                        className="float-left form-control-plaintext"
                        placeholder="Enter Truck NO"
                        autoComplete="off" 
                        onFocus={this.initalList.bind(this)} 
                        onChange={this.handlerForm.bind(this)}
                        name="searchtruck"
                        value={this.state.searchtruck}
                    /> */}
                            {/* <div className="container-fluid"> */}
                                {/* <div className="row" style={{width : '360px'}}>
                                    <div className="col-lg-3 col-xl-3 p-0">
                                        <Select
                                            className="hdrop"
                                            isMulti={false}
                                            id="selectedFilter"
                                            style={{ borderRadius: "0px" }}
                                            value={this.state.filopt}
                                            onChange={this.onChangeFilOpt}
                                            options={[
                                                { label: "Truck", value: "Truck" },
                                                { label: "GR No", value: "Consingments" },
                                                { label: "Invoice", value: "Invoice" },
                                            ]}
                                            required
                                        />
                                    </div>
                                    <div className="col-lg-7 col-xl-7 p-0"> */}
                                    {/* <Select
                                            // className="hdrop"
                                            isMulti={false}
                                            id="selectedTypeFilter"
                                            closeMenuOnSelect={true}
                                            placeholder= "Select value"
                                            // style={{ borderRadius: "0px" }}
                                            value={this.state.inputTypeVal}
                                            onChange={this.onChangeSearchItem}
                                            options={this.state.searchList}
                                            required
                                        /> */}
                                        {/* <input
                                            type="text"
                                            name="truck_no"
                                            id="hinputTruck"
                                            placeholder={this.state.splaceholder}
                                            autoComplete="off"
                                            // className="float-left form-control-plaintext"
                                            className="float-left form-control py-2 border-right-0 border"
                                           onFocus={this.initalListsearc}
                                           onChange={this.handlerFormSearc}
                                        /> 
                                        {/* <span 
                                        // class="input-group-append"
                                        >
                                            <button
                                                class="btn btn-outline-secondary border-left-0 border"
                                                type="submit"
                                            >
                                                <i class="fa fa-search"></i>
                                            </button>
                                        </span> */}
                                        {/* <span class="input-group-append">
                                        <button class ="btn btn-outline-secondary border-left-0 border" type ="submit">
                                        Search
                                        </button>
                                    </span> */}
                                    {/* </div>
                                    <div className="col-lg-2 col-xl-2 p-0">
                                    <span 
                                        // class="input-group-append"
                                        >
                                            <button
                                                class="btn btn-outline-secondary border-left-0 border"
                                                type="submit"
                                            >
                                                <i class="fa fa-search"></i>
                                            </button>
                                        </span>
                                    </div> */}
                                    {/* <div className="col-lg-auto col-xl-auto"> <button type="submit"
                                    className="float-left mobile-search"
                                //style ={ { backgroundImage: "url("+SearchIcon+")" } }
                                // onClick={() => this.handleSearchClick()}
                                >
                                    <img src={SearchIcon} alt="search" />
                                </button></div> */}
                                {/* </div> */}
                            {/* </div> */}
                            {/* <MuiThemeProvider>
                        <AutoComplete
                            dataSource    = {this.state.dataSource}
                            onUpdateInput = {this.onUpdateInput}
                            //style ={ searchStyle }
                            id="keyword"
                            
                            />
                    </MuiThemeProvider>
                    <button type="button"
                        className="float-left mobile-search"
                        //style ={ { backgroundImage: "url("+SearchIcon+")" } }
                        onClick={this.onSelectOption}
                    >
                    <img src={SearchIcon} />
                     </button> */}
                        </div>
                    </form>

                <Modal
                    open={open}
                    onClose={this.onCloseModal}
                    styles={{ width: "1200px !important" }}
                >
                    <div
                        className={"row"}
                        style={{
                            height: "100vh",
                            padding: "5px 0px",
                            width: "100%",
                            overflow: "hidden",
                            zIndex: "9999",
                            overflowY: 'scroll',
                        }}
                    >
                        <div className="col-xl-12 col-lg-12">
                            <ModelTruckData
                                truck_no={this.state.searchtruck}
                                stype={this.state.filopt.value}
                            />
                        </div>
                        <div className="col-xl-12 col-lg-12">
                            <SearchTruckActiveConsignments
                                rowdata={this.state.activesearchtruckconsignments}
                                searchType={this.state.searchType}
                                searchtruck={this.state.searchtruck}
                            />
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

$(document).ready(function () {
    $(document).on("click", ".dropdown-truck-no", function () {
        var vals = $(this).text();
        $("#hinputTruck").val(vals);
        $(".trucks-dropdown-srch").removeClass("show-m");
        $(".trucks-dropdown-srch").addClass("show-n");
    });

    $("body").on("click", function (e) {
        var container = $("#hinputTruck");
        if (!container.is(e.target)) {
            try {
                if (
                    $(".trucks-dropdown-srch")
                        .attr("class")
                        .includes("show-m") ||
                    $(".trucks-dropdown-srch").attr("class") == "trucks-dropdown-srch"
                ) {
                    $(".trucks-dropdown-srch").removeClass("show-m");
                    $(".trucks-dropdown-srch").addClass("show-n");
                }
            } catch (e) { }
        }
    });
});
function GetSearchSortDescOrder(prop) {
    return function (a, b) {
        if (a[prop] < b[prop]) {
            return 1;
        } else if (a[prop] > b[prop]) {
            return -1;
        }
        return 0;
    };
}

export default Search;
