/* eslint-disable eqeqeq */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import _ from "lodash";
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';

import axios from 'axios';
import Multiselect from 'multiselect-dropdown-react';
import Breadcrumb from '../common/breadcrumb.component';
import { Redirect } from 'react-router-dom';
import { color } from 'highcharts';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749', '#F73E3E', '#B7B3B3'];
var selectedColor;
var colorButtons = {};
var fencingLength = 0;
var mapZoom = 12;
var coordinates = [];
const fenchtypeoptions = [
	{ value: 'Plant', label: 'Plant' },
	{ value: 'Parking', label: 'Parking' }
	//   { value: 'TVP', label: 'TVP' },
	//   { value: 'Stockyard', label: 'Stockyard' },
	//   { value: 'Railyard', label: 'Railyard' },
	//   { value: 'Port', label: 'Port' },
	//   { value: 'Vendor', label: 'Vendor' },
	//   { value: 'MSP', label: 'MSP' },
];


export default class AddGeoFence extends Component {
	state = {
		pageTitle: "Add Geo Fence",
		eventGridAction: "gridAction",
		eventCounterAction: "counterAction",
		eventFormAction: "formAction",
		currentDepartmentCode: null,
		currentDepartmentTitle: null,
		routemap: '',
		departments: [],
		coords: [],
		geo_name: '',
		geo_type: '',
		fence_type: '',
		dept_code: [],
		notify: '',
		erroMessage: '',
		redirectpage: '',
		typeListOptions: [],
		plantListOptoins: [],
		show: false,
		basicTitle: '',
		basicType: "default",
		isParking: false,
		plantDetails: null,

	};

	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
			} else {
				console.log("GA disabled...");
			}
		} catch (error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
	componentDidMount() {
		if (googleAnalytics.page.enableGA) {
			this.logPageView();
		}
		//get dynamic list for type dropdown
		this.getDropDownList()
		// get department code to filter records by department
		let currentDepartmentCode = this.getCurrentDepartmentCode(this.props.match.path);
		if (!currentDepartmentCode) {
			setTimeout(
				function () {
					redirectURL.post('/overview/departments')
						.then((response) => {
							//console.log(response.data);
							this.setState({
								departments: response.data
							});

						})
						.catch(function (error) {
							console.log(error)
						});

				}
					.bind(this),
				2000
			);
		}
		this.showMap();
	}

	getDropDownList = () => {
		redirectURL.post('/consignments/getTypeDropDownForgeoFence').then(response => {
			console.log("response1add", response.data)
			let typeListOptions = response.data.filteredData
			// typeListOptions.filter(item => item.label === 'Coal Mine' || item.label === 'Plant')
			// console.log(typeListOptions,"add options");
			// // let typeListOptions = [{ value: "Plant", label: "Plant" }, { value: "Port", label: "Coal Mine" }]
			let plantListOptoins = response.data.pD1
			console.log('typeListOptions',typeListOptions)
			// console.log('plantListOptoins',plantListOptoins)
			this.setState({
				typeListOptions,
				plantListOptoins
			})

		})
	}

	showMap = () => {
		this.renderMap();
	}
	renderMap = () => {

		loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyBI9tCwOL8bO4iIFRCWucbzeWfJOEX-y34&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
	}
	getCurrentDepartmentCode(pathToMatch = null) {
		let departmentCode = null;
		let departmentName = null;
		let departmentTitle = null;
		switch (pathToMatch) {
			case "/manage/sndaddgeofence":
				departmentCode = 'IBL';
				departmentName = "Inbound Logistics";
				departmentTitle = " (Inbound Logistics) ";
				break;
			case "/manage/prtaddgeofence":
				departmentCode = 'LOG-PRT';
				departmentName = "Spare Parts";
				departmentTitle = " (Spare Parts) ";
				break;
			case "/manage/tnpaddgeofence":
				departmentCode = 'LOG-TNP';
				departmentName = "Train and Production";
				departmentTitle = " (Train and Production) ";
				break;
			case "/manage/scaddgeofence":
				departmentCode = 'LOG-SC';
				departmentName = "Supply Chain";
				departmentTitle = " (Supply Chain) ";
				break;
			case "/manage/mllsndaddgeofence":
				departmentCode = 'SNDG';
				departmentName = "Sales and Dispatch";
				departmentTitle = " ";
				break;
			default:
				console.log("IN getDepartmentFilter, default, params = ", pathToMatch);
		}
		this.setState({
			currentDepartmentTitle: departmentTitle,
			currentDepartmentCode: departmentCode,
			departments: [{ "dept_code": departmentCode, "dept_name": departmentName }],
			dept_code: departmentCode
		});
		return departmentCode;
	}

	departmentDropDown() {
		let items = [];
		console.log("this.state.departments = ", this.state.departments);
		this.state.departments.map(item => {
			items.push({ value: item.dept_code, label: item.dept_name });
			//items.push(<option key={item.dept_code} value={item.dept_code}>{item.dept_name}</option>);
		});
		//console.log("IN departmentDropDown items= ", items);
		return items;
	}

	initMap = () => {
		var coordsvalues;
		var map = new window.google.maps.Map(document.getElementById('map_canvas'), {
			zoom: 12,
			center: new window.google.maps.LatLng(17.3580, 78.4869),
			mapTypeId: window.google.maps.MapTypeId.ROADMAP,
			mapTypeControl: true,
			mapTypeControlOptions: {
				style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
				position: window.google.maps.ControlPosition.TOP_RIGHT
			},
			disableDefaultUI: true,
			zoomControl: true
		});

		//added by nanda for places dropdown...
		var input = document.getElementById('search');
		var searchBox = new window.google.maps.places.SearchBox(input);
		map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

		// Bias the SearchBox results towards current map's viewport.
		map.addListener('bounds_changed', function () {
			searchBox.setBounds(map.getBounds());
		});

		var markers = [];
		console.log("searchBox", searchBox);
		searchBox.addListener('places_changed', function () {
			var places = searchBox.getPlaces();

			if (places.length == 0) {
				return;
			}

			// Clear out the old markers.
			markers.forEach(function (marker) {
				marker.setMap(null);
			});
			markers = [];
			// For each place, get the icon, name and location.
			var bounds = new window.google.maps.LatLngBounds();
			places.forEach(function (place) {
				if (!place.geometry) {
					console.log("Returned place contains no geometry");
					return;
				}
				var icon = {
					url: place.icon,
					size: new window.google.maps.Size(71, 71),
					origin: new window.google.maps.Point(0, 0),
					anchor: new window.google.maps.Point(17, 34),
					scaledSize: new window.google.maps.Size(25, 25)
				};

				// Create a marker for each place.
				markers.push(new window.google.maps.Marker({
					map: map,
					icon: icon,
					title: place.name,
					position: place.geometry.location
				}));

				if (place.geometry.viewport) {
					// Only geocodes have viewport.
					bounds.union(place.geometry.viewport);
				} else {
					bounds.extend(place.geometry.location);
				}
			});
			map.fitBounds(bounds);
		});
		//End of adding by nanda

		var polyOptions = {
			strokeWeight: 0,
			fillOpacity: 0.70,
			editable: true
		};
		// Creates a drawing manager attached to the map that allows the user to draw
		// markers, lines, and shapes.
		drawingManager = new window.google.maps.drawing.DrawingManager({
			drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
			drawingControlOptions: {
				drawingModes: [
					window.google.maps.drawing.OverlayType.POLYGON
				]
			},
			markerOptions: {
				draggable: true
			},
			polylineOptions: {
				editable: true
			},
			rectangleOptions: polyOptions,
			circleOptions: polyOptions,
			polygonOptions: polyOptions,
			map: map
		});

		window.google.maps.event.addListener(drawingManager, 'overlaycomplete', function (e) {
			if (e.type != window.google.maps.drawing.OverlayType.MARKER) {
				// Switch back to non-drawing mode after drawing a shape.
				drawingManager.setDrawingMode(null);
				// To hide:
				drawingManager.setOptions({
					drawingControl: false
				});

				// Add an event listener that selects the newly-drawn shape when the user
				// mouses down on it.
				var newShape = e.overlay;
				newShape.type = e.type;
				window.google.maps.event.addListener(newShape, 'click', function () {
					setSelection(newShape);
				});
				setSelection(newShape);
				//console.log("selectedShape",newShape);
				fencingLength = selectedShape.getPath().getLength();

				var latlngObj = [];
				var htmlStr = "";
				for (var i = 0; i < fencingLength; i++) {
					var each_lat_lng = selectedShape.getPath().getAt(i).toUrlValue(5).split(',');
					var in_latlng_array = { "lat": parseFloat(each_lat_lng[0]), "lng": parseFloat(each_lat_lng[1]) };
					latlngObj.push(in_latlng_array);
					coordinates.push(in_latlng_array);
				}

			}

		});


		// Clear the current selection when the drawing mode is changed, or when the
		// map is clicked.
		window.google.maps.event.addListener(drawingManager, 'drawingmode_changed', function () {
			clearSelection()
		});
		window.google.maps.event.addListener(map, 'click', function () {
			clearSelection()
		});
		/*
		 * window.google.maps.event.addDomListener(document.getElementById('delete-button'), 'click', function(){
				deleteSelectedShape()
		});
		*/


		//buildColorPalette();
		map.addListener('zoom_changed', function () {
			mapZoom = map.getZoom();
		});

		/*window.google.maps.event.addDomListener(document.getElementById('saveGeo'), 'click', function(){
			getSelectedShape()
		});*/

		console.log("coordinates", coordinates);

	}



	fenceTypeHandler = (event) => {
		console.log(event.target)
	}


	changeHandler = geo_type => {
		if (geo_type.value == 'Parking') {
			this.setState({
				isParking: true,
				geo_type
			})
		} else {
			this.setState(
				{ geo_type, isParking: false },
				// () => console.log(`Option selected:`, this.state.geo_type)
			);
		}
	}

	changePlantHandler = plantDetails => {
		this.setState({ plantDetails })
	}

	checkHandler = (event) => {

		let name = event.target.name;
		let value = event.target.value;
		this.setState({ [name]: value });
	}
	changeInputHandler = (event) => {
		let name = event.target.name;
		let value = event.target.value;
		this.setState({ [name]: value });
	}

	changeArrayHandler = dept_code => {
		/*this.setState({
			dept_code:[...this.state.dept_code,event.target.value]
		},() => {
			console.log(this.state.dept_code)
		});
		*/

		this.setState(
			{ dept_code },
			() => console.log(`Option selected:`, this.state.dept_code)
		);


	}

	checkNotifyHandler = (event) => {
		let name = event.target.name;
		let value = event.target.value;
		this.setState({ [name]: value });
	}

	applyCoords = (e) => {
		console.log(e);
	}

	formHandler = (event) => {

		event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmitted,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var formdata1 = new FormData(event.target);
		console.log(coordinates.length);

		if (coordinates.length > 0) {
			var formdata = {
				geo_name: this.state.geo_name,
				dept_code: [this.state.dept_code],
				geo_type: this.state.geo_type,
				fence_type: this.state.fence_type,
				notify: this.state.notify,
				coordinates: coordinates
			}
			if (this.state.plantDetails !== null && this.state.plantDetails !== undefined) formdata.plant_code = this.state.plantDetails.value
			console.log(formdata);
			console.log("coordinates", coordinates);
			if (this.state.geo_name == "" || this.state.dept_code == "" || this.state.geo_type == "" || this.state.fence_type == "" || this.state.notify == "") {
				this.setState({
					show: true,
					basicType: "warning",
					basicTitle: "All Fields Are Mandatory"

				})
				return
			}
			redirectURL.post("/geofence/addGeo", formdata, {
				headers: {
					'Content-Type': 'application/json'
				}
			}).
				then(
					(response) => {
						console.log(response);
						if (response.data.message == 'Success') {
							this.setState({
								geo_name: '',
								dept_code: '',
								geo_type: '',
								fence_type: '',
								notify: '',
								coordinates: '',
								//redirectpage:'yes'
								show: true,
								basicType: "success",
								basicTitle: "Successfully added geofence"
							});

						}

						window.location.href = '/manage/sndgeofences';

					}
				)
				.catch(function (error) {
					console.log(error);
				});
		}
		else {
			// this.setState({
			// 	erroMessage: 'Please Add Coordinates'
			// });
			this.setState({
				show: true,
				basicTitle: "Please select GeoFence.",
				basicType: "warning",
			});
		}

	}

	closeAlert = async () => {
		await this.setState({
			show: false
		});
		// await window.location.reload();
	}

	render() {
		if (this.state.redirectpage == 'yes') {
			if (this.props.match.path == "/manage/mllsndaddgeofence") {
				return <Redirect to={'/manage/mllsndgeofences'} push={true} />
			}
			else {
				return <Redirect to={'/manage/geofences'} push={true} />
			}

		}
		if (this.props.match.path == "/manage/sndaddgeofence") {
			var redurl = "/manage/sndgeofences";
		}
		else if (this.props.match.path == "/manage/prtaddgeofence") {
			var redurl = "/manage/prtgeofences";
		}
		else {
			var redurl = "/manage/geofences";
		}
		let order_cnt = [];

		const dStyles = {
			width: '100%',
			height: '500px'
		}
		const { dept_code } = this.state;
		const { geo_type } = this.state;

		return (
			<div className="container-fluid">
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}>
				</SweetAlert>
				<div className="">
				<div className="mb-4">
								<h5>
									<i className="icon-map cus-i"></i> <span>{this.state.pageTitle}</span>
								</h5>
							</div>
							</div>
				<div className="row">
					<div className="col-xl-12 col-lg-12">
						<div className="card">

							
							<div className="row card-body">

								<form className=" theme-form col-xl-4 col-lg-4" onSubmit={this.formHandler}>
									<div className="col-xl-12 col-lg-12">
										<div className="form-group">
											<label className="c-lbl">Geo Fence Name <span style={{ color: "red" }}>*</span></label>
											<input type="text" name="geo_name" value={this.state.geo_name} onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Name" required />
										</div>

										<div className="form-group">
											<label className="c-lbl">Department <span style={{ color: "red" }}>*</span></label>
											<Select value={this.state.dept_code} onChange={this.changeArrayHandler.bind(this)} style={{ borderRadius: "0px" }} options={this.departmentDropDown()} required />
											{/*
					               			<select name="dept_code[]" multiple="multiple" onChange={this.changeArrayHandler.bind(this)} value={this.state.dept_code} className="form-control" >
					               				<option value="">Select Department</option>
					               				{this.departmentDropDown()}
					               			</select>
					               			*/}
										</div>

										<div className="form-group">
											<label className="c-lbl">Geo Fence Type <span style={{ color: "red" }}>*</span></label>
											<Select value={this.state.geo_type} onChange={this.changeHandler.bind(this)} style={{ borderRadius: "0px" }}
												options={this.state.typeListOptions}
												//options={fenchtypeoptions}
												required />
										</div>
										{this.state.isParking == true ?
											<div className="form-group">
												<label className="c-lbl">Plant <span style={{ color: "red" }}>*</span></label>
												<Select value={this.state.plantDetails} onChange={this.changePlantHandler.bind(this)} style={{ borderRadius: "0px" }}
													options={this.state.plantListOptoins}
													required />
											</div>
											: ''
										}

										<div className="form-group">
											<label className="c-lbl">Fence Loading Type <span style={{ color: "red" }}>*</span></label>
											<div className="row col-xl-12 col-lg-12">
												<div className="radio radio-primary col col-xl-6 col-lg-6">
													<input id="radio11" onChange={this.checkHandler} name="fence_type" type="radio" className="form-check-input" value="Both" />
													<label htmlFor="radio11" className="">Both</label>
												</div>
												<div className="radio radio-primary col col-xl-6 col-lg-6">
													<input id="radio22" onChange={this.checkHandler} name="fence_type" type="radio" className="form-check-input" value="Loading Bay" />
													<label htmlFor="radio22" className="">Loading Bay</label>
												</div>
												<div className="radio radio-primary col col-xl-6 col-lg-6">
													<input id="radio33" onChange={this.checkHandler} name="fence_type" type="radio" className="form-check-input" value="Unloading Bay" />
													<label htmlFor="radio33" className="">Unloading Bay</label>
												</div>
											</div>
										</div>

										<div className="form-group">
											<label className="c-lbl">Notify Me <span style={{ color: "red" }}>*</span></label>
											<div className="row col-xl-12 col-lg-12">
												<div className="radio radio-primary col col-xl-6 col-lg-6">
													<input id="radio44" onChange={this.checkNotifyHandler} name="notify" type="radio" className="form-check-input" value="Vehicle Comes in" />
													<label htmlFor="radio44" className="">Vehicle Comes in</label>
												</div>
												<div className="radio radio-primary col col-xl-6 col-lg-6">
													<input id="radio55" onChange={this.checkNotifyHandler} name="notify" type="radio" className="form-check-input" value="Vehicle Goes out" />
													<label htmlFor="radio55" className="">Vehicle Goes out</label>
												</div>
												<div className="radio radio-primary col col-xl-6 col-lg-6">
													<input id="radio66" onChange={this.checkNotifyHandler} name="notify" type="radio" className="form-check-input" value="Both" />
													<label htmlFor="radio66" className="">Both</label>
												</div>
												<div className="radio radio-primary col col-xl-6 col-lg-6">
													<input id="radio77" onChange={this.checkNotifyHandler} name="notify" type="radio" className="form-check-input" value="Never" />
													<label htmlFor="radio77" className="">Never</label>
												</div>
											</div>
										</div>
										<div className="form-group">
											<button type="submit" className="btn btn-success cs-btn" id="saveGeo">Save Geofence</button>
											<a href={redurl} className="btn btn-info cs-btn">Cancel</a>

										</div>
									</div>

								</form>
								<div className="col-xl-8 col-lg-8">

									{this.state.erroMessage == '' ? '' :
										<div className="alert alert-danger"></div>
									}
									<input type="text" name="search" className="col-xl-6 col-lg-6 form-control mt-5p" id="search" placeholder="Enter Address to search" />


									<div id="map_canvas" className="col-xl-12 col-lg-12" style={dStyles}></div>

									<div className="col-xl-12 col-lg-12">
										<input type="hidden" name="coords" id="coords" value={this.state.coords} />
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		);
	}
}
function loadScript(url) {
	var index = window.document.getElementsByTagName("script")[0]
	var script = window.document.createElement("script")
	script.src = url
	script.async = true
	script.defer = true
	index.parentNode.insertBefore(script, index)
}

function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = Math.floor(hours);
	var minutes = (n) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}
var currentinfowindow = null;
function getTimeInfo(marker, timedet, coords) {
	var timeinfo = new window.google.maps.InfoWindow({
		content: "Time at this location: " + timedet
	});

	marker.addListener('click', function () {

		if (currentinfowindow != null) {
			currentinfowindow.close();
			currentinfowindow = timeinfo;
			timeinfo.open(marker.get('map'), marker);
		}
		else {
			currentinfowindow = timeinfo;
			timeinfo.open(marker.get('map'), marker);
		}

	});
}


function clearSelection() {
	if (selectedShape) {
		selectedShape.setEditable(false);
		selectedShape = null;
	}
}

function setSelection(shape) {
	clearSelection();
	selectedShape = shape;
	//console.log("selectedShape",selectedShape);
	shape.setEditable(true);

	selectColor(shape.get('fillColor') || shape.get('strokeColor'));
}

function deleteSelectedShape() {
	fencingLength = 0;
	if (selectedShape) {
		selectedShape.setMap(null);
	}
	// To show:
	drawingManager.setOptions({
		drawingControl: true
	});
}


function selectColor(color) {
	selectedColor = color;
	for (var i = 0; i < colors.length; ++i) {
		var currColor = colors[i];
		//console.log(colorButtons[currColor]);
		//colorButtons[currColor].style.border = currColor == color ? '1px solid #789' : '1px solid #fff';
	}

	// Retrieves the current options from the drawing manager and replaces the
	// stroke or fill color as appropriate.
	var polylineOptions = drawingManager.get('polylineOptions');
	polylineOptions.strokeColor = color;
	drawingManager.set('polylineOptions', polylineOptions);

	var rectangleOptions = drawingManager.get('rectangleOptions');
	rectangleOptions.fillColor = color;
	drawingManager.set('rectangleOptions', rectangleOptions);

	var circleOptions = drawingManager.get('circleOptions');
	circleOptions.fillColor = color;
	drawingManager.set('circleOptions', circleOptions);

	var polygonOptions = drawingManager.get('polygonOptions');
	polygonOptions.fillColor = color;
	drawingManager.set('polygonOptions', polygonOptions);
}

function setSelectedShapeColor(color) {
	if (selectedShape) {
		if (selectedShape.type == window.google.maps.drawing.OverlayType.POLYLINE) {
			selectedShape.set('strokeColor', color);
		} else {
			selectedShape.set('fillColor', color);
		}
	}
}

function makeColorButton(color) {
	var button = document.createElement('span');
	button.className = 'color-button';
	button.style.backgroundColor = color;
	window.google.maps.event.addDomListener(button, 'click', function () {
		selectColor(color);
		setSelectedShapeColor(color);
	});

	return button;
}

function buildColorPalette() {
	var colorPalette = document.getElementById('color-palette');
	for (var i = 0; i < colors.length; ++i) {
		var currColor = colors[i];
		var colorButton = makeColorButton(currColor);
		colorPalette.appendChild(colorButton);
		colorButtons[currColor] = colorButton;
	}
	selectColor(colors[0]);
}

function getSelectedShape(coords) {
	var lt = []
	//console.log("coordsLatlngs ",coords);
	for (let value of Object.values(coords)) {
		//console.log("Val is ",value); // John, then 30
		lt.push(value)
	}
	//console.log("Combine ",lt);
	document.getElementById('coords').value = lt;
	//return lt;
}
