/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */


import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import UpdateButton from "./updatelmstates";
import UpdateTransitButton from "./updatetransitbutton";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import ShowTPTGpsMap from "../tptlogin/showtptgpsmap";
import DrawMap from '../common/drawmap';

import $ from 'jquery';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenDDMMYYYY } from '../common/utils'; 
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var alertIcon_50 = require("../../assets/icons/accident alert_16.jpeg");
var redirectURL = require('../redirectURL');
var moment = require('moment');
var CELL_DIMENSION_SIZE = 90;
var googleAnalytics = require("../common/googleanalytics");
var axios = require('axios');


export default class ConsignerMaster extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			pageTitle: "Transporter GPS Overspeed",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			showform:'show-m',
			loadshow:'show-n',
			showrefresh:'show-n',
			sliderTranslate:'',
			showdata:this.displayData,
			modules: AllModules,
        	radius:'15',
			rlatitude:'',
			rlongitude:'',
			latitude:'',
			longitude:'',
			defaultradius:0,
			coordinates:[],
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			contentString:"testing",
			mwidth:"col-xl-12 col-lg-12",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
				//editable: true,
				resizable: true
			},
			rowData: [],
			truckList: [],
			originalrowData: [],
			selectedTabLat:'',
			selectedTabLng:'',
			cluster_centroids: [],
			deftab:'all',
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
			context: { componentParent: this },
			frameworkComponents : {
				showtptgpsmap:ShowTPTGpsMap,
                UpdateButton:UpdateButton,
                UpdateTransitButton:UpdateTransitButton,
            },
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,
		
			showPlant:'show-n',
			showAddr:'show-n',
			request_type:'',
			view_type:'',
			tabplants:[],
			clickabletab:'',
			plantlist:[],
			stateFormData : "",
			sliderStateEmailsTranslate : "",
			overly : "show-n",
            rakeslist : [],
            loadingpoints:[],
            destinationpoints:[],
            userType:"",
            pinnedRegionBottomRowData:[],
            pinnedTransitBottomRowData:[],
            stockData:[],
            totalStock:0,
            totalSales:0,
            totalDispatches:0,
            railShare:0,
            totalFySales:0,
            totalFyDispatches:0,
            railFyShare:0,
            pendingForDispatchStock:0,
            transitStock:0,
            unloadingUpdatedDate:"",
            intransitUpdatedDate:"",
            screenView: "",
            todayInstalled: [],
            totalInstalled: [],
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			filteredData: [],
			truckNoList:[],
			showTrucksList : "show-n",
			startDate:'',
			endDate:'',
			truckNos:[],
			mapinfo:[],
            // duration:"",
			sliderRouteTranslate:'',
			tpttrucks:[],
            truck_no:[],
            tpt_code:[],
            truck_no_option:[],
            tpt_code_option:[],
            lastElement:"",
            lastNumbers:0,
            allData:[],
            page_no:0,
            alrData:0,
            total_count:[],
            latitude:"",
            longitude:""
            // from_date: moment.parseZone().subtract(5,'days').format("YYYY-MM-DD"),
            // to_date: moment.parseZone().format("YYYY-MM-DD"),
		};
	}
	/*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
        
        
    }
    onChangeDuration(duration){
		this.setState(
			{ duration },
			// () => console.log(`duration Option selected:`, this.state.duration)
		);
	}
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
    componentDidMount(){
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
       this.onLoadPageData()
    }
    onLoadPageData =()=>{
        redirectURL.post("/dashboard/getinitialDataforconsignermaster").then((response)=>{
            this.setState({
                rowData:response.data,
                overly:"show-n",
                loadshow:"show-n"
            })
        })
    }
   

	onClickHideAll(){
		this.setState({
			overly : "show-n",
			sliderRso:'',
            latitude:'',
            longitude:''
		})
        $("#mine_name").val('')
        $("#address").val('')
        $("#consigner_state").val('')
        $("#distance_from_plant").val('')
	}
	
    onGridReady = params => {
		this.gridApi = params.api;
		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;
    };
    onClickShowForm1() {
		this.setState({
			overly: "show-m",
			sliderRso:"slider-translate",
		});
	}
    changeHandler = (event) => {
        console.log("Select", event.target.value);
    
        var name = event.target.name;
        var value = event.target.value;
        const re = /^[A-Za-z]+$/;
        // if (name == 'deptName') {
        //     if (value === "" || re.test(value)) {
        //         console.log("name succ")
        //         alert('hi')
        //         //   this.setState(value);
        //     }else{
        //         alert('dont enter the numerals')
        //     }
        // }
    
        console.log(name);
    }
    changeHandler1 = (event) => {
        console.log("Select", event.target.value);
    
        var name = event.target.name;
        var value = event.target.value;
        const re = /^[A-Za-z]+$/;
        // if (name == 'deptName') {
        //     if (value === "" || re.test(value)) {
        //         console.log("name succ")
        //         alert('hi')
        //         //   this.setState(value);
        //     }else{
        //         alert('dont enter the numerals')
        //     }
        // }
    
        console.log(name);
    }
    validatelatitudeInput = (event) => {
        const inputValue = event.target.value.trim();
        const floatRegex = /^\d{1,}(\.\d{0,20})?$/;
        if(inputValue !=undefined && inputValue !="")
        {
            if (!floatRegex.test(inputValue)) {
            
            } else {
            this.setState({
                latitude: inputValue,
            });
            }
        }else{
            this.setState({
                latitude: "",
            });
        }
      };
      validatelongitudeInput = (event) => {
        const inputValue = event.target.value.trim();
        const floatRegex = /^\d{1,}(\.\d{0,20})?$/;
        if(inputValue !=undefined && inputValue !="")
        {
            if (!floatRegex.test(inputValue)) {
            
            } else {
                this.setState({
                    longitude: inputValue,
                });
            }
        }
        else
        {
            this.setState({
                longitude: "",
            });
        }
      };
    onSubmitDO=(e)=>{
        this.setState({
            overly:"show-m",
            loadshow:"show-m"
        })
        e.preventDefault()
        var mine_name = $("#mine_name").val()
        var address = $("#address").val()
        var consigner_state = $("#consigner_state").val()
        var latitude = this.state.latitude
        var longitude = this.state.longitude
        var distance_from_plant = $("#distance_from_plant").val()
        var params ={
            mine_name:mine_name,
            address:address,
            consigner_state:consigner_state,
            latitude:latitude,
            longitude:longitude,
            distance_from_plant:distance_from_plant
        }
        console.log(params,"354")
        redirectURL.post("/dashboard/storeConsignerData",params).then((response)=>{
            if(response.data.message == "success"){
                this.setState({
                    loadshow:"show-n",
                    overly:"show-n",
                    basicTitle:"Data Successfully Added",
                    basicType:"success",
                    show:true
                })
                this.onClickHideAll()
            }else{
                this.setState({
                    loadshow:"show-n",
                    overly:"show-n",
                    basicTitle:"Data Updating Failed",
                    basicType:"danger",
                    show:true
                })
                this.onClickHideAll()
            }

        })
    }
    render(){
		var columnWithDefs= [
            {
				headerName: "Mine Name",
				field: "consigner_name",
                width: 130,
                filter: true,
                resizable: true,
                sortable:true,
                editable:false
			},
            {
				headerName: "Mine Code",
				field: "consigner_code",
                width: 120,
                filter: true,
                resizable: true,
                sortable:true,
                editable:false
			},
            {
				headerName: "Address",
				field: "consigner_address",
                width: 250,
                filter: true,
                resizable: true,
                sortable:true,
                editable:false
			},
            {
				headerName: "Consigner State",
				field: "consigner_state",
                width: 130,
                filter: true,
                resizable: true,
                sortable:true,
                editable:false
			},
            {
				headerName: "Latitude",
				field: "consigner_lat",
                width: 140,
                filter: true,
                resizable: true,
                sortable:true,
                editable:false
            },
            {
				headerName: "Longitude",
				field: "consigner_lng",
                width: 140,
                filter: true,
                resizable: true,
                sortable:true,
                editable:false
			},
            {
				headerName: "Distance From Plant",
				field: "distance_from_plant",
                width: 150,
                filter: true,
                resizable: true,
                sortable:true,
                editable:false
            }
		];
		
        return (
            
    		<div className={(this.state.screenView == "Mobile")?"":"container-fluid"} style={(this.state.screenView == "Mobile")?{marginTop:"2em"}: {}}>
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
                >
                </SweetAlert>
                
                <div className="card">
                    <div className="card-header">
                        <h5>
							<i class="icofont icofont-vehicle-delivery-van cus-i"></i>
                        	Mine Field Master
                        </h5>
                        <button
                                    onClick={this.onClickShowForm1.bind(this)}
                                    style={{fontWeight: 'bold'}}
                                    className="btn btn-warning float-right"
                                >
                                    Add New Mine
                                </button>
                    </div>
                    <div className={(this.state.screenView == "Mobile")?"card-body":"card-body col-xl-12 col-lg-12"}>
                        {/* <p>Note* : Data can be fetched only for 5 days window</p> */}
                        <div id="myGrid" style={{width:"100%",height:"550px"}} className={"ag-theme-balham"}>    
                        <AgGridReact
                            modules={this.state.modules}
                            columnDefs={columnWithDefs}
                            defaultColDef={this.state.defaultColDef}
                            rowData={this.state.rowData}
                            enableCharts={false}
                            // autoGroupColumnDef={this.state.autoGroupColumnDef}
                            onGridReady={this.onGridReady}
                            onGridState={this.onGridState}
                            frameworkComponents={this.state.frameworkComponents}
                            statusBar={this.state.statusBar}
                            sideBar={this.state.sideBar}
                            stopEditingWhenGridLosesFocus= {true}
                            paginationPageSize={1000}
                            pagination={true}
                            gridOptions={{
                                context: { componentParent: this },
                            }}
                            // components={this.state.components}
                            enableRangeSelection= {true}
                            // onCellClicked={this.onCellClickedInTransit.bind(this)}
                            // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                            />
                        </div>
                        {/* <p className="float-right" style = {{fontWeight:"bold"}}> Showing Rows {this.state.alrData+" of "+this.state.total_data} </p>
                        <div className="row col-xl-12"  style ={{marginTop:"2%"}}>
                            <div className="col-lg-6">
                                {this.state.page_no > 0 ?
                                <button type="submit" onClick={this.onClickPreviousData.bind(this)} className="btn btn-success">Previous</button>
                                :""}
                            </div>
                            <div className="col-lg-6">
                                {this.state.rowData.length >= 1000?
                                <button type="submit" onClick={this.onClickNextData.bind(this)} className="btn btn-success float-right">Next</button>
                                :""}
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                <div className={"slide-r " + (this.state.sliderRso)} style={{ overflow: "auto", zIndex:"9999" }}>
					<div className="slide-r-title">
						<h4>
						Add Mine Details 
						<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>

						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div>
						<div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >

						<div className="theme-form col-xl-12 col-lg-12">

							<form action="" 
							onSubmit={this.onSubmitDO.bind(this)}
							>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Mine Name*</label>
                                    <input type="text" name="mine_name" id="mine_name" className="form-control alfa-cls" onChange={this.changeHandler} required />
                                </div>
								<div className="col-xl-12 col-lg-12 form-group">
                                            <label>Address*</label>
                                            <input type="text" name="address" id="address" className="form-control" onChange={this.changeHandler1} required />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label style={{paddingLeft:"0px"}} className="col-xl-12 col-lg-12">Consigner State*</label>
                                    <input type="text" name="consigner_state" id="consigner_state" className="form-control alfa-cls" onChange={this.changeHandler} required />
                                </div>
								<div className="col-xl-12 col-lg-12 form-group">
                                    <label>Latitude*</label>
                                    <input type="text" name="latitude" id="latitude"  className="form-control" value={this.state.latitude} onChange={this.validatelatitudeInput}
                                     required />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Longitude*</label>
                                    <input type="text" name="longitude" id="longitude"  className="form-control" value={this.state.longitude} onChange={this.validatelongitudeInput}
                                     required />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Distance from Plant*</label>
                                    <input type="number" name="distance_from_plant" id="distance_from_plant"  className="form-control" placeholder="" onChange={this.changeHandler} required />
                                </div>
								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
							</form>
						</div>
						</div>
					</div>
            	</div>
				{this.state.sliderRouteTranslate != ''?
					 <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								View Route
                                <span className="float-right closebtn" style={{marginRight:"25px"}} 
                                onClick={this.onClickHideAll.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							
							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                                <DrawMap 
                                    context={this} 
                                    mapFor={"consignment"} 
                                    mapinfo={this.state.mapinfo}  />
							</div>
                        </div>
                    </div>
                :""}
				
            </div>
              
        );
    }
}

$(document).on("input", ".alfa-cls", function () {
    this.value = this.value.replace(/[^a-z\s]+$/gi, '');
  });
  $(document).on("input", ".alfa-num-cls", function () {
    this.value = this.value.replace(/[^a-zA-Z0-9]*$/gi, '');
  });
// eslint-disable-next-line no-unused-vars
function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		format:'Y-m-d',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

$(document).on("change", "#srtoDate", function(e) {
    e.preventDefault();
    var bookingDate=$("#srfromDate").val();
    var cargoDate=$("#srtoDate").val();
    // var bookingDate = bookingDate.split("-")[2]+"-"+bookingDate.split("-")[1]+"-"+bookingDate.split("-")[0]
    // var cargoDate = cargoDate.split("-")[2]+"-"+cargoDate.split("-")[1]+"-"+cargoDate.split("-")[0]
    console.log()
    var startDate = new Date(bookingDate);
    var endDate = new Date(cargoDate);
    var startTime = startDate.getTime();
    var endTime = endDate.getTime();
    console.log(startTime,endTime);
    if(startTime > endTime)
    {
        $("#srtoDate").val("");
        alert("To Date should be greater than or equal to From Date");
    }
})

$(document).on("change", "#srfromDate", function(e) {
    e.preventDefault();
    var bookingDate=$("#srfromDate").val();
    var cargoDate=$("#srtoDate").val();
    // var bookingDate = bookingDate.split("-")[2]+"-"+bookingDate.split("-")[1]+"-"+bookingDate.split("-")[0]
    // var cargoDate = cargoDate.split("-")[2]+"-"+cargoDate.split("-")[1]+"-"+cargoDate.split("-")[0]

    var startDate = new Date(bookingDate);
    var endDate = new Date(cargoDate);
    var startTime = startDate.getTime();
    var endTime = endDate.getTime();
    console.log(startTime,endTime);
    if(startTime > endTime)
    {
        $("#srfromDate").val("");
        alert("From Date should be less than equal to To Date");
    }
})

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd hh:mm",
        changeMonth: true,
        changeYear: true,
        timepicker: true,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};


function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}
function secondsToHM(seconds) {
    seconds = Number(seconds);
    // var d = Math.floor(seconds / (3600*24));
    // var h = Math.floor(seconds % (3600*24) / 3600);
    // var m = Math.floor(seconds % 3600 / 60);
    // var s = Math.floor(seconds % 60);
    
    // var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    // var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    // var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    // return dDisplay + hDisplay + mDisplay;
    
    var hh = Math.floor((seconds / 3600));
    var hrr = Math.floor(seconds - (hh*3600));
    var mm = Math.floor(hrr/60)
    if(mm < 10)
    {
        var mins = "0"+mm;
    }
    else
    {
        var mins = mm;
    }

    if(hh < 10)
    {
        var hrs = "0"+hh;
    }
    else
    {
        var hrs = hh;
    }
    return hrs+":"+mins;
}
function dateDifferenceInHHMM(date1, date2) {
    if(date2 !== 0)
    {
        const diff = moment.utc(moment(date2).diff(moment(date1)));
        return diff.format("HH:mm");
    }
    else{
        return "";
    }
}