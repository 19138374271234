/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import CountUp from 'react-countup';
import axios from 'axios';
import DisconnectSvgIcon from '../manage/DisconnectSvgIcon';
var redirectURL = require('../redirectURL');

export default class ConsignmentCountersComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            records: [],
            active_consignments: 0,
            overspeeding: 0,
            night_driving: 0,
            transit_delay: 0,
            suspicious_stoppages: 0,
            unloading_dealer: 0,
            left_dealer: 0,
            no_gps_data_available: 0,
            gps_mains_removed:0,
            nogpslifetime: 0,
            is_critical: 0,
            is_priority: 0,
            deptcode: this.props.deptcode,
            triptype: this.props.triptype,
            active_trucks: 0,
            loading_delay: 0,
            unloading_delay: 0,
            enroute_stoppage: 0,
            probableAccident: 0,
            hidecol: true,
            probableAccidentconfirm: 0,
            cancelledCount: 0,
            pod_count: 0,
            trip_closed_by_pod_count: 0,
            returnConsignments: 0,
            counttype: "all",
            truckReportedDate: "",
            deviated_routes: 0,
            withoutTruckGateOutTimeData: [],
            forceClosed: 0,
            consignmentbtn: "btn-info",
            transitbtn:"",
            overspeedbtn: "",
            stoppagebtn: "",
            suspicious_stoppagebtn: "",
            suspiciousIcon: "",
            deviationbtn: "",
            gpsBtn: "",
            transitIcon:"darkorange",
            overspeedIcon: "txt-danger",
            stoppageIcon: "maroon",
            deviationIcon: "",
            gpsIcon: ""
        }

    }
    componentWillReceiveProps = async (newprops) => {

        if (newprops.deptcode != this.state.deptcode) {
            console.log("Counter Props ", newprops)
            if (newprops.triptype && newprops.triptype != 0) {
                var layoutdata = {
                    token: localStorage.getItem('token'),
                    dept_code: newprops.deptcode,
                    trip_type: newprops.triptype
                }
            }
            else {
                var layoutdata = {
                    token: localStorage.getItem('token'),
                    dept_code: newprops.deptcode
                }
            }

            /*Venkatesh Code*/
            var transitdelaysarr = [];
            var gpsdatanaarr = [];
            var overspeedtrucksarr = [];
            var nightdrivearr = [];
            var enroutearr = [];
            var loadingarr = [];
            var unloadingarr = [];
            var iscriticalarr = [];
            //console.log("newprops.gridData ", newprops.gridData)
            if (newprops.gridData.length > 0) {

                /*New Code for Counters*/
                var counter = newprops.countersjson;
                console.log(counter, "counter_data");
                let withoutTruckGateOutTimeData = 0
                var allConsignmentData = 0;
                var overspdcnt = 0;
                var nightdrvcnt = 0;
                var transdly = 0;
                var npgs = 0;
                var susstp = 0
                var nogpslifetime = 0;
                var lodelay = 0;
                var unlodelay = 0;
                var enrote = 0;
                var critical = 0;
                var priority = 0;
                var probableAccident = 0;
                var probableAccidentConfirmCount = 0;
                var cancelledCount = 0;
                var pod_count = counter.pod_received_data;
                var trip_closed_by_pod_count = counter.trip_closed_by_pod;
                var forceClosed = 0;
                var gps_mains_removed_count = 0;
                var deviated_routes_count = 0;

                if(this.state.consignmentbtn == "" && (newprops.gpsWireDiconnected || newprops.suspiDetected)){
                    this.resetCountersHandler();
                }
                if(newprops.filterClicked){
                    if(this.state.consignmentbtn == ""){
                        this.resetCountersHandler();
                    }
                    newprops.context.resetFilterHandler(false);
                }
                
                if (counter.cancelled_count != "Not Applicable") {
                    cancelledCount = counter.cancelled_count
                }
                if (counter.overspeeding.length > 0) {
                    var overspdcnt = counter.overspeeding[0].overspeeding_exception_count;
                }
                try {
                    if (counter.probableAccident.length > 0) {
                        var probableAccident = counter.probableAccident[0].probableAccidentCount;
                    }
                } catch (e) { }

                try {
                    if (counter.probableAccidentConfirm.length > 0) {
                        var probableAccidentConfirmCount = counter.probableAccidentConfirm[0].probableAccidentConfirmCount;
                    }
                } catch (e) { }
                try {
                    if (counter.criticalCount.length > 0) {
                        critical = counter.criticalCount[0].criticalCount;
                    }
                }
                catch (e) { }
                try {
                    if (counter.priorityCount.length > 0) {
                        priority = counter.priorityCount[0].priorityCount;
                    }
                }
                catch (e) { }
                if (counter.night_drive.length > 0) {
                    var nightdrvcnt = counter.night_drive[0].nightdriving_exception_count;
                }
                try {
                    if (counter.forceClosed.length > 0) {
                        var forceClosed = counter.forceClosed[0].forceClosedCount;
                    }
                } catch (e) { }
                if (counter.transit_delay.length > 0) {
                    var transdly = counter.transit_delay[0].transit_delay_count;
                }

                if (counter.suspicious_stoppages !== undefined && counter.suspicious_stoppages.length > 0) {
                    var susstp = counter.suspicious_stoppages[0].suspicious_stoppage_alert;
                }

                if (counter.no_gps.length > 0) {
                    var npgs = counter.no_gps[0].no_gps_data_count;
                }
                if(counter.gps_mains_removed && 
                    counter.gps_mains_removed.length > 0){
                        
                     gps_mains_removed_count = counter.gps_mains_removed[0].gps_mains_removed_count
                }
                //console.log("newprops.hide ",newprops.hide)
                // if(newprops.hide == true)
                // {
                try {
                    if (counter.nogpslifetime.length > 0) {
                        var nogpslifetime = counter.nogpslifetime[0].no_gps_data_lifetime_count;
                    }
                } catch (e) { }
                // }
                if (counter.loading_delay.length > 0) {
                    var lodelay = counter.loading_delay[0].loading_delay_exception_count;
                }

                if (counter.unloading_delay.length > 0) {
                    var unlodelay = counter.unloading_delay[0].unloading_delay_exception_count;
                }

                // if (counter && counter.totalStoppagesCount && counter.totalStoppagesCount.length > 0) {
                //     var enrote = counter.totalStoppagesCount.length;
                //     console.log("enrote", enrote)
                // }
                
                if (counter && counter.enroute_stoppage
                     && counter.enroute_stoppage.length > 0) {
                     enrote = counter.enroute_stoppage[0].stoppage_alert_count;
                }
                if (counter && counter.deviated_routes
                    && counter.deviated_routes.length > 0) {
                    deviated_routes_count = counter.deviated_routes[0].deviated_routes_count;
               }
                let grid_data = []
                if (this.props.path == "/prtdeliveredconsignments") {
                    grid_data = newprops.gridData.filter(item => item.status !== 6)
                } else {
                    grid_data = newprops.gridData
                    console.log(newprops.gridData, "newprops.gridData")
                    var req = newprops.gridData.map(item => {
                        if ((item.consignment_code == "1709067913039195") == true){
                            return item.consignment_code 
                        }
                    })
                    console.log(req, "newreq");
                }
                //console.log("nogpslifetime ",nogpslifetime)
                await this.setState({
                    active_consignments: grid_data.length,
                    overspeeding: overspdcnt,
                    is_critical: critical,
                    is_priority: priority,
                    night_driving: nightdrvcnt,
                    transit_delay: transdly,
                    suspicious_stoppages: susstp,
                    no_gps_data_available: npgs,
                    nogpslifetime: nogpslifetime,
                    loading_delay: lodelay,
                    unloading_delay: unlodelay,
                    enroute_stoppage: enrote,
                    probableAccident: probableAccident,
                    probableAccidentconfirm: probableAccidentConfirmCount,
                    cancelledCount: cancelledCount,
                    pod_count: pod_count,
                    trip_closed_by_pod_count: trip_closed_by_pod_count,
                    returnConsignments: newprops.returnConsignments,
                    counttype: newprops.counttype,
                    deviated_routes: deviated_routes_count,
                    withoutTruckGateOutTimeData: newprops.withoutTruckGateOutTimeData.length,
                    gps_mains_removed : gps_mains_removed_count,
                    forceClosed
                }, () => {
                    console.log(this.state.enroute_stoppage, "191");
                    console.log(this.state.active_consignments, "192")
                });
            }
            else {
                await this.setState({
                    active_consignments: 0,
                    overspeeding: 0,
                    night_driving: 0,
                    transit_delay: 0,
                    is_critical: 0,
                    is_priority: 0,
                    //unloading_dealer:counterdata.unloading_dealer,
                    //left_dealer:counterdata.left_dealer,
                    no_gps_data_available: 0,
                    nogpslifetime: 0,
                    suspicious_stoppages: 0,
                    //active_trucks:counterdata.active_trucks,
                    loading_delay: 0,
                    unloading_delay: 0,
                    enroute_stoppage: 0,
                    probableAccident: 0,
                    probableAccidentconfirm: 0,
                    gps_mains_removed:0,
                    returnConsignments: newprops.returnConsignments,
                    deviated_routes: 0,
                });
            }
            this.setState({
                hidecol: newprops.hide,
                truckReportedDate: this.props.truckReportedDate
            });
        }


    }
    resetCountersHandler = () => {
        this.setState({
            consignmentbtn: "btn-info",
            transitbtn:"",
            overspeedbtn: "",
            stoppagebtn: "",
            suspicious_stoppagebtn: "",
            deviationbtn: "",
            gpsBtn: "",
            consignmentIcon: "",
            transitIcon:"darkorange",
            overspeedIcon: "txt-danger",        
            stoppageIcon: "maroon",
            deviationIcon: "",
            suspiciousIcon: "",
            gpsIcon: ""
        })
    }

    onClickCounter(data) {
        this.props.context.onClickCounterShowData(data)
        if (data == "all") {
            this.setState({
                consignmentbtn: "btn-info",
                transitbtn:"",
                overspeedbtn: "",
                stoppagebtn: "",
                suspicious_stoppagebtn: "",
                deviationbtn: "",
                gpsBtn: "",
                consignmentIcon: "",
                transitIcon:"darkorange",
                overspeedIcon: "txt-danger",
                stoppageIcon: "maroon",
                deviationIcon: "",
                suspiciousIcon: "",
                gpsIcon: ""
            })
        }
        if (data == "transit_delay") {
            this.setState({
                consignmentbtn: "",
                transitbtn:"btn-info",
                overspeedbtn: "",
                stoppagebtn: "",
                suspicious_stoppagebtn: "",
                deviationbtn: "",
                gpsBtn: "",
                consignmentIcon: "",
                transitIcon:"whitefont",
                overspeedIcon: "txt-danger",
                stoppageIcon: "maroon",
                deviationIcon: "",
                suspiciousIcon: "",
                gpsIcon: ""
            })
        }
        if (data == "overspeeding_exception") {
            this.setState({
                consignmentbtn: "",
                transitbtn:"",
                overspeedbtn: "btn-info",
                stoppagebtn: "",
                suspicious_stoppagebtn: "",
                deviationbtn: "",
                gpsBtn: "",
                transitIcon:"darkorange",
                consignmentIcon: "",
                overspeedIcon: "whitefont",
                stoppageIcon: "maroon",
                deviationIcon: "",
                suspiciousIcon: "",
                gpsIcon: ""
            })
        }
        /**
         * Developer Name: Siva G
         * Bug NO: 3085
         * Description: To make only stoppage kpi counter active.
         ***/
        if (data == "stoppages") {
            this.setState({
                consignmentbtn: "",
                transitbtn:"",
                overspeedbtn: "",
                stoppagebtn: "btn-info",
                suspicious_stoppagebtn: "", 
                deviationbtn: "",
                gpsBtn: "",
                consignmentIcon: "",
                transitIcon:"darkorange",
                overspeedIcon: "txt-danger",
                stoppageIcon: "whitefont",
                deviationIcon: "",
                suspiciousIcon: "",
                gpsIcon: ""
            })
        }
        if (data == "suspicious_stoppages") {
            this.setState({
                consignmentbtn: "",
                overspeedbtn: "",
                transitbtn:"",
                stoppagebtn: "",
                deviationbtn: "",
                gpsBtn: "",
                suspicious_stoppagebtn: "btn-info",
                consignmentIcon: "",
                transitIcon:"darkorange",
                overspeedIcon: "txt-danger",
                stoppageIcon: "maroon",
                deviationIcon: "",
                suspiciousIcon: "active",
                gpsIcon: ""
            })
        }
        if (data == "detour") {
            this.setState({
                consignmentbtn: "",
                overspeedbtn: "",
                transitbtn:"",
                stoppagebtn: "",
                deviationbtn: "btn-info",
                gpsBtn: "",
                suspicious_stoppagebtn: "",
                consignmentIcon: "",
                transitIcon:"darkorange",
                overspeedIcon: "txt-danger",
                stoppageIcon: "maroon",
                deviationIcon: "active",
                suspiciousIcon: "",
                gpsIcon: ""
            })
        }
        if (data == "gps_mains_removed") {
            this.setState({
                consignmentbtn: "",
                overspeedbtn: "",
                transitbtn: "",
                stoppagebtn: "",
                deviationbtn: "",
                suspicious_stoppagebtn: "",
                gpsBtn: "btn-info",
                consignmentIcon: "",
                transitIcon: "darkorange",
                overspeedIcon: "txt-danger",
                stoppageIcon: "maroon",
                deviationIcon: "",
                suspiciousIcon: "",
                gpsIcon: "whitefont"
            })
        }
    }
    render() {
        console.log(this.state.suspicious_stoppages, "j555");
        var activeshow = (this.state.counttype == 'all' || this.state.counttype == 'active') ? 'show-m' : 'show-n'
        // console.log("ths.state", this.state.deptcode, '\n this.prop', this.props)

        return (
            // <div className="row">
            <div className="col-12 col-xl-12 col-lg-12 col-sm-12 beffect">
                {(this.state.returnConsignments === false) ? (
                    <div className="row counters-row">

                        <div className="counter-column" onClick={this.onClickCounter.bind(this, "all")}>
                            <div className="o-hidden card inner-con">
                                <div className={"bg b-r-4 card-body p-3 " + this.state.consignmentbtn}>
                                    <div className="media static-top-widget d-flex">
                                        <div className="align-self-center text-center">
                                            <i className={`icofont icofont-truck f32 blackfont`}></i>
                                        </div>
                                        <div className="media-body" style={{ paddingLeft: 0 }}>
                                            <h4 className="counter mb-0 f28 d-flex justify-content-center" style={{ fontFamily: "Poppins-Regular" }}>
                                                <CountUp end={(this.state.active_consignments != '') ? this.state.active_consignments : 0} />
                                            </h4>
                                            {/**
                                             * Developer Name: Siva G
                                             * Bug NO: 3078
                                             * Description: Making all counter sizes same.
                                             ***/}
                                            <span className="m-0 d-flex justify-content-center" style={{minWidth:"100px", minHeight: "40px"}}>Consignments</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="counter-column" onClick={this.onClickCounter.bind(this, "transit_delay")}>
                            <div class="o-hidden card inner-con">
                                <div class={"bg b-r-4 card-body p-3 " + this.state.transitbtn}>
                                    <div class="media static-top-widget d-flex">
                                        <div class="align-self-center text-center">
                                            <i className={"icofont icofont-clock-time f32 " + this.state.transitIcon}></i>
                                        </div>
                                        <div class="media-body" style={{ paddingLeft: 0 }}>
                                                <h4 class="counter mb-0 f28 d-flex justify-content-center" style={{ fontFamily: "Poppins-Regular" }}><span>
                                                    <CountUp end={(this.state.transit_delay != '') ? this.state.transit_delay : 0} />
                                                </span></h4>
                                                {/**
                                                 * Developer Name: Siva G
                                                 * Bug NO: 3078
                                                 * Description: Making all counter sizes same.
                                                 ***/}
                                                <span class="m-0 d-flex justify-content-center" style={{minWidth:"100px", minHeight: "40px"}}>Transit Delays</span>
                                        </div>
                                </div>
                                </div>
                            </div>
                        </div>

                        <div className="counter-column" onClick={this.onClickCounter.bind(this, "gps_mains_removed")}>
                            <div class="o-hidden card inner-con">
                                <div class={"bg b-r-4 card-body p-3 " + this.state.gpsBtn}>
                                    <div class="media static-top-widget d-flex">
                                        <div class="align-self-center text-center">
                                            <DisconnectSvgIcon 
                                                height="32" 
                                                width="32" 
                                                color={this.state.gpsIcon ? "#fff":"#bd0a41" }
                                            />
                                        </div>
                                        <div class="media-body" style={{ paddingLeft: 0 }}>
                                            <h4 class="counter mb-0 f28 d-flex justify-content-center" style={{ fontFamily: "Poppins-Regular" }}><span>
                                                <CountUp end={(this.state.gps_mains_removed != '') ? this.state.gps_mains_removed : 0} />
                                            </span></h4>
                                            {/**
                                             * Developer Name: Siva G
                                             * Bug NO: 3078
                                             * Description: Making all counter sizes same.
                                             ***/}
                                            <span class="m-0 d-flex justify-content-center" style={{textAlign:"center",minWidth:"100px", minHeight: "40px"}}>GPS Wire<br/>Disconnection</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="counter-column" onClick={this.onClickCounter.bind(this, "overspeeding_exception")}>
                            <div class="o-hidden card inner-con">
                                <div class={"bg b-r-4 card-body p-3 " + this.state.overspeedbtn}>
                                    <div class="media static-top-widget d-flex">
                                        <div class="align-self-center text-center">
                                            <i className={"fa fa-tachometer f32 " + this.state.overspeedIcon}></i>
                                        </div>
                                        <div class="media-body" style={{ paddingLeft: 0 }}>
                                            <h4 class="counter mb-0 f28 d-flex justify-content-center" style={{ fontFamily: "Poppins-Regular" }}><span>
                                                <CountUp end={(this.state.overspeeding != '') ? this.state.overspeeding : 0} />
                                            </span></h4>
                                            {/**
                                             * Developer Name: Siva G
                                             * Bug NO: 3078
                                             * Description: Making all counter sizes same.
                                             ***/}
                                            <span class="m-0 d-flex justify-content-center" style={{minWidth:"100px", minHeight: "40px"}}>Overspeeding</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="counter-column" onClick={this.onClickCounter.bind(this, "stoppages")}>

                            <div class="o-hidden card inner-con">
                                <div class={"bg b-r-4 card-body p-3 " + this.state.stoppagebtn}>
                                    <div class="media static-top-widget d-flex">
                                        <div class="align-self-center text-center">
                                            <i className={"icofont icofont-pause f32 " + this.state.stoppageIcon}></i>
                                        </div>
                                        <div class="media-body" style={{ paddingLeft: 0 }}>
                                            <h4 class="counter mb-0 f28 d-flex justify-content-center" style={{ fontFamily: "Poppins-Regular" }}><span>
                                                <CountUp end={this.state.enroute_stoppage ? this.state.enroute_stoppage : 0} />
                                            </span></h4>
                                            {/**
                                             * Developer Name: Siva G
                                             * Bug NO: 3078
                                             * Description: Making all counter sizes same.
                                             ***/}
                                            <span class="m-0 d-flex justify-content-center" style={{minWidth:"100px", minHeight: "40px"}}>Stoppages</span>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="counter-column" onClick={this.onClickCounter.bind(this, "detour")}>

                            <div class="o-hidden card inner-con">
                                <div class={"bg b-r-4 card-body p-3 " + this.state.deviationbtn}>
                                    <div class="media static-top-widget d-flex">
                                        <div class="align-self-center text-center">
                                            <img src={require("../../assets/icons/deviation.png")} style={{ width: "32px", filter: this.state.deviationIcon == "active" ? "invert(1)" : "invert(20%) sepia(100%) saturate(7500%) hue-rotate(0deg) brightness(100%) contrast(100%)" }}></img>
                                        </div>
                                        <div class="media-body" style={{ paddingLeft: 0 }}>
                                            <h4 class="counter mb-0 f28 d-flex justify-content-center" style={{ fontFamily: "Poppins-Regular" }}><span>
                                                <CountUp end={(this.state.deviated_routes != '') ? this.state.deviated_routes : 0} />
                                            </span></h4>
                                            {/**
                                             * Developer Name: Siva G
                                             * Bug NO: 3078
                                             * Description: Making all counter sizes same.
                                             ***/}
                                            <span class="m-0 d-flex justify-content-center" style={{minWidth:"100px", minHeight: "40px"}}>Deviations</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="counter-column" onClick={this.onClickCounter.bind(this, "suspicious_stoppages")}>
                            <div class="o-hidden card inner-con">
                                <div class={"bg b-r-4 card-body p-3 " + this.state.suspicious_stoppagebtn}>
                                    <div class="media static-top-widget d-flex">
                                        <div class="align-self-center text-center">
                                            <img src={require("../../assets/icons/siren.png")} style={{ width: "32px", filter: this.state.suspiciousIcon == "active" ? "invert(1)" : "invert(20%) sepia(100%) saturate(7500%) hue-rotate(0deg) brightness(100%) contrast(100%)" }}></img>
                                        </div>
                                        <div class="media-body" style={{ paddingLeft: 0 }}>
                                            <h4 class="counter mb-0 f28 d-flex justify-content-center" style={{ fontFamily: "Poppins-Regular" }}><span>
                                                <CountUp end={(this.state.suspicious_stoppages != '') ? this.state.suspicious_stoppages : 0} />
                                            </span></h4>
                                            {/**
                                             * Developer Name: Siva G
                                             * Bug NO: 3078
                                             * Description: Making all counter sizes same.
                                             ***/}
                                            <span class="m-0 d-flex justify-content-center" style={{textAlign:"center",minWidth:"100px", minHeight: "40px"}}>Suspicious<br/>Stoppages</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        {/* Repeat for other counters */}
                    </div>
                ) : (
                    <div className="row">
                        <div className="col cirlce-d cursorPointer" onClick={this.onClickCounter.bind(this, "all")}>
                            <span className="f13"><i className="icofont icofont-ui-browser f24 greenfont"></i><br /> Consignments</span>
                            <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.active_consignments != '') ? this.state.active_consignments : 0} /></span></h4>

                        </div>

                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "transit_delay")}>
                            <span className="f13"><i className="icofont icofont-clock-time f22 redfont"></i><br /> Transit Delays</span>
                            <h4 className="txt-danger f40"><span className="counter"><CountUp end={(this.state.transit_delay != '') ? this.state.transit_delay : 0} /></span></h4>

                        </div>
                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "enroute_exception")}>
                            <span className="f13"><i className="icofont icofont-pause f22 maroon"></i><br /> Stoppages</span>
                            <h4 className="maroon f40"><span className="counter"><CountUp end={(this.state.enroute_stoppage != '') ? this.state.enroute_stoppage : 0} /></span></h4>
                        </div>

                        {/* <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"unloading_delay_exception")} >
                                    <span className="f13"><i className="icofont icofont-vehicle-delivery-van f24 mildgreen"></i><br /> Unloading Delay</span>
                                    <h4 className="mildgreen f40"><span className="counter"><CountUp end={(this.state.unloading_delay != '')?this.state.unloading_delay:0}/></span></h4>
                                </div>

                                {(this.props.counterScreen == "")?"":
                                    <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"is_critical")}>
                                        <span className="f13"><i className="icofont icofont-minus-circle f22 maroon"></i><br /> Critical</span>
                                        <h4 className="maroon f40"><span className="counter"><CountUp end={(this.state.is_critical != '')?this.state.is_critical:0}/></span></h4>
                                    </div>
                                } */}


                    </div>
                )}
            </div>
            // </div> 
                

        );
    }
}
