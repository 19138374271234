import React, { Component } from 'react';
import './createshipment.css'; // Assuming you create a CSS file for styling
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
var redirectURL = require('../redirectURL');


class CreateShipment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            trackingType: '',
            providerType: '',
            deviceId: '',
            truckNumber: '',
            driverMobile: '',
            doNumber: '',
            challanDate: '',
            consigneeName: '',
            consignerName: '',
            transporterName: '',
            deviceIdOp: [],
            truckNumberOp: [],
            doNumberOp: [],
            consigneeNameOp: [],
            transporterNameOp: [],
            consignerNameOp: [],
            savedTags: [],
            tagID: [],
            error: "",
            errors: {},
            show: false,
            trackingTypeOp: [{ "label": "GPS", "value": 1 }, { "label": "SIM", "value": 2 }, { "label": "SEALING", "value": 5 }],
            providerTypeOp: [{ "label": "JIO", "value": "jio" }, { "label": "NON-JIO", "value": "non_jio" }]
        };
    }

    handleChange = (event) => {
        console.log(event, "event")
        this.setState({ [event.target.name]: event.target.value, error: '' });
    };

    handleAddTag = () => {
        const { tagID, savedTags } = this.state;
        const trimmedTag = tagID.trim();


        if (trimmedTag === '') {
            this.setState({ error: 'Tag ID cannot be empty!' });
            this.setState({ tagID: '' });
            return;
        }

        if (savedTags.includes(trimmedTag)) {
            this.setState({ error: 'Tag ID already exists!' });
            this.setState({ tagID: '' });
            return;
        }


        this.setState(prevState => ({
            savedTags: [...prevState.savedTags, trimmedTag],
            tagID: '',
            error: '',
        }));
    };

    closeAlert = () => {
        this.setState({
            show: false
        });

    }

    // Handle deleting a tag
    handleDeleteTag = (tagToDelete) => {
        this.setState(prevState => ({
            savedTags: prevState.savedTags.filter(tag => tag !== tagToDelete)
        }));
    };

    validateForm = () => {
        const { trackingType, providerType, driverMobile, doNumber, truckNumber, consignerName, challanDate, consigneeName, transporterName, deviceId, savedTags } = this.state;
        const errors = [];
        var msg = ""
        if (!trackingType) {
            msg = 'Tracking type is required'
        }
        if (trackingType && trackingType.value === 2 && !driverMobile) {
            msg = 'Driver mobile number is required';
        }
        if (trackingType && trackingType.value === 2 && !providerType) {
            msg = 'Provider type is required';
        }

        if (!doNumber) {
            msg = 'DO number is required';
        }
        if (!truckNumber) {
            msg = 'Truck number is required';
        }

        if (!consignerName) {
            msg = 'Consignee name is required';
        }
        if (!challanDate) {
            msg = 'Challan date is required';
        }

        if (!consigneeName) {
            msg = 'Consignee name is required';
        }
        if (!transporterName) {
            msg = 'Transporter name is required';
        }

        if (trackingType && trackingType.value === 1 && !deviceId) {
            msg = 'Device id is required';
        }
        if (trackingType && trackingType.value === 5 && savedTags.length === 0) {
            msg = 'Tag Id is required';
        }

        if (msg.length > 0) {
            console.log("check msg", msg)
            this.setState({
                basicTitle: msg,
                basicType: "danger",
                show: true,
            })
            return false
        }
        return true
    };

    handleSubmit = (event) => {
        event.preventDefault();
        if (!this.validateForm()) {
            return;
        }
        console.log('Form submitted:', this.state);
        const challan_date_time = new Date(this.state.challanDate).toLocaleString('en-GB').replace(/\//g, '-').replace(',', '');
        var query = {
            do_number: this.state.doNumber.value || "",
            consigner_code: this.state.consignerName.value || "",
            consigner_name: this.state.consignerName.label || "",
            consignee_code: this.state.consigneeName.value || "",
            consignee_name: this.state.consigneeName.label || "",
            transporter_code: this.state.transporterName.value || "",
            transporter_name: this.state.transporterName.label || "",
            truck_no: this.state.truckNumber === "other" ? this.state.customTruckNumber || "" : this.state.truckNumber || "",
            device_id: this.state.deviceId.value || "",
            type_of_tracking: this.state.trackingType.value || 1,
            challan_date_time: challan_date_time || "",
            sim_tracking_service_provider_name: this.state.providerType.value || "jio",
            driver_mobileNo: this.state.driverMobile || "",
            tag_id: JSON.stringify(this.state.savedTags) || "",
            created_from_web: 1
        }
        if (this.state.trackingType && this.state.trackingType.value == 1) {
            query.is_device_mapping = 1
            query.email = localStorage.getItem('email')
            query.username = localStorage.getItem('username')
        }
        redirectURL.post("/dashboard/getConsignmentsDataForMobile", query)
            .then((resp) => {
                console.log(resp, "check_response")
                if (resp.data && resp.data.status == "success") {
                    this.setState({
                        basicTitle: "Successfully Created Consignment",
                        basicType: "success",
                        show: true,
                    })
                }
                else {
                    this.setState({
                        basicTitle: resp.data.message,
                        basicType: "success",
                        show: true,
                    })
                }
            })
        this.setState({
            providerType: "",
            trackingType: '',
            deviceId: "",
            truckNumber: '',
            driverMobile: '',
            doNumber: '',
            challanDate: '',
            consigneeName: '',
            consignerName: '',
            transporterName: '',
            savedTags: []
        });
    };


    componentDidMount() {
        try {
            redirectURL.post("/dashboard/getDevicesData", {})
                .then((resp) => {
                    let deviceOptions = []
                    resp.data.map((item) => {
                        deviceOptions.push({ "label": item.device_id, "value": item.device_id })
                    })
                    this.setState({
                        deviceIdOp: deviceOptions
                    });
                })

            redirectURL.post("/dashboard/getTrucksForMobile", {})
                .then((resp) => {
                    let truckOptions = []
                    resp.data.map((item) => {
                        truckOptions.push({ "label": item, "value": item })
                    })
                    this.setState({
                        truckNumberOp: truckOptions
                    }, () => {
                        console.log(this.state.truckNumberOp, "truckNumberOp")
                    });
                })

            redirectURL.post("/dashboard/plants", {})
                .then((resp) => {
                    let plants = []
                    resp.data.map((item) => {
                        plants.push({ "label": item.consigner_name, "value": item.consigner_code })
                    })
                    this.setState({
                        consignerNameOp: plants
                    });
                })

            redirectURL.post("dashboard/getConsigneeForMobile", {})
                .then((resp) => {
                    let consigneeOptions = []
                    resp.data.map((item) => {
                        consigneeOptions.push({ "label": item.consignee_name, "value": item.consignee_code })
                    })
                    this.setState({
                        consigneeNameOp: consigneeOptions
                    });
                })

            redirectURL.post("/transporters/list", {})
                .then((resp) => {
                    let transporterOptions = []
                    resp.data.map((item) => {
                        transporterOptions.push({ "label": item.transporter_name, "value": item.transporter_code })
                    })
                    this.setState({
                        transporterNameOp: transporterOptions
                    });
                })
        }
        catch (e) {
            console.log("error", e)
        }


    }

    render() {
        return (

            <div className="container-fluid bscreen">
                <h3 className="mt-3 text-left"><strong>Create Consignment</strong></h3>
                <div className="card1 mt-3">
                    <form method="POST" className="consignment-form1">
                        <div className="form-group1">
                            <label>Type of Tracking</label>
                            <Select
                                closeMenuOnSelect={true}
                                name="trackingType"
                                className="border-radius-0"
                                onChange={(event) => {
                                    this.setState({ trackingType: event })
                                }}
                                options={this.state.trackingTypeOp}
                                value={this.state.trackingType}
                                required
                            />
                        </div>
                        {/* {this.state.trackingType && this.state.trackingType.value == 2 ?  */}
                        <div className="form-group1">
                            <label>Service Provider</label>
                            <Select
                                closeMenuOnSelect={true}
                                name="providerType"
                                className="border-radius-0"
                                onChange={(event) => {
                                    this.setState({ providerType: event })
                                }}
                                options={this.state.providerTypeOp}
                                value={this.state.providerType}
                                required={this.state.trackingType && this.state.trackingType.value == 2}
                            />
                        </div>
                        {/* : ""} */}

                        {/* {this.state.trackingType && this.state.trackingType.value == 1 ?  */}
                        <div className="form-group1">
                            <label>Device ID</label>
                            <Select
                                closeMenuOnSelect={true}
                                name="deviceId"
                                className="border-radius-0"
                                onChange={(event) => {
                                    this.setState({ deviceId: event })
                                }}
                                options={this.state.deviceIdOp}
                                value={this.state.deviceId}
                                required={this.state.trackingType && this.state.trackingType.value == 1}
                            />
                        </div>
                        {/* : ""} */}


                        {/* <div className="form-group1">
                            <label>Truck Number</label>
                            <Select
                                closeMenuOnSelect={true}
                                name="truckNumber"
                                className="border-radius-0"
                                onChange={(event) => {
                                    this.setState({ truckNumber: event }, () => {
                                        console.log("truckNumber", this.state.truckNumber)
                                    })
                                }}
                                options={this.state.truckNumberOp}
                                value={this.state.truckNumber}
                                required
                            />
                        </div> */}
                        <div className="form-group1">
                            <label>Truck Number</label>
                            <Select
                                closeMenuOnSelect={true}
                                name="truckNumber"
                                className="border-radius-0"
                                onChange={(event) => {
                                    // Check if the selected value is "Other"
                                    const selectedValue = event ? event.value : "";
                                    this.setState({ truckNumber: selectedValue });
                                }}
                                options={[...this.state.truckNumberOp, { value: "other", label: "Other" }]} // Add "Other" option
                                value={this.state.truckNumber ? { value: this.state.truckNumber, label: this.state.truckNumber } : null}
                                required
                            />
                        </div>


                        {this.state.truckNumber === "other" && (
                            <div className="custom-truck-number-input">
                                <label>Enter Your Truck Number</label>
                                <input
                                    type="text"
                                    name="customTruckNumber"
                                    value={this.state.customTruckNumber || ""}
                                    onChange={(e) => this.setState({ customTruckNumber: e.target.value })}
                                    placeholder="Enter truck number"
                                />
                            </div>
                        )}



                        <div className="form-group1">
                            <label>Consigner Name</label>
                            <Select
                                closeMenuOnSelect={true}
                                name="consignerName"
                                className="border-radius-0"
                                onChange={(event) => {
                                    this.setState({ consignerName: event }, () => {
                                        redirectURL.post("/dashboard/getDoNumbersForMobile", { consigner_code: this.state.consignerName.value })
                                            .then((resp) => {
                                                let doOptions = []
                                                resp.data.map((item) => {
                                                    doOptions.push({ "label": item, "value": item })
                                                })
                                                this.setState({
                                                    doNumberOp: doOptions
                                                });
                                            })
                                    })
                                }}
                                options={this.state.consignerNameOp}
                                value={this.state.consignerName}
                                required
                            />
                        </div>

                        {/* {this.state.trackingType && this.state.trackingType.value == 2 ? */}
                        <div className="form-group1">
                            <label>Driver Mobile Number</label>
                            <input
                                type="tel"
                                name="driverMobile"
                                value={this.state.driverMobile}
                                onChange={this.handleChange}
                                required={this.state.trackingType && this.state.trackingType.value == 2}
                            />
                        </div>
                        {/* : ""} */}

                        <div className="form-group1">
                            <label>DO Number</label>
                            <Select
                                closeMenuOnSelect={true}
                                name="doNumber"
                                className="border-radius-0"
                                onChange={(event) => {
                                    this.setState({ doNumber: event })
                                }}
                                options={this.state.doNumberOp}
                                value={this.state.doNumber}
                                required
                            />
                        </div>

                        <div className="form-group1">
                            <label>Challan Date/Time</label>
                            <input
                                type="datetime-local"
                                name="challanDate"
                                value={this.state.challanDate}
                                onChange={this.handleChange}
                                required
                            />
                        </div>

                        <div className="form-group1">
                            <label>Consignee Name</label>
                            <Select
                                closeMenuOnSelect={true}
                                name="consigneeName"
                                className="border-radius-0"
                                onChange={(event) => {
                                    this.setState({ consigneeName: event })
                                }}
                                options={this.state.consigneeNameOp}
                                value={this.state.consigneeName}
                                required
                            />
                        </div>

                        <div className="form-group1">
                            <label>Transporter Name</label>
                            <Select
                                closeMenuOnSelect={true}
                                name="transporterName"
                                className="border-radius-0"
                                onChange={(event) => {
                                    this.setState({ transporterName: event })
                                }}
                                options={this.state.transporterNameOp}
                                value={this.state.transporterName}
                                required
                            />
                        </div>
                        {/* {this.state.trackingType && this.state.trackingType.value == 5 ?  */}
                        <div className="form-group1">
                            <div className="d-flex flex-row align-items-center">
                                <div className="d-flex flex-column" style={{ marginRight: '10px' }}>
                                    <label>Enter Tag IDs:</label>
                                    <input
                                        type="text"
                                        name="tagID"
                                        value={this.state.tagID}
                                        onChange={this.handleChange}
                                        required={this.state.trackingType && this.state.trackingType.value == 5}
                                        placeholder="Enter tag ID"
                                        className="form-control"
                                    />
                                </div>

                                <button
                                    type="button"
                                    onClick={this.handleAddTag}
                                    className="btn btn-primary"
                                    style={{ padding: '5px 10px', marginTop: "25px", borderRadius: "2px" }}
                                >
                                    Add
                                </button>
                            </div>

                            {this.state.error && <p style={{ color: 'red' }}>{this.state.error}</p>}

                            <div>
                                <div style={{
                                    display: 'grid',
                                    gridTemplateColumns: 'repeat(5, 1fr)',
                                    gap: '10px',
                                    marginTop: '20px'
                                }}>
                                    {this.state.savedTags.map((tag, index) => (
                                        <div key={index} style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            padding: '5px',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            backgroundColor: '#f9f9f9'
                                        }}>
                                            {tag}
                                            <button
                                                type="button"
                                                onClick={() => this.handleDeleteTag(tag)}
                                                style={{
                                                    background: 'none',
                                                    border: 'none',
                                                    color: 'red',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                &#x2716;
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        {/* : ""} */}
                    </form>
                    <div className="form-group" style={{ textAlign: "center", marginTop: "4px" }}>
                        <button type="submit" onClick={this.handleSubmit} className="btn btn-success">Submit</button>
                    </div>
                    <div>
                        <p className="note-text">
                            <strong>Note:</strong> Consigner Name is mandatory before selecting the DO Number.
                        </p>
                    </div>

                </div>
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}>
                </SweetAlert>
            </div>
        );
    }
}

export default CreateShipment;
