/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from "lodash";
import $ from 'jquery';
import axios from 'axios';
import Grid from '../layouts/transporterComponent';
import Modal from 'react-responsive-modal';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenDDMMYYYY } from '../common/utils'; 
var moment = require('moment');
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");



export default class AddCoalmine extends Component {

	constructor(props){
		super(props);
		this.state={
			departmentCode: null,
			currentDepartmentTitle: null,
			rowData:null,
			uploadDivWidth:'0%',
			sliderTranslate:'',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			pagetitle:"",
			usermanualmodal:false,
			overly: "show-n",
			sliderTrucksTranslate: "",
			loadshow:'show-n',
		}
	}
	getCurrentDepartmentCode(pathToMatch = null) {
		let department = null;
		let departmentName = null;
		switch(pathToMatch) {
			case "/manage/sndtransporters":
				department='IBL';
				departmentName = " (Inbound Logistics) ";
				break;
			case "/manage/prttransporters":
				department='LOG-PRT';
				departmentName = " (Spare Parts) ";
				break;
			case "/manage/tnptransporters":
				department='LOG-TNP';
				departmentName = " (Train and Production) ";
				break;
			case "/manage/sctransporters":
				department='LOG-SC';
				departmentName = " (Supply Chain) ";
				break;
			default:
				console.log("IN getDepartmentFilter, default, params = ", pathToMatch);
		}
		this.setState({
			currentDepartmentTitle:departmentName
		});
		return department;
	}
	componentDidMount(){
		if (googleAnalytics.page.enableGA) {
			this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});

		}
		// get department code to filter records by department
		let currentDepartmentCode = this.getCurrentDepartmentCode(this.props.match.path);
		this.setState({
			departmentCode:currentDepartmentCode,
			loadshow: "show-m"
		});
		 /*Consignments List*/	
	    this.loadPageData()
	};

	loadPageData = () => {
		redirectURL.post('/transporters/getAddCoalminedata')    
		.then((response) => {
		  var records = JSON.parse(JSON.stringify(response)).data;
		  
		  if (this.state.departmentCode) {
			  records = records.filter(rec => rec.dept_code === this.state.departmentCode);
			}
			console.log("/transporters/getAddCoalminedata, records = ", records);
		  this.setState({
			  rowData:records,
			  loadshow: "show-n"
		  });
		})
		.catch(function (error) {
		  console.log(error);
		  });
	}
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
    }

	onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };

	onClickShowSliderForTransporterAddition = () =>{
		this.setState({
			sliderTranslate : "slider-translate-30p",
			loadshow:'show-n',
			overly:'show-m',
			showDiv:'show-m',
		})

	}

	addTransporterDetails = e =>{
		e.preventDefault()
		let CCode = $("#consigner_code").val()
		let CName = $("#consigner_name").val()
        let CAdd = $("#consigner_address").val()
        let CState = $("#consigner_state").val()
        let CLat = $("#consigner_lat").val()
        let CLng = $("#consigner_lng").val()
        let Cdate = $("#created_date").val()
        let CType = $("#consigner_type").val()
        let CDistance = $("#distance_from_plant").val()
        let COpeninghours = $("#opening_hours").val()
       
		// let created_date = new Date().toISOString()
		let params = {
			is_active : 1,
			consigner_code:CCode,
			consigner_name:CName,
            consigner_address:CAdd,
            consigner_state:CState,
            consigner_lat:CLat,
            consigner_lng:CLng,
            created_date:Cdate,
            consigner_type:CType,
            distance_from_plant:CDistance,
            opening_hours:COpeninghours,
			dept_code: this.state.departmentCode,
			created_date : moment.parseZone().utcOffset("+05:30")._d
		}
		// console.log("param",params)
		redirectURL.post("/transporters/getInsertnewCoalmine",params).then(response =>{
			let data = response.data
			console.log("data",data)
			if(data.message == "Success"){
				$("#consigner_code").val("")
				$("#consigner_name").val("")
                $("#consigner_address").val("")
                $("#consigner_state").val("")
                $("#consigner_lat").val("")
                $("#consigner_lng").val("")
                $("#created_date").val("")
                $("#consigner_type").val("")
                $("#distance_from_plant").val("")
                $("#opening_hours").val("")
				this.setState({
					basicTitle: "Inserted Successfully.",
					basicType: "success",
					show: true
				})
				this.loadPageData()
			}else{
				this.setState({
					basicTitle: data.message,
					basicType: "warning",
					show: true
				})
			}

		})
	}

	onClickHideAll = () =>{
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderTranslate : "",
			showDiv:'show-n',
			show: false,
            sliderTranslate:"",
		});
	}
	
	closeAlert = () => {
		this.setState({
			show: false
		});
	}

    render(){
		const modalStyles  = {
			width:'1300px !important',
		}
		
		const {usermanualmodal} = this.state;
		var hideChilds = true;
		// commented this code as this condition was from the msil screen on 27-07-2022
		// if(this.state.departmentCode == "LOG-PRT")
		// {
		// 	hideChilds = false
		// }
		// console.log("hello Checking")
		const columnwithDefs = [
	  	        {
	  	          headerName: "Consigner Code",
	  	          field: "consigner_code",
	  	          width: 180,
                  editable:false,
	    	      filter: "agSetColumnFilter"
		        },
		        {
		          headerName: "Consigner Name",
                  field: "consigner_name",
				  width: 180,
				  editable : false,
                  filter: "agSetColumnFilter"	    	          
				},
                {
                    headerName: "Consigner Address",
                    field: "consigner_address",
                    width: 180,
                    editable : false,
                    filter: "agSetColumnFilter"	    	          
                  },
                  {
                    headerName: "Consigner State",
                    field: "consigner_state",
                    width: 180,
                    editable : false,
                    filter: "agSetColumnFilter"	    	          
                  },
                  {
                    headerName: "Lat",
                    field: "consigner_lat",
                    width: 180,
                    editable : false,
                    filter: "agSetColumnFilter"	    	          
                  },
                  {
                    headerName: "Lng",
                    field: "consigner_lng",
                    width: 180,
                    editable : false,
                    filter: "agSetColumnFilter"	    	          
                  },
                  {
                    headerName: "Create Date",
                    field: "created_date",
                    width: 180,
                    editable : false,
                    filter: "agSetColumnFilter",
                    valueGetter:function(params){
                        return getHyphenDDMMMYYYYHHMM(params.data.created_date)
                    }	    	          
                  },
                  {
                    headerName: "Type",
                    field: "consigner_type",
                    width: 180,
                    editable : false,
                    filter: "agSetColumnFilter"	    	          
                  },
                  {
                    headerName: "Distance From plant",
                    field: "distance_from_plant",
                    width: 180,
                    editable : false,
                    filter: "agSetColumnFilter"	    	          
                  },
                  {
                    headerName: "Opening Hours",
                    field: "opening_hours",
                    width: 180,
                    editable : false,
                    filter: "agSetColumnFilter"	    	          
                  },
				
		        // {
		        //     headerName: "Status",
		        //     field: "is_active",
		        //     width: 90,
				// 	filter: false,
		        //     //singleClickEdit:true,
		        //     editable: true,
		        //     cellRenderer: function(params){
		        //     	if(params.value == 1 || params.value == 'Active')
	            // 		{
		        //     		return 'Active';
	            // 		}
		        //     	else
	            // 		{
		        //     		return 'Inactive';
	            // 		}
		        //     },
		        //     cellEditor: 'agRichSelectCellEditor',
		        //     cellEditorParams: {
		        //         cellRenderer: 'genderCellRenderer',
		        //         values: ['Active', 'Inactive']
		        //     }
		        // },
		        // {
		        //   headerName: "Action",
		        //   field: "_id",
		        //   width: 100,
				//   filter: false,
		        //   cellRenderer:'updateItem',
		        //   cellRendererParams: {
			    //         actionCall: "transporters",
			    //         actionMethod:"updateTransporter"
			    //     },
			    //     editable:false
		        // }
	    ];
		return(
			<div className="container-fluid">
			<div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div class="loader-box">
						<div class="loader-box">
							<div className={`new-loader ${this.state.loadshow}`}></div>
						</div>
					</div>
				</div>
				{/* <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>	 */}
			<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        > 
	        </SweetAlert>
	            {this.state.alert}
				<div className="">
				<div className="mb-4">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span style={{ fontFamily: "Inter-SemiBold" }}>Add Coal Mine  </span>
									   <button onClick={this.onClickShowSliderForTransporterAddition} className={"btn btn-warning float-right"}>Add Coal Mine</button>
                                </h5>
				   			</div>	
							</div>
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                           			   			
		            		<div className="card-body">
				   				   <Grid 
								   ashow={this.state.show} 
								   atype={this.state.basicType} 
								   atitle={this.state.basicTitle}
								   actionCall={"transporters"}
									actionMethod={"updateTransporter"}  
									//table={"trucklocation"} 
									rowData={this.state.rowData} 
									columnwithDefs={columnwithDefs} 

									/>
				   			</div>
				   		</div>	
				   	</div>
				 </div>
				 <div className={"slide-r "+(this.state.sliderTranslate)}style={{ overflow: "auto", marginTop: "40px" }} >				 	
                 <div className="slide-r-title">
				 		<h4>
                            Add Coal Mine
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll} >X</span>
                        </h4>
				 	</div>
				 	<div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'2px 2px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.addTransporterDetails}>   
							<div className="form-group mt-20p">
								<label className="col-xl-12 col-lg-12" style={{padding:"0px"}}>Consigner Name</label> 
								<input type="text" name="text" placeholder="Consigner Name" id="consigner_name" className="form-control" required  />
							</div>                         
                            <div className="form-group mt-20p">
								<label className="col-xl-12 col-lg-12" style={{padding:"0px"}}>Consigner Code</label> 
								<input type="text" name="text" placeholder="Consigner Code" id="consigner_code" className="form-control" required  />
							</div>
                            <div className="form-group mt-20p">
								<label className="col-xl-12 col-lg-12" style={{padding:"0px"}}>Consigner Address</label> 
								<input type="text" name="text" placeholder="Consigner Address " id="consigner_address" className="form-control" required  />
							</div>
                            <div className="form-group mt-20p">
								<label className="col-xl-12 col-lg-12" style={{padding:"0px"}}>Consigner State</label> 
								<input type="text" name="text" placeholder="Consigner State" id="consigner_state" className="form-control" required  />
							</div>
                            <div className="form-group mt-20p">
								<label className="col-xl-12 col-lg-12" style={{padding:"0px"}}>Consigner Lat</label> 
								<input type="text" name="text" placeholder="Consigner Lat" id="consigner_lat" className="form-control" required  />
							</div>
                            <div className="form-group mt-20p">
								<label className="col-xl-12 col-lg-12" style={{padding:"0px"}}>Consigner Lng</label> 
								<input type="text" name="text" placeholder="Consigner Lng" id="consigner_lng" className="form-control" required  />
							</div>
                            {/* <div className="form-group mt-20p">
								<label className="">Created Date</label> 
								<input type="text" name="text" placeholder="Created Date" id="created_date" className="form-control" required  />
							</div> */}
                            <div className="form-group mt-20p">
								<label className="col-xl-12 col-lg-12" style={{padding:"0px"}}>Consigner Type</label> 
								<input type="text" name="text" placeholder="Consigner Type" id="consigner_type" className="form-control" required  />
							</div>
                            <div className="form-group mt-20p">
								<label className="col-xl-12 col-lg-12" style={{padding:"0px"}}>Distance From Plant</label> 
								<input type="text" name="text" placeholder="Distance From Plant" id="distance_from_plant" className="form-control" required  />
							</div>
                            <div className="form-group mt-20p">
								<label className="col-xl-12 col-lg-12" style={{padding:"0px"}}>Opening Hours</label> 
								<input type="text" name="text" placeholder="Opening Hours" id="opening_hours" className="form-control" required  />
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>	
                        </div>
					</div>
				 </div>
				<span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span>
				<Modal open={usermanualmodal} onClose={this.onCloseUserManualModal.bind(this)} styles={modalStyles}>
					<div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<h5 className="model-title">Transporter</h5>
						<div className="col-xl-12 col-lg-12 mt-20p">
							<div className="col-xl-12 col-lg-12">
								This screen consists details regarding to all the transporters, its MSIL code and currently actively taking consignments or not.
							</div>
							
							<div className="col-xl-12 col-lg-12">	
								Image Here
							</div>
							<div className="col-xl-12 col-lg-12">
								For PRT department there is additional column present which is known as child codes which consists of subcodes of transporters through which they pick the consignments from various point of Origin
							</div>
							
							<div className="col-xl-12 col-lg-12">	
								Image Here
							</div>
						</div>
					</div>
				</Modal>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll}></div>
            </div>
              	
		);
	}
}

$(document).on(".num-cls", function() {
    this.value = this.value.replace(/\D\./g,'');
    // this.value = this.value.replace(/[!#$%^*()+\=;'|<>\?a-zA-Z]+/g,"")

});

// const regex = /^[-\w\s]+$/;
// $(document).on(".txt-cls", function() {
//     this.value = this.value.replace(/^[-\w\s]+$/g,'');
// });

function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		format:'Y-m-d',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}