import React from 'react'

const EditRenderer = (props) => {

    const handleEditClick = (e) => {
        e.stopPropagation();
        
        props.context.componentParent.onEditButtonHandler(props.data);
    };

  return (
      <button
        className='btn btn-success'
        type="button"
        onClick={handleEditClick}
        title="Edit"
        style={{
            padding: "0px",
            width: "100%"
        }}
      >
        <i className="icofont icofont-edit f14"></i> Edit
      </button>
  );
}

export default EditRenderer