import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
class Breadcrumb extends Component {
    constructor(props) {
        super(props);
        this.redirectToPage = this.redirectToPage.bind(this);
    }
    redirectToPage() {
        const { history } = this.props;
        history.push('/'); // Use history.push to redirect
      }
    
    render(){
        return (
        <div className="container-fluid">
            <div className="page-header pt-0px pb-5px">
                <div className="row">
                    <div className="col-lg-6">
                        {/*<h3>{this.props.title}
                        </h3>
                        */}
                    </div>
                    <div className="col-lg-6">
                        <ol className="breadcrumb pull-right">
                            <li className="breadcrumb-item" onClick={this.redirectToPage}><a href="#!"><i className="fa fa-home"></i></a></li>
                            <li className="breadcrumb-item">{this.props.parent}</li>
                            <li className="breadcrumb-item active">{this.props.title}</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default withRouter(Breadcrumb);