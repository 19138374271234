import React from 'react';
import DisconnectSvgIcon from './DisconnectSvgIcon';

const GpsDisconnectionEnabledAction = (props) => {
    const handleEventClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onShowGPSWireDisconnection(props);
    };

    return (
        <div>
            <button onClick={handleEventClick} className="custom-btn f22 label label-success" 
            title="GPS Wire Disconnected" >
                <DisconnectSvgIcon height="20" width="20" color="#bd0a41"/> &nbsp;
            </button>
        </div>
    );
};

export default GpsDisconnectionEnabledAction;
