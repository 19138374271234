/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from "lodash";
import $ from 'jquery';
import axios from 'axios';
import Select from 'react-select';
import Grid from '../layouts/transporterComponent';
import Modal from 'react-responsive-modal';
var moment = require('moment');
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");


export default class ManageTransporters extends Component {

	constructor(props){
		super(props);
		this.state={
			departmentCode: null,
			currentDepartmentTitle: null,
			rowData:null,
			uploadDivWidth:'0%',
			sliderTranslate:'',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			pagetitle:"",
			usermanualmodal:false,
			overly: "show-n",
			sliderTrucksTranslate: "",
			loadshow:'show-n',
			originoption: { "value": "--Select--", "label": "--Select--" },
			originoptions:{}
		}
	}
	getCurrentDepartmentCode(pathToMatch = null) {
		let department = null;
		let departmentName = null;
		switch(pathToMatch) {
			case "/manage/sndtransporters":
				department='IBL';
				departmentName = " (Inbound Logistics) ";
				break;
			case "/manage/prttransporters":
				department='LOG-PRT';
				departmentName = " (Spare Parts) ";
				break;
			case "/manage/tnptransporters":
				department='LOG-TNP';
				departmentName = " (Train and Production) ";
				break;
			case "/manage/sctransporters":
				department='LOG-SC';
				departmentName = " (Supply Chain) ";
				break;
			default:
				console.log("IN getDepartmentFilter, default, params = ", pathToMatch);
		}
		this.setState({
			currentDepartmentTitle:departmentName
		});
		return department;
	}
	componentDidMount(){
		if (googleAnalytics.page.enableGA) {
			this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});

		}
		// get department code to filter records by department
		let currentDepartmentCode = this.getCurrentDepartmentCode(this.props.match.path);
		this.setState({
			departmentCode:currentDepartmentCode,
			loadshow: "show-m"
		});
		redirectURL.post("/dashboard/getInitialDataForDeviationAnalysis")
		.then((response) => {
			var plantdata = response.data.consigners;
			let mineMap = {};
			console.log("fjdklsjfsdkjlfsd",response.data);
			var originoptions = [{ "label": "--Select--", "value": "--Select--" }];
			plantdata.map((item) => {
				console.log(item,"item123")
				originoptions.push({
					value: item.consigner_code,
					label:item.consigner_code+" - "+item.consigner_name
				})
				mineMap[`${item.consigner_code}`] = item.consigner_name
			})
			console.log(originoptions,"originoptions1234")
			var destinationOptions = [];
			var destinationarr = response.data.destinations;
			destinationarr.map((item) => {
				const containsObject = destinationOptions.some(obj => {
					return obj.value === item && obj.label === item;
				  });
				if(!containsObject){
				destinationOptions.push({
					value: item, label: item
				})}
			})
			this.setState({
				originoptions: originoptions,
				destinationOptions: destinationOptions,
				destinationarr,
				mineMap
			})
		})
		 /*Consignments List*/	
	    this.loadPageData()
	};

	loadPageData = () => {
		redirectURL.post('/transporters/list')    
		.then((response) => {
		  var records = JSON.parse(JSON.stringify(response)).data;
		  
		  if (this.state.departmentCode) {
			  records = records.filter(rec => rec.dept_code === this.state.departmentCode);
			}
			console.log("/transporters/list, records = ", records);
		  this.setState({
			  rowData:records,
			  loadshow: "show-n"
		  });
		})
		.catch(function (error) {
		  console.log(error);
		  });
	}
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
    }

	onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };

	onClickShowSliderForTransporterAddition = () =>{
		this.setState({
			sliderTranslate : "slider-translate-30p",
			loadshow:'show-n',
			overly:'show-m',
			showDiv:'show-m',
		})

	}

	changeOriginHandler = originoption => {
		console.log(originoption,"originoption00000000")
        this.setState(
            { originoption }
        );
    }
	addTransporterDetails = e =>{
		e.preventDefault()
		let tCode = $("#transporter_Code").val()
		let tName = $("#transporter_name").val()
		let selectedOption = this.state.originoption.map(option => option.label);
		console.log(this.state.originoption,"option12")
		// let created_date = new Date().toISOString()
		let params = {
			is_active : 1,
			transporter_code:tCode.trim().toUpperCase().replace(/^0+/, ""),
			transporter_name:tName,
			originoption:selectedOption,
			dept_code: this.state.departmentCode,
			created_date : moment.parseZone().utcOffset("+05:30")._d
		}
		// console.log("param",params)
		redirectURL.post("/transporters/insertNewTransporter",params).then(response =>{
			let data = response.data
			console.log("data",data)
			if(data.message == "Success"){
				$("#transporter_Code").val("")
				$("#transporter_name").val("")
				this.setState({ originoption: null });
				this.setState({
					basicTitle: "Inserted Successfully.",
					basicType: "success",
					show: true
				})
				this.loadPageData()
			}else{
				this.setState({
					basicTitle: data.message,
					basicType: "warning",
					show: true
				})
			}

		})
	}

	onClickHideAll = () =>{
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderTranslate : "",
			showDiv:'show-n',
			show: false,
			sliderTranslate:""
		});
	}
	
	closeAlert = () => {
		this.setState({
			show: false
		});
	}

    render(){
		const modalStyles  = {
			width:'1300px !important',
		}
		
		const {usermanualmodal} = this.state;
		var hideChilds = true;
		// commented this code as this condition was from the msil screen on 27-07-2022
		// if(this.state.departmentCode == "LOG-PRT")
		// {
		// 	hideChilds = false
		// }
		// console.log("hello Checking")
		const columnwithDefs = [
	  	        {
	  	          headerName: "Transporter Code",
	  	          field: "transporter_code",
	  	          width: 300,
                  editable:false,
	    	      filter: "agSetColumnFilter"
		        },
		        {
		          headerName: "Transporter Name",
                  field: "transporter_name",
				  width: 300,
				  editable : false,
                  filter: "agSetColumnFilter"	    	          
				},
				{
					headerName: "Child Codes",
					field: "child_codes",
					width: 200,
					cellStyle: {'white-space': 'normal'},
					editable : false,
					autoHeight:true,
					hide : hideChilds,
					valueGetter:function(params)
					{
						if(params.data.child_code.length > 0)
						{
							var child_codes = ''
							params.data.child_code.map(function(e){
								if(child_codes != "")
								{
									child_codes = child_codes+", "+e
								}
								else{
									child_codes = e
								}
							});
							return child_codes
						}
						else
						{
							return ""
						}
					}	    	          
				  },
		        {
		            headerName: "Status",
		            field: "is_active",
		            width: 90,
					filter: false,
		            //singleClickEdit:true,
		            editable: true,
		            cellRenderer: function(params){
		            	if(params.value == 1 || params.value == 'Active')
	            		{
		            		return 'Active';
	            		}
		            	else
	            		{
		            		return 'Inactive';
	            		}
		            },
		            cellEditor: 'agRichSelectCellEditor',
		            cellEditorParams: {
		                cellRenderer: 'genderCellRenderer',
		                values: ['Active', 'Inactive']
		            }
		        },
				{
					headerName: "Mine Name",
					field: "originoption",
					width: 300,
					editable : false,
					filter: "agSetColumnFilter"	    	          
				  },
		        // {
		        //   headerName: "Action",
		        //   field: "_id",
		        //   width: 100,
				//   filter: false,
		        //   cellRenderer:'updateItem',
		        //   cellRendererParams: {
			    //         actionCall: "transporters",
			    //         actionMethod:"updateTransporter"
			    //     },
			    //     editable:false
		        // }
	    ];
		return(
			<div className="container-fluid">
			<div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div class="loader-box">
						<div class="loader-box">
							<div className={`new-loader ${this.state.loadshow}`}></div>
						</div>
					</div>
				</div>
				{/* <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>	 */}
			<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        > 
	        </SweetAlert>
	            {this.state.alert}
				<div className="">
				<div className="mb-4">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span style={{ fontFamily: "Inter-SemiBold" }}>Manage Transporters  </span>
									   <button onClick={this.onClickShowSliderForTransporterAddition} className={"btn btn-warning float-right"}>Add Transporter</button>
                                </h5>
				   			</div>	
							</div>
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                           			   			
		            		<div className="card-body">
				   				   <Grid 
								   ashow={this.state.show} 
								   atype={this.state.basicType} 
								   atitle={this.state.basicTitle}
								   actionCall={"transporters"}
									actionMethod={"updateTransporter"}  
									//table={"trucklocation"} 
									rowData={this.state.rowData} 
									columnwithDefs={columnwithDefs} 

									/>
				   			</div>
				   		</div>	
				   	</div>
				 </div>
				 <div className={"slide-r "+(this.state.sliderTranslate)} style={{overflow:"auto",marginTop:"40px"}}>				 	
					 <div className="slide-r-title">
				 		<h4>
                            Add Transporter
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll} >X</span>
                        </h4>
				 	</div>
				 	<div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.addTransporterDetails}>   
							<div className="form-group mt-20p">
								<label className="">Transporter Code</label> 
								<input type="text" name="text" placeholder="Transporter Code" id="transporter_Code" className="form-control" required  />
							</div>                         
                            <div className="form-group mt-20p">
								<label className="">Transporter Name</label> 
								<input type="text" name="text" placeholder="Transporter Name" id="transporter_name" className="form-control" pattern="[a-zA-Z'-'\s]*" required  />
							</div>
							<div className="form-group col-xl-12 col-lg-12" style={{ paddingLeft: 0 }}>
                                <label>Coal Mines  </label>
                                <Select
                                    name="originoption"
									id="originoption"
                                    closeMenuOnSelect={true}
									isMulti="true"
                                    value={this.state.originoption}
                                    className="border-radius-0"
                                    onChange={this.changeOriginHandler.bind(this)}
                                    style={{ borderRadius: "0px" }}
                                    options={this.state.originoptions} required
                                />
                            </div>
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>	
                        </div>
					</div>
				 </div>
				<span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span>
				<Modal open={usermanualmodal} onClose={this.onCloseUserManualModal.bind(this)} styles={modalStyles}>
					<div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<h5 className="model-title">Transporter</h5>
						<div className="col-xl-12 col-lg-12 mt-20p">
							<div className="col-xl-12 col-lg-12">
								This screen consists details regarding to all the transporters, its MSIL code and currently actively taking consignments or not.
							</div>
							
							<div className="col-xl-12 col-lg-12">	
								Image Here
							</div>
							<div className="col-xl-12 col-lg-12">
								For PRT department there is additional column present which is known as child codes which consists of subcodes of transporters through which they pick the consignments from various point of Origin
							</div>
							
							<div className="col-xl-12 col-lg-12">	
								Image Here
							</div>
						</div>
					</div>
				</Modal>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll}></div>
            </div>
              	
		);
	}
}

$(document).on(".num-cls", function() {
    this.value = this.value.replace(/\D\./g,'');
    // this.value = this.value.replace(/[!#$%^*()+\=;'|<>\?a-zA-Z]+/g,"")

});

// const regex = /^[-\w\s]+$/;
// $(document).on(".txt-cls", function() {
//     this.value = this.value.replace(/^[-\w\s]+$/g,'');
// });