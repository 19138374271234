/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */


import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import UpdateButton from "./updatelmstates";
import UpdateTransitButton from "./updatetransitbutton";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import ShowTPTGpsMap from "../tptlogin/showtptgpsmap";
import DrawMap from '../common/drawmap';
import CSVFileValidator from 'csv-file-validator'

import $ from 'jquery';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenDDMMYYYY } from '../common/utils'; 
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var alertIcon_50 = require("../../assets/icons/accident alert_16.jpeg");
var redirectURL = require('../redirectURL');
var moment = require('moment');
var CELL_DIMENSION_SIZE = 90;
var googleAnalytics = require("../common/googleanalytics");
var axios = require('axios');


export default class CreateDo extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			pageTitle: "Transporter GPS Overspeed",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			showform:'show-m',
			loadshow:'show-n',
			showrefresh:'show-n',
			sliderTranslate:'',
			showdata:this.displayData,
			modules: AllModules,
        	radius:'15',
			rlatitude:'',
			rlongitude:'',
			latitude:'',
			longitude:'',
			defaultradius:0,
			coordinates:[],
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			contentString:"testing",
			mwidth:"col-xl-12 col-lg-12",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
				//editable: true,
				resizable: true
			},
			rowData: [],
			truckList: [],
			originalrowData: [],
			selectedTabLat:'',
			selectedTabLng:'',
			cluster_centroids: [],
			deftab:'all',
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
			context: { componentParent: this },
			frameworkComponents : {
				showtptgpsmap:ShowTPTGpsMap,
                UpdateButton:UpdateButton,
                UpdateTransitButton:UpdateTransitButton,
            },
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,
		
			showPlant:'show-n',
			showAddr:'show-n',
			request_type:'',
			view_type:'',
			tabplants:[],
			clickabletab:'',
			plantlist:[],
			stateFormData : "",
			sliderStateEmailsTranslate : "",
			overly : "show-n",
            rakeslist : [],
            loadingpoints:[],
            destinationpoints:[],
            userType:"",
            pinnedRegionBottomRowData:[],
            pinnedTransitBottomRowData:[],
            stockData:[],
            totalStock:0,
            totalSales:0,
            totalDispatches:0,
            railShare:0,
            totalFySales:0,
            totalFyDispatches:0,
            railFyShare:0,
            pendingForDispatchStock:0,
            transitStock:0,
            unloadingUpdatedDate:"",
            intransitUpdatedDate:"",
            screenView: "",
            originoptions: [],
            todayInstalled: [],
            totalInstalled: [],
            bulkPrioritySlide:"",
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			filteredData: [],
			truckNoList:[],
			showTrucksList : "show-n",
			startDate:'',
			endDate:'',
			truckNos:[],
			mapinfo:[],
            // duration:"",
			sliderRouteTranslate:'',
			tpttrucks:[],
            truck_no:[],
            tpt_code:[],
            truck_no_option:[],
            tpt_code_option:[],
            lastElement:"",
            lastNumbers:0,
            allData:[],
            page_no:0,
            alrData:0,
            total_count:[],
            originoption: "",
            do_number:"",
            do_quantity:"",
            startdate:"",
            enddate:"",
			sliderRsoTitle:"",
			valid_from_date:"",
			main_rowId:"",
			do_number_check:"",
			file:"",
			fromDate: "",
			toDate: "",
			doFromDate: "",
			doToDate: "",
			extendDODate: ""
            // from_date: moment.parseZone().subtract(5,'days').format("YYYY-MM-DD"),
            // to_date: moment.parseZone().format("YYYY-MM-DD"),
		};
		this.onShowTPTGPSMapRoute = this.onShowTPTGPSMapRoute.bind(this);
	}
	/*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onChangeDuration(duration){
		this.setState(
			{ duration },
			// () => console.log(`duration Option selected:`, this.state.duration)
		);
	}
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
    async componentDidMount(){
        loadDateTimeScript();
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
        var end_date = moment.parseZone(new Date()).format("YYYY-MM-DD")
        var start_date = moment.parseZone(new Date()).subtract(6, 'months').format("YYYY-MM-DD")

		this.setState({ fromDate: start_date, toDate: end_date });

        // $("#srfromDate").val(start_date)
        // $("#srtoDate").val(end_date)
        var params ={
            start_date:start_date,
            end_date:end_date
        }
        await redirectURL.post("/dashboard/getIntialDoData",params).then((response)=>{
            // console.log(response.data,"239")
            this.setState({
                rowData:response.data,
                loadshow:"show-n",
                overly:"show-n"
            })
        })
        await redirectURL.post("/dashboard/getInitialDataForDeviationAnalysis")
            .then((response) => {
                var plantdata = response.data.consigners;
                // console.log("fjdklsjfsdkjlfsd",response.data);
                var originoptions = [];
                plantdata.map((item) => {
                    // console.log(item,"item123")
                    originoptions.push({
                        value: item.consigner_code,
                        label: item.consigner_name+" - "+item.consigner_code
                    })
                })
                var destinationOptions = [{ "label": "ALL", "value": "ALL" }];
                var destinationarr = response.data.destinations;
                destinationarr.map((item) => {
                    destinationOptions.push({
                        value: item, label: item
                    })
                })
                this.setState({
                    originoptions: originoptions,
                    destinationOptions: destinationOptions,
                    destinationarr
                })
            })

    }
	onClickHideAll(){
		this.setState({
			overly : "show-n",
			showTrucksList : "show-n",
			sliderRouteTranslate:'',
            sliderRso:"",
            sliderRso1:"",
            bulkPrioritySlide:"",
			do_number:"",
			originoption:"",
			do_quantity:"",
			doFromDate:"",
			doToDate:"",
			extendDODate: ""
		})
		$("#doEndDateExtend").val('')
		$("#doStartDate").val('');
		$("#doEndDate").val('');
		$("#uploadFile").val('')
	}
	hideList(){
        this.setState({
			loadshow:'show-n',
			overly:'show-n',
			showTrucksList : "show-n",
        });
    }
	
    onGridReady = params => {
		this.gridApi = params.api;
		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;
    };
    

	onClickFilterDoDate = () => {
        this.setState({
			loadshow:'show-m',
			overly:'show-m',
        });
		
		// var from_date =$("#srfromDate").val();
		// var to_date = $("#srtoDate").val();
		var from_date =this.state.fromDate;
		var to_date = this.state.toDate;
        var start_date =moment.parseZone(new Date(from_date)).format("YYYY-MM-DD")
        var end_date = moment.parseZone(new Date(to_date)).format("YYYY-MM-DD")
        var params ={
            from_date : start_date,
            to_date : end_date
        }
        redirectURL.post("/dashboard/getFilteredDoData",params).then((response)=>{
            console.log(response.data,"317")
            this.setState({
                rowData:response.data,
                loadshow:"show-n",
                overly:"show-n"
            })
        })
		
	}

	reloadPageData = () => {
        window.location.reload();
    }

	onChangeDepartment(truckNos){
		this.setState(
			{ truckNos },
			() => console.log(`truckNos Option selected:`, this.state.truckNos)
		);
	}

    handlerFromDateTime = (event, currentDate, selectedDate) => {
        
        var from_date = moment(new Date(event._d)).format('YYYY-MM-DD');
        this.setState({
            from_date
        });
    }
    handlerToDateTime = (event, currentDate, selectedDate) => {      
		var to_date = moment(new Date(event._d)).format('YYYY-MM-DD');

		this.setState({
            to_date
        });
    }

	initalList =(event)=>{
        $(".trucks-dropdown").removeClass("show-n");
        var dataset = this.state.truck_no_option;
        var tpttrucks = this.state.tpttrucks;
        var tptunqrecords = []
        if(tpttrucks.length > 0)
        {
            dataset.filter(f =>
				tptunqrecords.push(f)
            );
        }
        else
        {
            tptunqrecords = dataset
        }
        tptunqrecords = tptunqrecords.slice(0,100);
        this.setState({
            filteredData : tptunqrecords,
            showTrucksList : "show-m",
        })
    }

	handlerForm = (event) => {    	
        $(".trucks-dropdown").removeClass("show-n");
        var dataset = this.state.truck_no_option;       
        var tptunqrecords = []
		console.log("inputval", $("#inputTruck").val())		
        if(dataset.length > 0)
        {
            dataset.filter(f =>
				tptunqrecords.push(f)                
            );
        }
        else
        {
            tptunqrecords = dataset
        }
        // eslint-disable-next-line array-callback-return
        var filteredResult = tptunqrecords.filter((e) =>{
            if(e != "" && e != null)
            {
                return e.toString().toLowerCase().includes($("#inputTruck").val().toLocaleLowerCase());
            }
            
        });
        // console.log("filteredResult ", filteredResult)
        filteredResult = filteredResult.slice(0,100);
        this.setState({
            filteredData : filteredResult,
            showTrucksList : "show-m",
        })
    }

    onShowTPTGPSMapRoute(rownode){
        var node = rownode.data;
        // console.log("Node ", node)
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
        if (googleAnalytics.page.enableGA) {
            let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		const toDate = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
		var parameters = {
			truck_no:node.truck_no,
			from_date:moment.parseZone(node.gate_out_time).format('YYYY-MM-DD HH:mm:ss'),
			to_date:moment.parseZone(node.plant_in_timestamp).format('YYYY-MM-DD HH:mm:ss'),
            consignment_code: node.consignment_code,
			device_id:""
        }
        redirectURL.post("/consignments/showgpseodmap", parameters)
        .then((response) => {
            // console.log("response ", response.data)
            this.setState({
                mapinfo:response.data,
                sliderRouteTranslate:"slider-translate-60p",
                loadshow:'show-n',
                overly:'show-m',

        });
        })
    }
   
    onClickShowForm1() {
		this.setState({
			overly: "show-m",
			sliderRso:"slider-translate",
            
		});
	}
    onSubmitDO = async (event) => {
		event.preventDefault();
        var originoption = this.state.originoption.label;
		var do_number = this.state.do_number;
        // var startDate = $("#doStartDate").val();
        // var endDate =  $("#doEndDate").val();
        var startDate = this.state.doFromDate;
        var endDate =  this.state.doToDate;
		console.log("test12 - ",startDate ," - " ,endDate);
        var do_quantity = this.state.do_quantity;
		var do_upload = this.state.file
		// var consigner = this.state.consigners.value;
		// var valid_till_date = $("#valid_till_date").val();
		// var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		if (originoption!=""&& originoption!=undefined && do_number != "" && do_number !=undefined && startDate !="" && startDate !=undefined && endDate!="" && endDate!=undefined) {
			if(moment.parseZone(new Date(startDate+" 00:00:00")).utcOffset("+05:30")._d < moment.parseZone(new Date(endDate+" 23:59:59")).utcOffset("+05:30")._d){
				if(do_quantity == "" || parseInt(do_quantity) > 0){
					var saveData = new FormData();
					saveData.append("do_number",do_number);
					saveData.append("startDate",startDate);
					saveData.append("endDate",endDate);
					saveData.append("consigner",originoption);
					saveData.append("do_quantity",do_quantity);
					saveData.append("created_by",localStorage.getItem("email"));
					saveData.append("do_upload",do_upload)
					redirectURL.post("/dashboard/createDO", saveData)
						.then((response) => {
							// console.log("save response ", response.data)
							if (response.data.message == "Success") {
								this.setState({
									show: true,
									basicType: "success",
									basicTitle: "Successfully created DO"
								})
								this.onClickHideAll()
								this.componentDidMount()
							}
							else {
								this.setState({
									show: true,
									basicType: "danger",
									basicTitle: response.data.message
								})
								this.onClickHideAll()
							}
						})
					}
				else{
					this.setState({
						show: true,
						basicType: "danger",
						basicTitle: "DO quantity should be greater than 0"
					})
					// this.onClickHideAll()
				}
			}
			else{
				this.setState({
					show: true,
					basicType: "danger",
					basicTitle: "To Date should be greater than or equal to From Date"
				})
				// this.onClickHideAll()
			}
				
				// }else{
				// 	this.setState({
				// 		show: true,
				// 		basicType: "danger",
				// 		basicTitle: "From Date Should Be Less than To Date"
				// 	})
				// 	$("#doStartDate").val('');
        		// 	$("#doEndDate").val('');
				// }
		}
		else {
			this.setState({
				show: true,
				basicType: "danger",
				basicTitle: "Please fill the mandatory fields"
			})

		}
	}

    extendDo = async (event) => {
		event.preventDefault();
        // var extendDays =  $("#doEndDateExtend").val();
        var extendDays =  this.state.extendDODate;
		var valid_from_date = moment.parseZone(new Date(this.state.valid_from_date)).format("YYYY-MM-DD HH:mm:ss")
		var rowId = this.state.main_rowId
		var do_number_check = this.state.do_number_check
		var valid_from_date_check1 = moment.parseZone(new Date(extendDays)).format("YYYY-MM-DD")
		var valid_from_date_check2 = moment.parseZone(new Date()).format("HH:mm:ss")
		var valid_from_date_check = moment.parseZone(new Date(valid_from_date_check1+" "+valid_from_date_check2)).format("YYYY-MM-DD HH:mm:ss")
		if (extendDays!="" && extendDays!=undefined){
			if(moment.parseZone(new Date(valid_from_date_check)).utcOffset("+05:30")._d > moment.parseZone(new Date(valid_from_date)).utcOffset("+05:30")._d){
				var params = {
					rowId: rowId,
					extendDays:valid_from_date_check,
					do_number_check:do_number_check
				}
				console.log("parameters ", params);
	
				redirectURL.post("/dashboard/extendDO", params)
					.then((response) => {
						// console.log("save response ", response.data)
						if (response.data.message == "success") {
							this.setState({
								show: true,
								basicType: "success",
								basicTitle: "Successfully created DO"
							})
							this.onClickHideAll()
							this.componentDidMount()
							// $("#doEndDateExtend").val('')
						}else{
							this.setState({
								show: true,
								basicType: "danger",
								basicTitle: response.data.message
							})
							this.onClickHideAll()
						}
					})
			}else{
				this.setState({
					show: true,
					basicType: "danger",
					basicTitle: "Extended DO Date Should be greater than DO Start Date"
				})
				this.onClickHideAll()
			}
			
		}
		else {
			this.setState({
				show: true,
				basicType: "danger",
				basicTitle: "Please Enter Extended Date"
			})

		}
	}
    changeOriginHandler = originoption => {
        this.setState(
            { originoption }
        );
    }

    changeHandler = (e) => {
        // Assuming you want to limit the length to 15 characters
        // const inputValue = e.target.value.slice(0, 15);
		var value = e.target.value.slice(0,15)
		var name = e.target.name
			const alphanumericRegex = /^[+-]?\d*\.?\d+$/;
			if (alphanumericRegex.test(value) || value === '') {
				this.setState({
					do_number: value,
				});
			}
       
    };
    changeHandler1 = (e) => {
        // Assuming you want to limit the length to 15 characters
        const inputValue = e.target.value.slice(0, 15);

        this.setState({
            do_quantity: inputValue
        });
    };


    uploadBulkPriorityFormHandler(event) {
		event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.formAction,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var bulkMdData = this.state.pCsvContent;
		//console.log(bulkMdData);
		var flag = 0;
		// console.log("bulkMdData: =>", bulkMdData)
		bulkMdData.map(function (e, index) {
			var keys = Object.keys(e);
			var consignmentKey = ""
			keys.map(function (key) {
				if (key == "Consignment_Code" ||
					key == "GR No" ||
					key == "consignment_code" ||
					key == "gr no" ||
					key == "ConsignmentCode" ||
					key == "consignmentcode"
				) {
					consignmentKey = key;
				}
			})
			if (index != (bulkMdData.length - 1)) {
				if (e[consignmentKey] == "") {
					flag = 1
				}
			}

		})
		if (flag == 0) {
			var reqParams = {
				email: localStorage.getItem("email"),
				csvData: bulkMdData
			}
			redirectURL.post("/consignments/bulkUploadConsignmentPriority", reqParams).then((response) => {
				this.setState({
					basicTitle: "Success",
					basicType: "success",
					show: true,
					file: "",
					bulkPrioritySlide: "",
					overly: "show-n",
					loadshow: "show-n"
				})
				// window.location.reload();
			}).catch((e) => {
				console.log(e);
			})

		}
		else {
			$("#bulkUploadPID").val();
			this.setState({
				basicTitle: "Consignment_code fields must not be empty",
				basicType: "danger",
				show: true,
				file: "",
				pCsvContent: "",
			})
		}

		var urlpath = '/consignments/prtconsignments';
		var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		var dpt = 'LOG-PRT';
		var consignData = {
			dept_code: encode(dpt),
			startDate: this.state.startDate,
			endDate: this.state.endDate,
			consigner: this.state.consigner,
			movement: [this.state.movementtype]
		}


		this.onLoadGetConsignmentsList(urlpath, consignData)
	}

    
	
    resetUpload = () => {
		this.setState({
			slideuploadeway: '',
			overly: 'show-n',
			ticketslide:'',
			file: '',
			showDiv: 'show-n',
			uploadDivWidth: '0%',
			bulkPrioritySlide: '',
			bulkForceCloseSlide: '',
		});
	}

    onBulkPriorityBtn() {
		this.setState({
			overly: "show-m",
			bulkPrioritySlide: "slider-translate"
		})
	}

    onChangeConsigner(consigners) {
		this.setState(
			{ consigners },
			// () => console.log(`Plant Option selected:`, this.state.plant_code)
		);

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}
	onCellClickedInTransit=(e)=>{
		if(e.colDef.field == "extend_do"){
			var do_number = e.data.do_number
			var valid_from = e.data.valid_from
			this.setState({
				overly: "show-m",
				sliderRso1:"slider-translate",
				sliderRsoTitle:"Extend DO - "+do_number,
				valid_from_date : valid_from,
				main_rowId : e.data._id,
				do_number_check:do_number
			});
		}
	}
	changeSetDeliverFileHandler = async (e) => {
		if(e.target.files[0] != undefined && e.target.files[0] != null  && e.target.files[0] != ""){
			console.log(e.target.files[0],"791")
			var fileName = e.target.files[0].name.split(".")
			var fileType = fileName[fileName.length-1]
			var fileSize = e.target.files[0].size
			var maxFileSize = 1024*1024*10
			if(fileType == "png" || fileType == "jpg" || fileType == "jpeg" || fileType == "PNG" || fileType == "JPG" || fileType == "JPEG"){
				if(fileSize <= maxFileSize){
					var out = new Promise(function(reject, resolve){
						var reader =  new FileReader();
						reader.onload = async function(e) {
							var contents = await e.target.result;
							console.log("contents ", contents )
							resolve(contents);
						};
						var tt =  reader.readAsText(e.target.files[0]);
						console.log("tt ",tt)
					});
					//console.log("Out ", out);
			
					this.setState({
						file:e.target.files[0]
					});
					//console.log("e.target.files[0].type ", e.target.files[0].type);
			
					if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
					{
					}
					else{
						// e.target.value = null;
						// this.setState({
						// 	uploadFile:'',
						// 	show: true,
						// 	basicType:'danger',
						// 	basicTitle:'Please upload file having extensions .csv only.',
						// });
					}
				}else{
					this.setState({
						show:true,
						basicType:"warning",
						basicTitle:"Please Upload File Size Less then 10MB"
					})
					$("#uploadFile").val('')
				}
			}else{
				this.setState({
					show:true,
					basicType:"warning",
					basicTitle:"Please Upload Valid File"
				})
				$("#uploadFile").val('')
			}
			
		}
		

	}


	/**
	 * Developer Name: Siva G
	 * Bug NO: 3155
	 * Description: This function is called when FROM date is changed.
	 ***/
	handleFromDate = (event) => {
		let changedDate = moment(event._d).format("YYYY-MM-DD");
		if (this.state.toDate) {
		  if (moment(changedDate).isAfter(moment(this.state.toDate))) {
			this.setState({
			  show: true,
			  basicType: "danger",
			  basicTitle: "To Date should be greater than or equal to From Date",
			  fromDate: moment(this.state.toDate)
				.subtract(1, "days")
				.format("YYYY-MM-DD"),
			});
		  } else {
			this.setState({
			  fromDate: changedDate,
			});
		  }
		} else {
		  this.setState({
			fromDate: changedDate,
		  });
		}
	};

	/**
	 * Developer Name: Siva G
	 * Bug NO: 3155
	 * Description: This function is called when TO date is changed.
	 ***/
	handleToDate = (event) => {
		let changedDate = moment(event._d).format("YYYY-MM-DD");
		if (this.state.fromDate) {
		  if (moment(changedDate).isBefore(moment(this.state.fromDate))) {
			this.setState({
			  show: true,
			  basicType: "danger",
			  basicTitle: "To Date should be greater than or equal to From Date",
			  toDate: moment(this.state.fromDate)
				.add(1, "days")
				.format("YYYY-MM-DD"),
			});
		  } else {
			this.setState({
			  toDate: changedDate,
			});
		  }
		} else {
		  this.setState({
			toDate: changedDate,
		  });
		}
	};

	/**
	 * Developer Name: Siva G
	 * Bug NO: 3155
	 * Description: This function is called when DO FROM date is changed.
	 ***/
	handleDOFromDate = (event) => {
		let changedDate = moment(event._d).format("YYYY-MM-DD");
		if (this.state.doToDate) {
		  if (moment(changedDate).isAfter(moment(this.state.doToDate))) {
			this.setState({
			  show: true,
			  basicType: "danger",
			  basicTitle: "To Date should be greater than or equal to From Date",
			  doFromDate: moment(this.state.doToDate)
				.subtract(1, "days")
				.format("YYYY-MM-DD"),
			});
		  } else {
			this.setState({
				doFromDate: changedDate,
			});
		  }
		} else {
		  this.setState({
			doFromDate: changedDate,
		  });
		}
	};

	/**
	 * Developer Name: Siva G
	 * Bug NO: 3155
	 * Description: This function is called when DO TO date is changed.
	 ***/
	handleDOToDate = (event) => {
		let changedDate = moment(event._d).format("YYYY-MM-DD");
		if (this.state.doFromDate) {
		  if (moment(changedDate).isBefore(moment(this.state.doFromDate))) {
			this.setState({
			  show: true,
			  basicType: "danger",
			  basicTitle: "To Date should be greater than or equal to From Date",
			  doToDate: moment(this.state.doFromDate)
				.add(1, "days")
				.format("YYYY-MM-DD"),
			});
		  } else {
			this.setState({
				doToDate: changedDate,
			});
		  }
		} else {
		  this.setState({
			doToDate: changedDate,
		  });
		}
	};

	/**
	 * Developer Name: Siva G
	 * Bug NO: 3155
	 * Description: This function is called when Extend DO date is changed.
	 ***/
	handleExtendDODate = (event)=>{
		let changedDate = moment(event._d).format("YYYY-MM-DD");

		this.setState({
			extendDODate: changedDate,
		});
	}

    render(){
        
		var columnWithDefs= [
			// {
            //     headerName:"",
            //     field:"",
            //     width:50,
            //     cellRendererSelector:function(params){
            //         return {
            //             component:"showtptgpsmap"
            //         }
            //     }
            // },

            // {
			// 	headerName: "Truck No",
			// 	field: "truck_no",
            //     width: 130,
            //     filter: true,
            //     resizable: true,
            //     sortable:true,
               
			// },
            {
				headerName: "Mine Code",
				field: "consigner_code",
                width: 120,
                filter: true,
                resizable: true,
                sortable:true,
			},
            {
				headerName: "Coal Mine",
				field: "consigner_name",
                width: 180,
                filter: true,
                resizable: true,
                sortable:true,
			},

            {
				headerName: "DO Number",
				field: "do_number",
                width: 140,
                filter: true,
                resizable: true,
                sortable:true,
               
            },
            {
				headerName: "DO Start Date",
				field: "valid_from",
                width: 180,
                filter: true,
                resizable: true,
                sortable:true,
				valueGetter:function(params){
					return getHyphenDDMMMYYYYHHMM(params.data.valid_from)
				}
			},
            {
				headerName: "DO End Date",
				field: "valid_till",
                width: 180,
                filter: true,
                resizable: true,
                sortable:true,
				valueGetter:function(params){
					return getHyphenDDMMMYYYYHHMM(params.data.valid_till)
				}
			},
            {
				headerName: "DO Quantity",
				field: "do_quantity",
                width: 120,
                filter: true,
                resizable: true,
                sortable:true,
			},
			{
				headerName: "Extend DO",
				field: "extend_do",
                width: 180,
                filter: true,
                resizable: true,
                sortable:true,
				cellRenderer:function(params){       
					var rendComponent = '<button class ="btn btn-success" id="buttonStyling">Extend DO</button>'
					return rendComponent;
				},
			},
		];
		var listData = [] 
        // eslint-disable-next-line array-callback-return
        this.state.filteredData.map(function(e){
        listData.push(<li className="dropdown-truck-no">{e}</li>)
        })
        return (
            
    		<div className={(this.state.screenView == "Mobile")?"":"container-fluid"} style={(this.state.screenView == "Mobile")?{marginTop:"2em"}: {}}>
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
                >
                </SweetAlert>
				<div className="">
				<div className="mb-4">
                        <h5 style={{ fontFamily: "Inter-SemiBold" }}>
							<i class="icofont icofont-vehicle-delivery-van cus-i mr-1"></i>
                        	Create DO
                        </h5>
                    </div>
					</div>
                <div className="card">
                    <div className={(this.state.screenView == "Mobile")?"card-body":"card-body col-xl-12 col-lg-12"}>
						<div className={"trucks-dropdown "+this.state.showTrucksList} style={{top:"94px"}}>
							<ul>
								{listData}
							</ul>
						</div>
						<div className="row">
							<div className='col-xl-10 col-lg-10'>
								<div className='row'>							
									<div className="col-xl-2 col-lg-2 form-group" style={{ minWidth: "200px"}}>
										<label>From Date</label>
										{
											/**
											 * Developer Name: Siva G
											 * Bug NO: 3155
											 * Description: Changed to react-datetime.
											 ***/
										}
										<Datetime
											timeFormat={false}
											value={this.state.fromDate}
											disableCloseOnClickOutside={false}
											closeOnSelect={true}
											inputProps={{
												placeholder: "From",
												name: "fromDate",
												autoComplete: "off",
											}}
											dateFormat="YYYY-MM-DD"
											name="fromDate"
											onChange={this.handleFromDate.bind(this)}
											required
										/>
										{/* <input 
										type="text" name="date" id="srfromDate" autoComplete="off" className="datetimepicker_date form-control" readonly="true"/> */}
									</div>
									<div className="col-xl-2 col-lg-2 form-group" style={{ minWidth: "200px"}}>
										<label>To Date</label>
										{
											/**
											 * Developer Name: Siva G
											 * Bug NO: 3155
											 * Description: Changed to react-datetime.
											 ***/
										}
										<Datetime
											timeFormat={false}
											value={this.state.toDate}
											disableCloseOnClickOutside={false}
											closeOnSelect={true}
											inputProps={{
												placeholder: "To",
												name: "toDate",
												autoComplete: "off",
											}}
											dateFormat="YYYY-MM-DD"
											name="toDate"
											onChange={this.handleToDate.bind(this)}
											required
										/>
										{/* <input type="text" name="date" id="srtoDate" autoComplete="off" className="datetimepicker_date form-control" readonly="true"/> */}
									</div>
									<div className="col-xl-3 col-lg-3 form-group" style={{whiteSpace:'nowrap'}}>
										<button type="submit" onClick={this.onClickFilterDoDate.bind(this)} class="btn btn-success mt-30p mr-3">Submit</button>
										<button style={{ marginTop: "30px" }} onClick={this.reloadPageData.bind(this)} type="button" className="btn btn-info">Reset Filters</button>

									</div>
								</div>
							</div>
							<div className='col-xl-2 col-lg-2'>
								<div className="col-xl-12">
									<button
										onClick={this.onClickShowForm1.bind(this)}
										style={{ marginTop: '10px', fontWeight: 'bold',margin:"3px"}}
										className="btn btn-warning float-right"
									>
										Add DO
									</button>
                                </div>
							</div>
							
                            
                               
						</div>
                        {/* <p>Note* : Data can be fetched only for 5 days window</p> */}
                        <div id="myGrid" style={{width:"100%",height:"550px"}} className={"ag-theme-balham"}>    
                        <AgGridReact
                            modules={this.state.modules}
                            columnDefs={columnWithDefs}
                            defaultColDef={this.state.defaultColDef}
                            rowData={this.state.rowData}
                            enableCharts={false}
                            // autoGroupColumnDef={this.state.autoGroupColumnDef}
                            onGridReady={this.onGridReady}
                            onGridState={this.onGridState}
                            frameworkComponents={this.state.frameworkComponents}
                            statusBar={this.state.statusBar}
                            sideBar={this.state.sideBar}
                            stopEditingWhenGridLosesFocus= {true}
                            paginationPageSize={1000}
                            pagination={true}
                            gridOptions={{
                                context: { componentParent: this },
                            }}
                            // components={this.state.components}
                            enableRangeSelection= {true}
                            onCellClicked={this.onCellClickedInTransit.bind(this)}
                            // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                            />
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>

                <div className={"slide-r " + (this.state.sliderRso)} style={{ overflow: "auto", zIndex:"9999" }}>
					<div className="slide-r-title">
						<h4>
						Add DO 
						<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>

						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div>
						<div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >

						<div className="theme-form col-xl-12 col-lg-12">

							<form action="" 
							onSubmit={this.onSubmitDO.bind(this)}
							>
                               
                            <div className="form-group col-xl-12 col-lg-12">
									<label style={{paddingLeft:"0px"}} className="col-xl-12 col-lg-12">Coal Mine <span className='mandatory_check'>*</span></label>
									<Select
                                    name=""
                                    closeMenuOnSelect={true}
                                    value={this.state.originoption}
                                    className="border-radius-0"
                                    onChange={this.changeOriginHandler.bind(this)}
                                    style={{ borderRadius: '0px' }}
                                    options={this.state.originoptions} required

                                />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group" style={{paddingLeft:"30px"}}>
									<label style={{paddingLeft:"0px"}} className="col-xl-12 col-lg-12">DO Number <span className='mandatory_check'>*</span></label>
									<input
										type="text"
										className="form-control"
										onChange={this.changeHandler}
										value={this.state.do_number}
										name="do_number"
                                        maxLength="15"
									/>
								</div>
								<div className="col-xl-12 col-lg-12 row form-group" style={{paddingLeft:"30px", minWidth:"200px"}}>
                                <label>From Date <span className='mandatory_check'>*</span></label>
								{
									/**
									 * Developer Name: Siva G
									 * Bug NO: 3155
									 * Description: Changed to react-datetime.
									 ***/
								}
								{/* <input 
                                type="text" name="startDate" id="doStartDate" autoComplete="off" className="datetimepicker_date form-control" readOnly="true"/> */}
								<Datetime
									timeFormat={false}
									value={this.state.doFromDate}
									disableCloseOnClickOutside={false}
									closeOnSelect={true}
									inputProps={{
										// placeholder: "From",
										name: "startDate",
										autoComplete: "off",
									}}
									dateFormat="YYYY-MM-DD"
									name="startDate"
									onChange={this.handleDOFromDate.bind(this)}
									required
								/>
                                </div>
								<div className="col-xl-12 col-lg-12 row form-group" style={{paddingLeft:"30px", minWidth:"200px"}}>
                                <label>To Date <span className='mandatory_check'>*</span></label>
								{
									/**
									 * Developer Name: Siva G
									 * Bug NO: 3155
									 * Description: Changed to react-datetime.
									 ***/
								}
                                {/* <input 
                                type="text" name="endDate" id="doEndDate" autoComplete="off" className="datetimepicker_date form-control" readOnly="true"/> */}
								<Datetime
									timeFormat={false}
									value={this.state.doToDate}
									disableCloseOnClickOutside={false}
									closeOnSelect={true}
									inputProps={{
										// placeholder: "To",
										name: "endDate",
										autoComplete: "off",
									}}
									dateFormat="YYYY-MM-DD"
									name="endDate"
									onChange={this.handleDOToDate.bind(this)}
									required
								/>
								</div>
                                <div className="col-xl-12 col-lg-12 row form-group" style={{paddingLeft:"30px"}}>
									<label style={{paddingLeft:"0px"}} className="col-xl-12 col-lg-12">Quantity</label>
									<input
										type="number"
										className="form-control"
										onChange={this.changeHandler1}
										value={this.state.do_quantity}
										name="do_quantity"
                                        maxLength="15"
									/>
								</div>
								<div className="col-xl-12 col-lg-12 row form-group" style={{paddingLeft:"30px"}}>
									<label style={{paddingLeft:"0px"}} className="col-xl-12 col-lg-12">DO Upload</label>
									<input type="file" name="uploadFile" id="uploadFile" onChange={this.changeSetDeliverFileHandler}  className="form-control"/>
								</div>
								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
							</form>
						</div>
						</div>
					</div>
            	</div>
                <div className={"slide-r " + (this.state.sliderRso1)} style={{ overflow: "auto", zIndex:"9999" }}>
					<div className="slide-r-title">
						<h4>
						{this.state.sliderRsoTitle}
						<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>

						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div>
						<div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
						<div className="theme-form col-xl-12 col-lg-12">
							<form action="" 
							onSubmit={this.extendDo.bind(this)}
							>
								<div className="col-xl-12 col-lg-12 row form-group" style={{paddingLeft:"30px", minWidth: "220px"}}>
                                <label>Extend DO Date <span className='mandatory_check'>*</span></label>
								{
									/**
									 * Developer Name: Siva G
									 * Bug NO: 3155
									 * Description: Changed to react-datetime.
									 ***/
								}
                                {/* <input 
                                type="text" name="doEndDateExtend" id="doEndDateExtend" autoComplete="off" className="datetimepicker_date form-control" readonly="true"/> */}
								
								<Datetime
									timeFormat={false}
									value={this.state.extendDODate}
									disableCloseOnClickOutside={false}
									closeOnSelect={true}
									inputProps={{
										// placeholder: "Extend DO Date",
										name: "doEndDateExtend",
										autoComplete: "off",
									}}
									dateFormat="YYYY-MM-DD"
									name="doEndDateExtend"
									onChange={this.handleExtendDODate.bind(this)}
									required
								/>
								</div>
								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
							</form>
						</div>
						</div>
					</div>
            	</div>

				{this.state.sliderRouteTranslate != ''?
					 <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								View Route
                                <span className="float-right closebtn" style={{marginRight:"25px"}} 
                                onClick={this.onClickHideAll.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							
							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                                <DrawMap 
                                    context={this} 
                                    mapFor={"consignment"} 
                                    mapinfo={this.state.mapinfo}  />
							</div>
                        </div>
                    </div>
                :""}
				
            </div>
              
        );
    }
}


// eslint-disable-next-line no-unused-vars
function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		format:'Y-m-d',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

$(document).on("change", "#srtoDate", function(e) {
    e.preventDefault();
    var bookingDate=$("#srfromDate").val();
    var cargoDate=$("#srtoDate").val();
    // var bookingDate = bookingDate.split("-")[2]+"-"+bookingDate.split("-")[1]+"-"+bookingDate.split("-")[0]
    // var cargoDate = cargoDate.split("-")[2]+"-"+cargoDate.split("-")[1]+"-"+cargoDate.split("-")[0]
    console.log()
    var startDate = new Date(bookingDate);
    var endDate = new Date(cargoDate);
    var startTime = startDate.getTime();
    var endTime = endDate.getTime();
    console.log(startTime,endTime);
    if(startTime > endTime)
    {
        $("#srtoDate").val("");
        alert("To Date should be greater than or equal to From Date");
    }
})

$(document).on("change", "#srfromDate", function(e) {
    e.preventDefault();
    var bookingDate=$("#srfromDate").val();
    var cargoDate=$("#srtoDate").val();
    // var bookingDate = bookingDate.split("-")[2]+"-"+bookingDate.split("-")[1]+"-"+bookingDate.split("-")[0]
    // var cargoDate = cargoDate.split("-")[2]+"-"+cargoDate.split("-")[1]+"-"+cargoDate.split("-")[0]

    var startDate = new Date(bookingDate);
    var endDate = new Date(cargoDate);
    var startTime = startDate.getTime();
    var endTime = endDate.getTime();
    console.log(startTime,endTime);
    if(startTime > endTime)
    {
        $("#srfromDate").val("");
        alert("From Date should be less than equal to To Date");
    }
})

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd hh:mm",
        changeMonth: true,
        changeYear: true,
        timepicker: true,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};


function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}
function secondsToHM(seconds) {
    seconds = Number(seconds);
    // var d = Math.floor(seconds / (3600*24));
    // var h = Math.floor(seconds % (3600*24) / 3600);
    // var m = Math.floor(seconds % 3600 / 60);
    // var s = Math.floor(seconds % 60);
    
    // var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    // var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    // var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    // return dDisplay + hDisplay + mDisplay;
    
    var hh = Math.floor((seconds / 3600));
    var hrr = Math.floor(seconds - (hh*3600));
    var mm = Math.floor(hrr/60)
    if(mm < 10)
    {
        var mins = "0"+mm;
    }
    else
    {
        var mins = mm;
    }

    if(hh < 10)
    {
        var hrs = "0"+hh;
    }
    else
    {
        var hrs = hh;
    }
    return hrs+":"+mins;
}
function dateDifferenceInHHMM(date1, date2) {
    if(date2 !== 0)
    {
        const diff = moment.utc(moment(date2).diff(moment(date1)));
        return diff.format("HH:mm");
    }
    else{
        return "";
    }
}