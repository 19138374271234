
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const KpiTransitDelayActions = (props) => {
    const handleOverspeedClick = (e) => {
        e.stopPropagation();
        //console.log("TransitDelay ",props.data);
        if(props.data.dept_code == 'IBL')
        {
            if(props.data.show_legs == 1)
            {
                props.context.componentParent.onShowTransitKpi(props);
            }
            else
            {
                props.context.componentParent.onShowTransitKpi(0);
            }
        }
        else
        {
            props.context.componentParent.onShowTransitKpi(props);
        }
        
        
    };
    

    return (
        <div>
			{/* <button onClick={handleOverspeedClick} className="custom-btn f22 label label-success" title="Transit Delay" style={{color:"#ff0000"}}><i className="icofont icofont-clock-time darkorange"></i> &nbsp;</button> */}
			<button className="custom-btn f22 label label-success" title="Transit Delay" style={{color:"#ff0000"}}><i className="icofont icofont-clock-time darkorange"></i> &nbsp;</button>
        </div>
    );
};

export default KpiTransitDelayActions;
